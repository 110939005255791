<template>
    <div class="d-inline-block">
        <a @click.prevent="openDialog">
            <v-icon color="primary" class="mr-1">mdi-plus-circle-outline</v-icon> Créer un modèle d'entretien
        </a>

        <v-dialog v-model="dialog" width="600px">
            <v-card :loading="loading">
                <v-card-text class="pt-5">
                    <h3 class="">Créer un nouveau modèle</h3>
                    <v-form ref="form" @submit.prevent="saveModel">
                        <v-text-field
                            v-model="projectmodel_name"
                            :rules="fieldRules"
                            label="Donnez un nom à votre modèle"
                            required
                        ></v-text-field>
                        <div class="">
                            <v-btn
                                color="primary"
                                class="mr-4"
                                @click="saveModel"
                            >
                                Enregistrer
                            </v-btn>
                        </div>
                    </v-form>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="darken-1" text @click="dialog = false">Fermer</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            dialog: false,
            questions: [],
            projectmodel_name: '',
            fieldRules: [
                v => !!v || 'Ce champ est requis',
            ],
            activetab: 'tab_1',
        }
    },
    created() {
    },
    props: [
        'project_id'
    ],
    methods: {
        openDialog() {
            this.dialog = true;
        },
        saveModel() {
            this.loading = true;
            let project_id = null;
            if( this.project_id ) {
                project_id = this.project_id;
            }
            this.$http
                .post(process.env.VUE_APP_API + '/projectmodels', {
                    name: this.projectmodel_name,
                    project_id: project_id
                })
                .then( (response) => {
                    this.projectmodel_name = '';
                    if( project_id ) {
                        // emit reload questions
                        this.dialog = false;
                        this.$emit('modelCreated', response.data.projectmodel.id);
                    } else {
                        // else go to model edit page
                        this.dialog = false;
                        this.$emit('modelCreated', response.data.projectmodel.id);
                    }
                    
                }).catch( () => {
                    //this.errorResults = true;
                    this.loading = false;
                });
        }
    }
}
</script>