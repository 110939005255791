<template>
    <div>
        <div v-if="q.answered">
            <div class="interview-questions-title">
                <span class="interview-questions-title-number" :style="'background-color:'+q.question.category.color">{{ q.question.num }}</span> <span class="interview-questions-title-question" v-html="q.question.question"></span>
            </div>
            <div>
                <v-textarea
                    solo
                    name="input-7-4"
                    label="Saisissez la réponse"
                    v-model="q.answer"
                    @keydown="answerEdited(q)"
                ></v-textarea>
                <template v-if="! q.saving">
                    <a href="#" class="interview-questions-reinit" @click.prevent="$emit('reinit', q);">
                        Réinitialiser
                    </a>
                </template>
            </div>
        </div>
        <div v-else>
            <div>
                <div class="interview-questions-title">
                    <span class="interview-questions-title-number" :style="'background-color:'+q.question.category.color">{{ q.question.num }}</span> <span class="interview-questions-title-question">
                        Concernant la recherche de candidats,<br>
                        Comment recrute t-il ? Par quels moyens recherche t-il des candidats ?
                        <v-tooltip top color="black" :max-width="500">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon small v-bind="attrs" v-on="on" style="vertical-align:baseline">mdi-information</v-icon>
                            </template>
                            <span>
                                Annonces, intérim, cooptation, Pôle emploi, offres d'emploi sur site Web....
                            </span>
                        </v-tooltip>
                    </span>
                </div>
                <div>
                    <v-textarea
                        solo
                        name="input-7-4"
                        label="Saisissez la réponse"
                        v-model="reponse1"
                        @keydown="answerEdited(q)"
                    ></v-textarea>
                </div>
            </div>
            <div>
                <div class="interview-questions-title">
                    <span class="interview-questions-title-question">
                        Utilise t-il les réseaux sociaux ?
                    </span>
                </div>
                <div class="mb-3">
                    <label class="mr-3">
                        <input type="radio" @change="displayNext" v-model="reponse2" :value="'oui'"> oui
                    </label>
                    <label>
                        <input type="radio" @change="displayNext" v-model="reponse2" :value="'non'"> non
                    </label>
                </div>
            </div>
            <div v-if="reponse2 === 'oui'">
                <div class="interview-questions-title">
                    <span class="interview-questions-title-question">
                        Lesquels utilise t-il ?
                    </span>
                </div>
                <div>
                    <v-textarea
                        solo
                        name="input-7-4"
                        label="Saisissez la réponse"
                        v-model="reponse3"
                        @keydown="answerEdited(q)"
                    ></v-textarea>
                </div>
                <div class="interview-questions-title">
                    <span class="interview-questions-title-question">
                        Sait il à quoi chacun sert ?
                    </span>
                </div>
                <div class="mb-3">
                    <label class="mr-3">
                        <input type="radio" @change="displayNext" v-model="reponse4" :value="'oui'"> oui
                    </label>
                    <label>
                        <input type="radio" @change="displayNext" v-model="reponse4" :value="'non'"> non
                    </label>
                </div>
                <div class="interview-questions-title">
                    <span class="interview-questions-title-question">
                        Juge t-il utile de les utiliser ?
                    </span>
                </div>
                <div class="mb-3">
                    <label class="mr-3">
                        <input type="radio" @change="displayNext" v-model="reponse5" :value="'oui'"> oui
                    </label>
                    <label class="mr-3">
                        <input type="radio" @change="displayNext" v-model="reponse5" :value="'non'"> non
                    </label>
                    <label>
                        <input type="radio" @change="displayNext" v-model="reponse5" :value="'ça dépend'"> ça dépend
                    </label>
                </div>
                <div class="interview-questions-title">
                    <span class="interview-questions-title-question">
                        Souhaite t-il un appui pour les découvrir ?
                    </span>
                </div>
                <div class="mb-3">
                    <label class="mr-3">
                        <input type="radio" @change="displayNext" v-model="reponse6" :value="'oui'"> oui
                    </label>
                    <label>
                        <input type="radio" @change="displayNext" v-model="reponse6" :value="'non'"> non
                    </label>
                </div>
            </div>
        </div>
        <div v-if="q.edited || q.answered">
            <v-btn :loading="q.saving" :disabled="! q.edited || q.saving" color="primary" @click="saveAnswer(q)">Enregistrer</v-btn>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            step: 1,
            reponse1: '',
            reponse2: '',
            reponse3: '',
            reponse4: '',
            reponse5: '',
            reponse6: '',
        }
    },
    methods: {
        saveAnswer(question) {
            question.comment = {
                reponse1: this.reponse1,
                reponse2: this.reponse2,
                reponse3: this.reponse3,
                reponse4: this.reponse4,
                reponse5: this.reponse5,
                reponse6: this.reponse6,
            };
            this.$emit('saved', question);
            // clean data
            question.comment = '';
            this.reponse1 = '';
            this.reponse2 = '';
            this.reponse3 = '';
            this.reponse4 = '';
            this.reponse5 = '';
            this.reponse6 = '';
        },
        answerEdited(question) {
            if( ! question.edited ) {
                question.edited = true;
            }
            this.$emit('editing', question);
        },
        displayNext() {
            /*if( this.reponse1 == 'avoir besoin d’un appui' ) {
                this.step = 2;
            } else {
                this.step = 3;
            }*/
            this.q.edited = true;
        }
    },
    props: [
        'q',
    ],
}
</script>