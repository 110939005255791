<template>
  <v-container
      fluid
  >

    <v-row v-if="loading">
      <v-card-text >
        <Loading />
      </v-card-text>
    </v-row>
    <template v-else>
      <v-row>
        <v-col cols="8">
          <v-card>
            <v-card-text>
              <h1 class="py-3 pa-2">
                Utilisateurs
              </h1>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card class="py-3 pa-2">
            <v-card-text class="py-2 pa-2 text-center">
              <v-btn class="text-body-2"
                  color="primary"
                   @click="addUserModal = true"
              >
                <v-icon class="pr-2">mdi-account-plus</v-icon>
                Ajouter un utilisateur
              </v-btn>
              <v-dialog
                  v-model="addUserModal"
                  width="auto"
              >
                <v-card class="pa-3">
                  <v-card-text>
                    <v-row>
                      <v-col
                          cols="6"
                      >
                        <v-text-field
                            :rules="nameRules"
                            v-model="newUserFirstName"
                            :counter="10"
                            label="Prénom"
                            required
                            hide-details
                        ></v-text-field>
                      </v-col>

                      <v-col
                          cols="6"
                      >
                        <v-text-field
                            :rules="nameRules"
                            v-model="newUserLastName"
                            :counter="10"
                            label="Nom"
                            hide-details
                            required
                        ></v-text-field>
                      </v-col>

                      <v-col
                          cols="12"
                      >
                        <v-text-field
                            v-model="newUserEmail"
                            :rules="emailRules"
                            label="Adresse e-mail"
                            hide-details
                            required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-select
                            :items="itemsRoles"
                            v-model="newUserRole"
                            item-text="name"
                            item-value="slug"
                            label="Selectionner le rôle de l'utilisateur"
                        ></v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-select
                            :items="agencies"
                            v-model="newUserAgencies"
                            density="compact"
                            label="Selectionner les agences auxquelles l'utilisateur aura accès"
                            multiple
                            item-text="name"
                            item-value="id"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-row>
                      <v-col cols="6">
                        <v-btn color="secondary" block @click="closeNewUserModal()">Annuler</v-btn>
                      </v-col>
                      <v-col cols="6">
                        <v-btn color="success" block @click="saveNewUser()">Ajouter l'utilisateur</v-btn>
                      </v-col>
                    </v-row>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog
                  v-model="editUserModal"
                  width="auto"
              >
                <v-card class="pa-3">
                  <v-card-text>
                    <v-row>
                      <v-col
                          cols="6"
                      >
                        <v-text-field
                            :rules="nameRules"
                            v-model="editedUser.firstname"
                            :counter="10"
                            label="Prénom"
                            required
                            hide-details
                        ></v-text-field>
                      </v-col>

                      <v-col
                          cols="6"
                      >
                        <v-text-field
                            :rules="nameRules"
                            v-model="editedUser.lastname"
                            :counter="10"
                            label="Nom"
                            hide-details
                            required
                        ></v-text-field>
                      </v-col>

                      <v-col
                          cols="12"
                      >
                        <v-text-field
                            v-model="editedUser.email"
                            :rules="emailRules"
                            label="Adresse e-mail"
                            hide-details
                            required
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12">
                        <v-select
                            :items="itemsRoles"
                            v-model="editedUser.role"
                            item-text="name"
                            item-value="slug"
                            label="Selectionner le rôle de l'utilisateur"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" v-if="editedUser.role === 'dr'">
                        <v-select
                            v-model="editedUser.regional_directorate_id"
                            :items="regionalDirectorates"
                            item-text="name"
                            item-value="id"
                            density="compact"
                            label="Selectionner la Région dirigée"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" v-if="editedUser.role === 'dt'">
                        <v-select
                            v-model="editedUser.territorial_directorate_id"
                            :items="territorialDirectorates"
                            item-text="name"
                            item-value="id"
                            density="compact"
                            label="Selectionner le Territoire dirigé"
                        ></v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-select
                            :items="agencies"
                            v-model="editedUser.agencies_ids"
                            density="compact"
                            label="Selectionner les agences auxquelles l'utilisateur aura accès"
                            multiple
                            item-text="name"
                            item-value="id"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-row>
                      <v-col cols="6">
                        <v-btn color="secondary" block @click="closeNewUserModal()">Annuler</v-btn>
                      </v-col>
                      <v-col cols="6">
                        <v-btn color="success" block @click="editUser()">Modifier l'utilisateur</v-btn>
                      </v-col>
                    </v-row>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="8">
          <v-card class="py-4 pa-4">
            Filtres :
            <v-btn class="text-body-2 mr-2"
                   color="primary"
                   small
                   outlined
                   dark
                   @click="filterUsers('all')"
            >
              Tous
            </v-btn>
            <v-btn class="text-body-2 mr-2"
                   color="primary"
                   small
                   outlined
                   dark
                   @click="filterUsers('dr')"
            >
              Directeur Régional
            </v-btn>
            <v-btn class="text-body-2 mr-2"
                   color="primary"
                   small
                   outlined
                   dark
                   @click="filterUsers('dt')"
            >
              Directeur Térritorial
            </v-btn>
            <v-btn class="text-body-2"
                   color="primary"
                   small
                   outlined
                   dark
                   @click="filterUsers('user')"
            >
              Agent
            </v-btn>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card class="pt-3 px-4 pb-4">
            <v-text-field
                class="pt-0 mt-0"
                label="Rechercher un utilisateur"
                required
                hide-details
                @input="searchUser($event)"
            ></v-text-field>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="[
              { text: 'Nom', value: 'lastname' },
              { text: 'Prénom', value: 'firstname' },
              { text: 'Email', value: 'email' },
              { text: 'Rôle', value: 'role' },
              { text: 'Actions', value: 'actions' },
            ]"
            :items="users"
            :items-per-page="20"
            class="elevation-1">
            <template v-slot:item.role="{item}">
              <v-select
                  :value="item.role"
                  :v-model="item.role"
                  :items="itemsRoles"
                  item-text="name"
                  item-value="slug"
                  @change="changeUserRole($event, item.id)"
              ></v-select>
              <v-select
                  v-if="item.role === 'dr'"
                  :value="item.regional_directorate_id"
                  :v-model="item.regional_directorate_id"
                  :items="regionalDirectorates"
                  item-text="name"
                  item-value="id"
                  @change="setRegionalDirectorateForUser($event, item.id)"
                  label="Selectionner la Région dirigée"
              ></v-select>
              <v-select
                  v-if="item.role === 'dt'"
                  :value="item.territorial_directorate_id"
                  :v-model="item.territorial_directorate_id"
                  :items="territorialDirectorates"
                  item-text="name"
                  item-value="id"
                  @change="setTerritorialDirectorateForUser($event, item.id)"
                  label="Selectionner le Territoire dirigée"
              ></v-select>
            </template>
            <template v-slot:item.actions="{item}">
              <v-btn
                  class="mr-2"
                  color="primary"
                  @click="editUserModal = true; editedUser = item; editedUserAgencies();"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                  color="error"
                  @click="deleteUser(item)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </template>


  </v-container>
</template>

<script>
import Loading from '@/components/Loading';

export default {
  data(){
    return {
      users: [],
      agencies: [],
      regionalDirectorates: [],
      territorialDirectorates: [],
      addUserModal: false,
      editUserModal: false,
      editedUser: false,
      loading: false,
      itemsRoles: [
        {
          'slug': 'admin',
          'name': 'Administrateur',
        },
        {
          'slug': 'user',
          'name': 'Utilisateur',
        },
        {
          'slug': 'dr',
          'name': 'Directeur Régional',
        },
        {
          'slug': 'dt',
          'name': 'Directeur Territorial',
        }
      ],

      nameRules: [
        value => {
          if (value) return true

          return 'Le nom est requis'
        }
      ],
      emailRules: [
        value => {
          if (value) return true

          return 'L\'adresse e-mail est requise.'
        },
        value => {
          if (/.+@.+\..+/.test(value)) return true

          return 'L\'adresse e-mail doit être valide.'
        },
      ],

      newUserFirstName: '',
      newUserLastName: '',
      newUserEmail: '',
      newUserRole: false,
      newUserAgencies: false
    }
  },

  created() {
    this.getUsers();
    this.getAgencies();
    this.getRegionalDirectorates()
    this.getTerritorialDirectorates()
  },

  methods: {

    searchUser(event) {
      let url = process.env.VUE_APP_API + '/users/search';

      this.$http
          .post(url, {
            'search': event,
          })
          .then(response => {
            this.users = response.data.users;
          }).catch( () => {
        this.loading = false;
      });
    },

    filterUsers(role) {
      this.loading = true;
      let url = process.env.VUE_APP_API + '/users/filter';

      this.$http
          .post(url, {
            'role': role,
          })
          .then(response => {
            this.users = response.data.users;
            this.loading = false;
          }).catch( () => {
        this.loading = false;
      });
    },

    getUsers() {
      this.loading = true;
      let url = process.env.VUE_APP_API + '/users';

      this.$http
          .get(url)
          .then(response => {
            this.users = response.data.users;
            this.loading = false;
          }).catch( () => {
        this.loading = false;
      });
    },

    getAgencies() {
      this.loading = true;
      let url = process.env.VUE_APP_API + '/agencies';

      this.$http
          .get(url)
          .then(response => {
            this.agencies = response.data.agencies;
          }).catch( () => {
        this.loading = false;
      });
    },

    getRegionalDirectorates() {
      this.loading = true;
      let url = process.env.VUE_APP_API + '/regional-directorates';

      this.$http
          .get(url)
          .then(response => {
            this.regionalDirectorates = response.data.regional_directorates;
            this.loading = false;
          }).catch( () => {
        this.loading = false;
      });
    },

    getTerritorialDirectorates() {
      this.loading = true;
      let url = process.env.VUE_APP_API + '/territorial-directorates';

      this.$http
          .get(url)
          .then(response => {
            this.territorialDirectorates = response.data.territorial_directorates;
            this.loading = false;
          }).catch( () => {
        this.loading = false;
      });
    },

    editedUserAgencies() {
      this.editedUser.agencies_ids = JSON.parse(this.editedUser.agencies_ids);

      this.editedUser.agencies_ids.forEach((userAgency, index) => {
        this.agencies.filter((element) => {
          if (element.id === userAgency){
            this.editedUser.agencies_ids[index] = element;
          }
        });
      });
    },

    changeUserRole(role, id) {
      let url = process.env.VUE_APP_API + '/user/role';

      this.$http
          .post(url, {
            'id': id,
            'role': role,
          })
          .then(response => {
            this.users = response.data.users;
          }).catch( () => {}
      );
    },

    closeNewUserModal() {
      this.addUserModal     = false;
      this.editedUser       = false;
      this.editUserModal    = false;
      this.newUserFirstName = '';
      this.newUserLastName  = '';
      this.newUserEmail     = '';
      this.newUserPassword  = '';
      this.newUserRole      = false;
      this.newUserAgencies  = false;
    },

    saveNewUser() {
      this.loading = true;
      let url = process.env.VUE_APP_API + '/user/create';

      this.$http
          .post(url, {
            'firstname': this.newUserFirstName,
            'lastname': this.newUserLastName,
            'email': this.newUserEmail,
            'password': this.newUserPassword,
            'role': this.newUserRole,
            'agencies': this.newUserAgencies,
          })
          .then(response => {
            this.users = response.data.users;
            this.loading = false;
            this.closeNewUserModal();
          }).catch( () => {
        this.loading = false;
      });
    },

    editUser() {
      this.loading = true;
      let url = process.env.VUE_APP_API + '/user/create';

      this.$http
          .post(url, {
            'id': this.editedUser.id,
            'firstname': this.editedUser.firstname,
            'lastname': this.editedUser.lastname,
            'email': this.editedUser.email,
            'role': this.editedUser.role,
            'agencies': this.editedUser.agencies_ids,
            'regional_directorate_id': this.editedUser.regional_directorate_id,
          })
          .then(response => {
            this.users = response.data.users;
            this.loading = false;
            this.closeNewUserModal();
          }).catch( () => {
        this.loading = false;
      });
    },

    deleteUser(user) {

      if (window.confirm("Supprimer l'utilisateur " + user.firstname + " " + user.lastname + " ?")) {
        this.loading = true;
        let url = process.env.VUE_APP_API + '/user/delete';

        this.$http
            .post(url, {
              'id': user.id,
            })
            .then(response => {
              this.users = response.data.users;
              this.loading = false;
            }).catch(() => {
          this.loading = false;
        });
      }

    },

    setRegionalDirectorateForUser(event, user_id) {
      let url = process.env.VUE_APP_API + '/user/regional-directorate';

      this.$http
          .post(url, {
            'user_id': user_id,
            'regional_directorate_id': event,
          })
          .then(response => {
            this.users = response.data.users;
          }).catch(() => {
      });
    },

    setTerritorialDirectorateForUser(event, user_id) {
      let url = process.env.VUE_APP_API + '/user/territorial-directorate';

      this.$http
          .post(url, {
            'user_id': user_id,
            'territorial_directorate_id': event,
          })
          .then(response => {
            this.users = response.data.users;
          }).catch(() => {
      });
    }
  },

  components: {
    Loading
  },

  computed: {
    console: () => console,
  }
}
</script>
