<template>
    <v-container
        fluid
    >
        <v-card>
            <v-card-text class="conseils">
                <div class="mb-5">
                    <h3 class="mb-2">Apports des webinaires</h3>
                    <p>
                        <a href="/pdf/2024_-_Notions_CSP_Capter_Selectionner_Perenniser.pdf" target="_blank">
                           Notions CSP - Capter Sélectionner Pérenniser
                        </a><br>

                        <a href="/pdf/2024_-_Sequencement_des_actions_dans_l'outil.pdf" target="_blank">
                          Séquencement des actions dans l’outil
                        </a><br>

                        <a href="/pdf/2024_-_Le_triangle_de_la_relation_de_conseil.pdf" target="_blank">
                          Le triangle de la relation de conseil
                        </a><br>

                        <a href="/pdf/2024_-_Le_cycle_de_l'autonomie.pdf" target="_blank">
                          Le cycle de l’autonomie
                        </a>
                    </p>
                </div>

                <div class="mb-5">
                    <h3>Conseils préparatoires</h3>
                    <p>
                        <a href="/pdf/2024_-_Argumentaire_pour_proposer_la_demarche_a_un_employeur.pdf" target="_blank">
                          Argumentaire pour proposer la démarche à un employeur
                        </a><br>
                        <a href="/pdf/2024_-_Comment_passer_du_diagnostic_a_entretien.pdf" target="_blank">
                          Comment passer de l’auto-diagnostic à l’entretien ?
                        </a><br>
                        <a href="/pdf/2024_-_Exemples_d'analyse_auto-diagnostic_pour_preparer_son_entretien.pdf" target="_blank">
                          Exemples d’analyse de l’auto-diagnostic pour préparer son entretien
                        </a><br>
                        <a href="/pdf/2024_-_Liste_des_questions_de_l'auto-diagnostic_recrutement.pdf" target="_blank">
                          Liste des questions de l’auto-diagnostic recrutement
                        </a><br>
                        <a href="/pdf/2024_-_Liste_des_questions_disponibles_pour_les_entretiens.pdf" target="_blank">
                          Liste des questions disponibles pour les entretiens
                        </a>
                    </p>
                </div>
            </v-card-text>
        </v-card>

    </v-container>
</template>

<script>
export default {
    data() {
        return {
        }
    },
}
</script>
