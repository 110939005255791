<template>
    <v-container
        fluid
    >
        <v-card>
            <v-card-text>
                <h3 class="text--primary mb-4">
                   Générer un lien vers l'auto-diagnostic
                </h3>
                <v-row>
                    <v-col cols="12" md="7" sm="6">

                        <v-form>
                            <v-text-field
                                label="Renseignez le SIRET de l'entreprise"
                                v-model="siret"
                                @change="getSiretInfo(user_token)"
                            ></v-text-field>
                            <p v-if="siretError">
                              <span class="red--text">Le SIRET ne correspond à aucune entreprise dans la base de données de l'Insee</span>
                            </p>
                            <v-text-field
                                label="Renseignez le nom de l'entreprise"
                                v-model="company"
                            ></v-text-field>
                            <v-btn
                                text
                                @click="generateLink"
                                class="orange white--text"
                            >
                                Générer
                            </v-btn>
                        </v-form>

                        <div v-if="url && url.length > 0" class="mt-4">
                            <div class="mb-1">
                                <strong>
                                    Voilà le lien vers l’auto-diagnostic :
                                </strong>
                            </div>
                            <div class="blue--text mb-2">
                                {{ url }}
                            </div>
                            <div class="mb-3">
                              <v-row>
                                <v-col
                                    cols="6"
                                >
                                  <v-text-field
                                      v-model="autodiag_email"
                                      :rules="emailRules"
                                      :counter="10"
                                      label="Email destinataire"
                                      required
                                      hide-details
                                  ></v-text-field>
                                </v-col>
                                <v-col
                                    cols="6">
                                  <span class="v-btn v-btn--contained theme--dark v-size--small black v-btn--flat mt-4" @click.prevent="sendAutoDiag">Envoyer par mail</span>
                                </v-col>
                              </v-row>
                              <v-row v-if="mailSent">
                                <v-col cols="12">
                                  <span class="green py-2 px-2 white--text">Le lien a été envoyé</span>
                                </v-col>
                              </v-row>
                            </div>
                            <div>
                                Envoyez ce lien par email à l'entreprise de votre choix sur votre secteur. Elle pourra réaliser l’auto-diagnostic et vous serez informé une fois le formulaire complété.
                            </div>
                        </div>
                    </v-col>
                    <v-col cols="12" md="5" sm="6" align="center">
                        <v-img src="@/assets/images/screen_outil1.jpg" aspect-ratio="1.69" max-width="334" contain></v-img>
                    </v-col>
                </v-row>

            </v-card-text>

        </v-card>

    </v-container>
</template>

<script>

export default {
    data() {
        return {
            loading: false,
            url: '',
            token: false,
            company: '',
            siret: '',
            mailSent: false,
            autodiag_email: '',
            user_token: '',
            siretError: false,
            emailRules: [
              value => {
                if (value) return true

                return 'L\'adresse e-mail est requise.'
              },
              value => {
                if (/.+@.+\..+/.test(value)) return true

                return 'L\'adresse e-mail doit être valide.'
              },
            ],
        }
    },
    created() {
      // test route sirene/login
      this.$http
          .get(process.env.VUE_APP_API + '/sirene/login')
          .then( request => {
              this.user_token = request.data.access_token;
          }).catch( () => {
              this.loading = false;
          });
    },
    methods: {
        buildLink() {
            this.url = process.env.VUE_APP_AUTODIAG_URL + '/' + this.token;
        },
        generateLink() {
            this.loading = true;
            this.$http
                .post(process.env.VUE_APP_API + '/employers', {
                    company: this.company,
                    siret: this.siret
                })
                .then( request => {
                    this.loading = false;
                    this.token = request.data.token;
                    this.buildLink();
                }).catch( () => {
                    this.loading = false;
                });
        },

        sendAutoDiag() {
          this.$http
              .post(process.env.VUE_APP_API + '/autodiag/send', {
                  email: this.autodiag_email,
                  link: this.url
              })
              .then((response) => {
                 if (response.data.success === true) {
                   this.mailSent = true;

                   setTimeout(() => {
                     this.mailSent = false;
                   }, 3000);
                 }
              })
              .catch( () => {
                  this.loading = false;
              });
        },

        getSiretInfo(token) {
          this.$http
              .post(process.env.VUE_APP_API + '/sirene/get', {token: token, siret: this.siret})
              .then(request => {
                console.log(request);
                console.log(request.data)
                if (request.data.etablissement) {
                  this.company = request.data.etablissement.uniteLegale.denominationUniteLegale;
                  this.siretError = false;
                }
                else {
                  this.company = '';
                  this.siretError = true;
                }
              }).catch(() => {
                this.loading = false;
              });
        }
    }
}
</script>
