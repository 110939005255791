<template>
  <v-container
      fluid
  >

    <v-row v-if="loading">
      <v-card-text >
        <Loading />
      </v-card-text>
    </v-row>
    <template v-else>
      <v-row>
        <v-col cols="8">
          <v-card>
            <v-card-text>
              <h1 class="py-3 pa-2">
                Directions Territoriales
              </h1>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card class="py-3 pa-2">
            <v-card-text class="py-2 pa-2 text-center">
              <v-btn class="text-body-2"
                     color="primary"
                     @click="addTerritorialDirectorateModal = true"
              >
                <v-icon class="pr-2">mdi-account-plus</v-icon>
                Ajouter une direction territoriale
              </v-btn>
              <v-dialog
                  v-model="addTerritorialDirectorateModal"
                  width="50%"
              >
                <v-card class="pa-3">
                  <v-card-text>
                    <v-row>
                      <v-col
                          cols="12"
                      >
                        <v-text-field
                            :rules="nameRules"
                            v-model="newTerritorialDirectorateName"
                            :counter="10"
                            label="Nom de la Direction Territoriale"
                            required
                            hide-details
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-row>
                      <v-col cols="6">
                        <v-btn color="secondary" block @click="closeTerritorialDirectorateModal()">Annuler</v-btn>
                      </v-col>
                      <v-col cols="6">
                        <v-btn color="success" block @click="saveTerritorialDirectorate()">Ajouter la Direction Territoriale</v-btn>
                      </v-col>
                    </v-row>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog
                  v-model="editTerritorialDirectorateModal"
                  width="50%"
              >
                <v-card class="pa-3">
                  <v-card-text>
                    <v-row>
                      <v-col
                          cols="12"
                      >
                        <v-text-field
                            :rules="nameRules"
                            v-model="editedTerritorialDirectorate.name"
                            :counter="10"
                            label="Nom"
                            required
                            hide-details
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-row>
                      <v-col cols="6">
                        <v-btn color="secondary" block @click="closeTerritorialDirectorateModal()">Annuler</v-btn>
                      </v-col>
                      <v-col cols="6">
                        <v-btn color="success" block @click="editTerritorialDirectorate()">Modifier la Direction Territoriale</v-btn>
                      </v-col>
                    </v-row>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-data-table
              :headers="[
              { text: 'Nom', value: 'name' },
              { text: 'Rattaché à la Direction Régionale suivante :', value: 'regional_directorate_id' },
              { text: 'Actions', value: 'actions' }
            ]"
              :items="territorialDirectorates"
              :items-per-page="20"
              class="elevation-1">
            <template v-slot:item.regional_directorate_id="{item}">
              <v-select
                  :value="item.regional_directorate_id"
                  :v-model="item.regional_directorate_id"
                  :items="regionalDirectorates"
                  item-text="name"
                  item-value="id"
                  @change="changeRegionalDirectorate($event, item.id)"
              ></v-select>
            </template>
            <template v-slot:item.actions="{item}">
              <v-btn
                  class="mr-2"
                  color="primary"
                  @click="editTerritorialDirectorateModal = true; editedTerritorialDirectorate = item;"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                  color="error"
                  @click="deleteTerritorialDirectorate(item)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </template>


  </v-container>
</template>

<script>
import Loading from '@/components/Loading';

export default {
  data(){
    return {
      regionalDirectorates: [],
      territorialDirectorates: [],
      addTerritorialDirectorateModal: false,
      editTerritorialDirectorateModal: false,
      editedTerritorialDirectorate: false,
      loading: false,

      nameRules: [
        value => {
          if (value) return true

          return 'Le nom est requis'
        }
      ],

      newTerritorialDirectorateName: '',
    }
  },

  created() {
    this.getRegionalDirectorates();
    this.getTerritorialDirectorate();
  },

  methods: {
    getRegionalDirectorates() {
      this.loading = true;
      let url = process.env.VUE_APP_API + '/regional-directorates';

      this.$http
          .get(url)
          .then(response => {
            this.regionalDirectorates = response.data.regional_directorates;
            this.loading = false;
          }).catch( () => {
        this.loading = false;
      });
    },

    getTerritorialDirectorate() {
      this.loading = true;
      let url = process.env.VUE_APP_API + '/territorial-directorates';

      this.$http
          .get(url)
          .then(response => {
            this.territorialDirectorates = response.data.territorial_directorates;
            this.loading = false;
          }).catch( () => {
        this.loading = false;
      });
    },

    closeTerritorialDirectorateModal() {
      this.addTerritorialDirectorateModal     = false;
      this.editedTerritorialDirectorate       = false;
      this.editTerritorialDirectorateModal    = false;
      this.newTerritorialDirectorateName      = '';
    },

    saveTerritorialDirectorate() {
      this.loading = true;
      let url = process.env.VUE_APP_API + '/territorial-directorate/create';

      this.$http
          .post(url, {
            'name': this.newTerritorialDirectorateName,
          })
          .then(response => {
            this.territorialDirectorates = response.data.territorial_directorates;
            this.loading = false;
            this.closeTerritorialDirectorateModal();
          }).catch( () => {
        this.loading = false;
      });
    },

    editTerritorialDirectorate() {
      this.loading = true;
      let url = process.env.VUE_APP_API + '/territorial-directorate/update';

      this.$http
          .post(url, {
            'id': this.editedTerritorialDirectorate.id,
            'name': this.editedTerritorialDirectorate.name,
          })
          .then(response => {
            this.territorialDirectorates = response.data.territorial_directorates;
            this.loading = false;
            this.closeTerritorialDirectorateModal();
          }).catch( () => {
        this.loading = false;
      });
    },

    deleteTerritorialDirectorate(territorialDirectorate) {

      if (window.confirm("Supprimer la direction territoriale " + territorialDirectorate.name + " ?")) {
        this.loading = true;
        let url = process.env.VUE_APP_API + '/territorial-directorate/delete';

        this.$http
            .post(url, {
              'id': territorialDirectorate.id,
            })
            .then(response => {
              this.territorialDirectorates = response.data.territorial_directorates;
              this.loading = false;
            }).catch(() => {
          this.loading = false;
        });
      }

    },

    changeRegionalDirectorate(event, territorial_directorate_id) {
      this.loading = true;
      let url = process.env.VUE_APP_API + '/territorial-directorate/regional-directorate';

      this.$http
          .post(url, {
            'territorial_directorate_id': territorial_directorate_id,
            'regional_directorate_id': event,
          })
          .then(response => {
            this.territorialDirectorates = response.data.territorial_directorates;
            this.loading = false;
          }).catch(() => {
        this.loading = false;
      });
    }
  },

  components: {
    Loading
  }
}
</script>
