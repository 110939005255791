<template>
    <div class="interview">

        <div v-if="loading" class="pt-4">
            <Loading />
        </div>
        <v-container v-else fluid>
            <div class="topbar">
                <div class="topbar-title">
                    <span class="topbar-title-span">
                        Entretien : {{ project.employer.company }}
                    </span>
                </div>
            </div>
            <div class="interview-questions">
                <v-row align="center" justify="center">
                    <v-col cols="12" lg="8" md="10">
                        <v-card class="mb-5" min-width="100%">
                            <v-stepper value="4">
                                <v-stepper-header>
                                    <v-stepper-step step="1" complete>Entretien<br>enregistré</v-stepper-step>
                                    <v-divider></v-divider>
                                    <v-stepper-step step="2" complete>Positionnement<br>CSP</v-stepper-step>
                                    <v-divider></v-divider>
                                    <v-stepper-step step="3" complete>Positionnement<br>CPE</v-stepper-step>
                                    <v-divider></v-divider>
                                    <v-stepper-step step="4">Pistes d'actions<br>et échéances</v-stepper-step>
                                    <v-divider></v-divider>
                                    <v-stepper-step step="5">Évaluation</v-stepper-step>
                                </v-stepper-header>
                            </v-stepper>
                            <v-card-text class="">

                                <div>
                                    <h3 class="pt-3 pb-4">
                                        Pistes d’actions du conseiller & Pistes d’actions Entreprise :
                                    </h3>
                                    <v-textarea
                                        solo
                                        label=""
                                        v-model="project.bilan"
                                    ></v-textarea>
                                </div>


                                <h3 class="pt-3 pb-2  black--text">
                                    Date d'échéance :
                                </h3>
                                <div>
                                    <v-menu
                                        ref="menu_1"
                                        v-model="menu_1"
                                        :close-on-content-click="false"
                                        :return-value.sync="project.date_next_step_1"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="project.date_next_step_1"
                                                label="Choisissez la date"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker v-model="project.date_next_step_1" no-title scrollable locale="fr" first-day-of-week="1">
                                            <v-spacer></v-spacer>
                                            <v-btn text color="primary" @click="menu_1 = false">Cancel</v-btn>
                                            <v-btn text color="primary" @click="$refs.menu_1.save(project.date_next_step_1)">OK</v-btn>
                                        </v-date-picker>
                                    </v-menu>
                                </div>



                                <!--<div>
                                    <h3 class="pt-3 pb-4">
                                        Qui fait quoi et dates d'échéances
                                    </h3>
                                </div>

                                <v-row>
                                    <v-col>
                                        <div class="text-left">
                                            Notez qui fait quoi pour les prochaines étapes :
                                        </div>
                                        <v-textarea
                                            solo
                                            label=""
                                            v-model="project.next_step"
                                        ></v-textarea>
                                    </v-col>
                                    <v-col>
                                        <div class="text-left">
                                            Date d'échéance :
                                        </div>
                                        <div>
                                            <v-menu
                                                ref="menu_1"
                                                v-model="menu_1"
                                                :close-on-content-click="false"
                                                :return-value.sync="project.date_next_step_1"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290px"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        v-model="project.date_next_step_1"
                                                        label="Choisissez la date"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker v-model="project.date_next_step_1" no-title scrollable locale="fr" first-day-of-week="1">
                                                    <v-spacer></v-spacer>
                                                    <v-btn text color="primary" @click="menu_1 = false">Cancel</v-btn>
                                                    <v-btn text color="primary" @click="$refs.menu_1.save(project.date_next_step_1)">OK</v-btn>
                                                </v-date-picker>
                                            </v-menu>
                                        </div>
                                    </v-col>
                                </v-row>
                                -->


                            </v-card-text>
                            <v-card-text class="text-right">
                                <v-btn text small :to="{ name: 'interview.review2', params: {id: project.id} }">Retour</v-btn> <v-btn text small color="primary" @click="saveDates">Suivant</v-btn>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </div>
        </v-container>
    </div>
</template>

<script>
import Loading from '@/components/Loading';

export default {
    data(){
        return {
            loading: false,
            project: false,
            menu_1: false,
        }
    },
    created() {
        this.getProject();
    },
    methods: {
        getProject() {
            this.loading = true;
            this.$http
                .get(process.env.VUE_APP_API + '/projects/' + this.$route.params.id)
                .then(response => {
                    this.project = response.data.data;
                    this.getSelectedQuestions();
                    this.loading = false;
                }).catch( () => {
                    this.loading = false;
                });
        },
        saveDates() {
            this.$http
                .post(process.env.VUE_APP_API + '/projects/' + this.$route.params.id + '/dates', {
                    next_step: this.project.next_step,
                    date_next_step_1: this.project.date_next_step_1,
                    bilan: this.project.bilan
                })
                .then( () => {
                    // redirect to next screen
                    this.$router.push({
                        name: 'interview.rates',
                        params: {
                            id: this.$route.params.id,
                        }
                    });

                    this.loading = false;
                }).catch( () => {
                    this.loading = false;
                });
        }
    },
    components: {
        Loading
    }
}
</script>
