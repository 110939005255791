<template>
    <div>
        <div v-if="q.answered">
            <div class="interview-questions-title">
                <span class="interview-questions-title-number" :style="'background-color:'+q.question.category.color">{{ q.question.num }}</span> <span class="interview-questions-title-question" v-html="q.question.question"></span>
            </div>
            <div>
                <v-textarea
                    solo
                    name="input-7-4"
                    label="Saisissez la réponse"
                    v-model="q.answer"
                    @keydown="answerEdited(q)"
                ></v-textarea>
                <template v-if="! q.saving">
                    <a href="#" class="interview-questions-reinit" @click.prevent="$emit('reinit', q);">
                        Réinitialiser
                    </a>
                </template>
            </div>
        </div>
        <div v-else>
            <div>
                <div class="interview-questions-title">
                    <span class="interview-questions-title-number" :style="'background-color:'+q.question.category.color">{{ q.question.num }}</span> <span class="interview-questions-title-question">
                        Connait-il des outils digitaux permettant de gérer ses recrutements sur internet ? sur smartphone ?
                    </span>
                </div>
                <div class="mb-3">
                    <label class="mr-3">
                        <input type="radio" @change="displayNext" v-model="reponse1" :value="'oui'"> Oui
                    </label>
                    <label>
                        <input type="radio" @change="displayNext" v-model="reponse1" :value="'non'"> Non
                    </label>
                </div>
            </div>
            <div v-if="reponse1 === 'non'">
                <div class="interview-questions-title">
                    <span class="interview-questions-title-question">
                        Si non, souhaiterait-il en savoir plus sur ces outils ?
                    </span>
                </div>
                <div class="mb-3">
                     <label class="mr-3">
                        <input type="radio" @change="displayNext" v-model="reponse2" :value="'oui'"> Oui
                    </label>
                    <label>
                        <input type="radio" @change="displayNext" v-model="reponse2" :value="'non'"> Non
                    </label>
                </div>
            </div>
            <div v-if="reponse1 === 'oui'">
                <div class="interview-questions-title">
                    <span class="interview-questions-title-question">
                        Si oui, les utilise t-il ? 
                    </span>
                </div>
                <div class="mb-3">
                    <label>
                        <input type="radio" @change="displayNext" v-model="reponse3" :value="'régulièrement'"> régulièrement
                    </label><br>
                    <label>
                        <input type="radio" @change="displayNext" v-model="reponse3" :value="'de temps en temps'"> de temps en temps
                    </label><br>
                    <label>
                        <input type="radio" @change="displayNext" v-model="reponse3" :value="'rarement'"> rarement
                    </label><br>
                    <label>
                        <input type="radio" @change="displayNext" v-model="reponse3" :value="'jamais'"> jamais
                    </label><br>
                </div>
                <div v-if="reponse3 === 'rarement' || reponse3 === 'jamais'">
                    <div class="interview-questions-title">
                        <span class="interview-questions-title-question">
                            Pourquoi il ne les utilise pas ?
                        </span>
                    </div>
                    <div>
                        <v-textarea
                            solo
                            name="input-7-4"
                            label="Saisissez la réponse"
                            v-model="reponse4"
                            @keydown="answerEdited(q)"
                        ></v-textarea>
                    </div>
                </div>
            </div>
            
        </div>
        <div v-if="q.edited || q.answered">
            <v-btn :loading="q.saving" :disabled="! q.edited || q.saving" color="primary" @click="saveAnswer(q)">Enregistrer</v-btn>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            step: 1,
            reponse1: '',
            reponse2: '',
            reponse3: '',
            reponse4: '',
        }
    },
    methods: {
        saveAnswer(question) {
            question.comment = {
                reponse1: this.reponse1,
                reponse2: this.reponse2,
                reponse3: this.reponse3,
                reponse4: this.reponse4,
            };
            this.$emit('saved', question);
            // clean data
            question.comment = '';
            this.reponse1 = '';
            this.reponse2 = '';
            this.reponse3 = '';
            this.reponse4 = '';
        },
        answerEdited(question) {
            if( ! question.edited ) {
                question.edited = true;
            }
            this.$emit('editing', question);
        },
        displayNext() {
            /*if( this.reponse1 == 'avoir besoin d’un appui' ) {
                this.step = 2;
            } else {
                this.step = 3;
            }*/
            this.q.edited = true;
        }
    },
    props: [
        'q',
    ],
}
</script>