<template>
    <div>
        <div v-if="q.answered">
            <div class="interview-questions-title">
                <span class="interview-questions-title-number" :style="'background-color:'+q.question.category.color">{{ q.question.num }}</span> <span class="interview-questions-title-question" v-html="q.question.question"></span>
            </div>
            <div>
                <v-textarea
                    solo
                    name="input-7-4"
                    label="Saisissez la réponse"
                    v-model="q.answer"
                    @keydown="answerEdited(q)"
                ></v-textarea>
                <template v-if="! q.saving">
                    <a href="#" class="interview-questions-reinit" @click.prevent="$emit('reinit', q);">
                        Réinitialiser
                    </a>
                </template>
            </div>
        </div>
        <div v-else>
            <div>
                <div class="interview-questions-title">
                    <span class="interview-questions-title-number" :style="'background-color:'+q.question.category.color">{{ q.question.num }}</span> 
                    <span class="interview-questions-title-question">
                        1) D'après le recuteur, ses difficultés sont elles dues au fait que pas ou peu de candidats se positionnent sur ses offres ?
                    </span>
                </div>
                <div class="mb-4">
                    <label class="mr-3">
                        <input type="radio" @change="displayNext" v-model="oui_non" :value="'oui'"> Oui
                    </label>
                    <label>
                        <input type="radio" @change="displayNext" v-model="oui_non" :value="'non'"> Non
                    </label>
                </div>
            </div>
            <div v-if="step == 2">
                <div class="interview-questions-title">
                    <span class="interview-questions-title-question">
                        2) Si oui, d'après lui, quels seraient les freins des candidats ? (poste, secteur, entreprise)
                    </span>
                </div>
                <div class="">
                    <label>
                        <input type="checkbox" @change="answerEdited(q)" v-model="freins" value="du poste"> du poste
                        <v-tooltip top color="black" :max-width="500">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon small v-bind="attrs" v-on="on" style="vertical-align:baseline">mdi-information</v-icon>
                            </template>
                            <span>
                                Poste physique, contraintes horaires, salaire peu attractif car soumis à convention ...
                            </span>
                        </v-tooltip>
                    </label><br>
                    <label>
                        <input type="checkbox" @change="answerEdited(q)" v-model="freins" value="de votre secteur"> de votre secteur
                        <v-tooltip top color="black" :max-width="500">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon small v-bind="attrs" v-on="on" style="vertical-align:baseline">mdi-information</v-icon>
                            </template>
                            <span>
                                Secteur mal ou peu connu ...
                            </span>
                        </v-tooltip>
                    </label><br>
                    <label>
                        <input type="checkbox" @change="answerEdited(q)" v-model="freins" value="de votre entreprise"> de votre entreprise
                        <v-tooltip top color="black" :max-width="500">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon small v-bind="attrs" v-on="on" style="vertical-align:baseline">mdi-information</v-icon>
                            </template>
                            <span>
                                Image négative, turn over récurrent ...
                            </span>
                        </v-tooltip>
                    </label>
                    <div class="mb-2"></div>
                    <v-textarea
                        solo
                        name="input-7-4"
                        label="Commentaire"
                        v-model="comment"
                    ></v-textarea>
                </div>
            </div>
        </div>
        <div v-if="step == 2 || step == 3 || q.answered">
            <v-btn :loading="q.saving" :disabled="! q.edited || q.saving" color="primary" @click="saveAnswer(q)">Enregistrer</v-btn>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            step: 1,
            oui_non: false,
            freins: [],
            comment: '',
        }
    },
    methods: {
        saveAnswer(question) {
            question.comment = {
                step1: this.oui_non,
                options: this.freins
            };
            if( this.comment && this.comment.length > 0 ) {
                question.comment.options.push(this.comment);
            }
            this.$emit('saved', question);
            // clean data
            question.comment = '';
            this.oui_non = false;
            this.step = 1;
            this.freins = [];
        },
        answerEdited(question) {
            if( ! question.edited ) {
                question.edited = true;
            }
            this.$emit('editing', question);
        },
        displayNext() {
            if( this.oui_non == 'oui' ) {
                this.step = 2;
            } else {
                this.step = 3;
            }
            this.q.edited = true;
        }
    },
    props: [
        'q',
    ],
}
</script>