<template>
    <div class="login">
        <v-card class="elevation-4 mt-5 pt-2 pb-4">
            <v-card-text>
                <h2 class="mb-2">Outil d'aide au diagnostic</h2>

                <v-form @submit.prevent="login" class="pb-5">
                    <div v-if="$store.state.status == 'error'">
                        <v-alert type="error">
                            Email ou mot de passe incorrect
                        </v-alert>
                    </div>

                    <v-text-field label="Email" name="email" type="email" autocomplete="off" v-model="email" :readonly="$store.state.status == 'loading'" />
                    <v-text-field id="password" label="Mot de passe" name="password" type="password" autocomplete="new-password" v-model="password" :readonly="$store.state.status == 'loading'" />
                    <div class="text-right mb-2">
                        <router-link :to="{ name: 'passwordLost' }">
                            <small>Mot de passe oublié</small>
                        </router-link>
                    </div>
                    <v-card-actions class="justify-center">
                        <v-btn type="submit" color="primary" :loading="$store.state.status == 'loading'" :disabled="$store.state.status == 'loading'">Connexion</v-btn>
                    </v-card-actions>
                </v-form>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
    export default {
        data(){
            return {
                email : "",
                password : ""
            }
        },
      mounted(){
        //let routes = this.$router.getRoutes()
        // console.log(routes)
      },
        methods: {
            login: function () {
                let email = this.email;
                let password = this.password;
                this.$store.dispatch('login', { email, password })
                    .then(() => {
                        if( this.$route.query.from ) {
                            this.$router.push(this.$route.query.from);
                        } else {
                            this.$router.push('/');
                        }
                    })
                    .catch( () => {
                        //console.log(err)
                    })
            }
        }
    }
</script>
