<template>
    <v-container
        fluid
    >
        <v-snackbar
            v-model="snackbar"
            :color="snackbarColor"
        >
            {{ snackbarText }}

            <template v-slot:action="{ attrs }">
                <v-btn
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    Fermer
                </v-btn>
            </template>
        </v-snackbar>

        <v-dialog v-model="modal_share" width="750px">
            <v-card>
                <v-card-title>
                    <span class="headline mr-2">
                        Avec qui souhaitez-vous partager ce modèle ?
                        <v-tooltip top color="black" :max-width="500">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" v-on="on" class="ml-1" color="black">mdi-information</v-icon>
                            </template>
                            <span>
                                Aucune notification n’est envoyée, mais le conseiller le verra apparaître dans son listing de modèles.
                            </span>
                        </v-tooltip>
                    </span>
                </v-card-title>
                <v-card-text>
                    <Loading v-if="loadingUsers" />
                    <template v-else>
                        <v-select
                            :items="users"
                            :item-text="getFieldText"
                            item-value="id"
                            label="Sélectionnez un conseiller"
                            v-model="user_selected"
                            return-object
                        ></v-select>
                        <v-btn @click.prevent="shareModel" :loading="loadingShare" :disabled="loadingShare" color="primary">
                            Partager
                        </v-btn>
                    </template>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="darken-1" text @click="modal_share = false">Fermer</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-card class="mb-3">
            <v-card-text>
                <h3 class="text--primary">
                    Vos modèles d'entretien
                </h3>
            </v-card-text>
            <div v-if="loading">
                <Loading />
            </div>
            <div v-else>
                <v-simple-table v-if="projectmodels && projectmodels.length > 0">
                    <template v-slot:default>
                        <thead>
                        <tr>
                            <th class="text-left">Nom</th>
                            <th class="text-left">Nb de questions</th>
                            <th class="text-left">Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="p in projectmodels" :key="'model_'+p.id">
                            <td>{{ p.name }}</td>
                            <td>{{ p.modelquestions_count }}</td>
                            <td>
                                <v-btn small color="primary" outlined dark class="mr-2" @click.prevent="goToEdit(p.id)">
                                    Éditer
                                </v-btn>
                                <v-btn small color="green" outlined dark class="mr-2" @click.prevent="display_share_modal(p)">
                                    Partager
                                </v-btn>
                                <v-btn small color="orange" outlined dark @click.prevent="confirmDelete(p)">
                                    Supprimer
                                </v-btn>
                            </td>
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>
                <v-card-text v-else>
                    <em>Vous n'avez pas encore créé de modèles d'entretien.</em>
                </v-card-text>
            </div>
        </v-card>
        <CreateProjectmodel @modelCreated="goToEdit" />

        <v-dialog v-model="dialogConfirmDelete" width="600px">
            <v-card :loading="loading">
                <v-card-title>
                    <h3>Supprimer le modèle</h3>
                </v-card-title>
                <v-card-text>
                    <p>
                        Êtes-vous sûr de vouloir supprimer le modèle : "<em>{{ selected_delete_model.name }}</em>" ?
                    </p>
                    <p>
                        Seul le modèle sera supprimé. Les questions et les entretiens ne seront pas impactés.<br>
                        Si vous supprimez un entretien partagé, ça le supprime pour vous mais pour les conseillers avec qui vous l’avez partagé.
                    </p>
                    <v-btn
                        dark
                        color="red"
                        class="mr-4"
                        @click="deleteModel"
                    >
                        Supprimer
                    </v-btn>
                    <v-btn color="darken-1" text @click="dialogConfirmDelete = false">Annuler</v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import CreateProjectmodel from '@/components/CreateProjectmodel'; 
import Loading from '@/components/Loading'; 

export default {
    data() {
        return {
            loading: false,
            projectmodels: [],
            dialog: false,
            dialogConfirmDelete: false,
            selected_delete_model: false,
            fieldRules: [
                v => !!v || 'Ce champ est requis',
            ],
            snackbar: false,
            snackbarColor: 'success',
            snackbarText: '',
            selected_share_model: false,
            loadingShare: false,
            loadingUsers: false,
            modal_share: false,
            users: [],
            user_selected: false,
        }
    },
    created() {
        this.getModels();
    },
    methods: {
        getModels() {
            this.loading = true;
            this.$http
                .get(process.env.VUE_APP_API + '/projectmodels')
                .then(response => {
                    this.projectmodels = response.data.data;
                    this.loading = false;
                }).catch( () => {
                    //this.errorResults = true;
                    this.loading = false;
                });
        },
        goToEdit(item_id) {
            this.$router.push({
                name: 'models.edit',
                params: {
                    id: item_id,
                }
            });
        },
        confirmDelete(model) {
            this.selected_delete_model = model;
            this.dialogConfirmDelete = true;
        },
        deleteModel() {
            this.loading = true;
            this.$http
                .delete(process.env.VUE_APP_API + '/projectmodels/' + this.selected_delete_model.id)
                .then( () => {
                    this.loading = false;
                    this.getModels();
                    this.snackbarText = "Modèle supprimé";
                    this.snackbarColor = 'success';
                    this.snackbar = true;
                    this.dialogConfirmDelete = false;
                    this.selected_delete_model = false;
                }).catch( () => {
                    //this.errorResults = true;
                    this.loading = false;
                });
        },
        display_share_modal(model) {
            this.selected_share_model = model;
            this.modal_share = true;
            this.loadingUsers = true;
            this.$http
                .get(process.env.VUE_APP_API + '/projectmodels/users')
                .then(response => {
                    this.users = response.data.data;
                    this.loadingUsers = false;
                }).catch( () => {
                    //this.errorResults = true;
                    this.loadingUsers = false;
                });
        },
        shareModel() {
            this.loadingShare = true;
            this.$http
                .post(process.env.VUE_APP_API + '/projectmodels/' + this.selected_share_model.id + '/share', {
                    user_id: this.user_selected.id
                })
                .then( () => {
                    this.snackbarText = "Modèle partagé";
                    this.snackbarColor = 'success';
                    this.snackbar = true;
                    this.loadingShare = false;
                    this.modal_share = false;
                }).catch( () => {
                    //console.log(error);
                    this.loadingShare = false;
                });
        },
        getFieldText(item) {
            return `${item.firstname} ${item.lastname}`;
        }
    },
    components: {
        CreateProjectmodel,
        Loading
    }
}
</script>