<template>
    <div class="interviewedit">

        <v-snackbar
            v-model="snackbar"
            :color="snackbarColor"
        >
            {{ snackbarText }}

            <template v-slot:action="{ attrs }">
                <v-btn
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    Fermer
                </v-btn>
            </template>
        </v-snackbar>

        <div v-if="loading" class="pt-4">
            <Loading />
        </div>
        <v-container v-else fluid class="pb-5">
            <div class="topbar">
                <div class="topbar-title">
                    <span class="topbar-title-span">
                        Vous consultez l'entretien de : {{ project.employer.company }}
                    </span>
                    <Autodiagnostic v-if="project && project.employers" :employer="project.employer" :employers="project.employers" @reload="getProject" />
                    <Autodiagnostic v-else :employer="project.employer" @reload="getProject" />
                </div>
                <div class="topbar-actions">

                    <!--
                    <v-btn color="black" small dark class="mr-2" :to="{ name: 'interviews.edit', params: {id: project.id} }">Modifier le formulaire</v-btn>
                    <v-btn color="orange" small dark :to="{ name: 'interview', params: {id: project.id}, query: {edit: 1} }">Modifier les réponses</v-btn>
                    -->
                    <v-btn color="black" small dark class="mr-2" :to="{ name: 'interviews.editreport', params: {id: project.id} }">Rédiger le rapport</v-btn>

                    <template v-if="loadingPdf">
                        <v-btn class="mr-2" small disabled>Génération en cours...</v-btn>
                    </template>
                    <template v-else>
                        <v-btn class="mr-2" small @click="downloadReport"><v-icon>mdi-download</v-icon> Générer le rapport</v-btn>
                    </template>
                </div>
            </div>
            <div class="row pb-5">
                <div class="col-lg-6 col-md-6 col-12">
                    <v-card>
                        <v-card-text>
                            <h3>Diagnostic RH</h3>
                        </v-card-text>
                        <v-data-table
                            :headers="headers"
                            :items="selectedQuestions"
                            :options.sync="options"
                            :loading="loadingSelectedQuestions"
                            :no-data-text="'Aucune question disponible'"
                            class="elevation-1"
                            fluid
                        >
                            <template v-slot:item.question_html="{ item }">
                                <template v-if="item.question && item.question.question">
                                  <strong style="margin-top: 15px; display: inline-block"><span v-html="item.question.question"></span></strong>
                                </template>
                                <template v-else-if="item.customquestion && item.customquestion.question">
                                    <strong style="margin-top: 15px; display: inline-block">{{ item.customquestion.question }}</strong>
                                </template>
                                <br>
                                <p style="margin-top: 15px; margin-bottom: 15px">{{ item.answer }}</p>
                            </template>
                        </v-data-table>
                    </v-card>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                    <v-card>
                        <template v-if="scores">
                            <div ref="chart1" id="chart1">
                                <apexchart height="" type="radar" :options="chart1Options" :series="chart1data"></apexchart>
                            </div>
                        </template>
                    </v-card>
                    <v-row>
                        <v-col class="pb-0">
                            <v-card class="pa-3">
                                <h3>{{ project.employer.date_appointment }}</h3>
                                <small><strong class="grey--text">DATE DE L'ENTRETIEN</strong></small>
                            </v-card>
                        </v-col>
                        <v-col class="pb-0">
                            <v-card class="pa-3">
                                <h3>{{ selectedQuestions.length }}</h3>
                                <small><strong class="grey--text">QUESTIONS-RÉPONSES</strong></small>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="pb-0">
                            <v-card class="pa-3">
                                Prochain point de situation : <strong class="orange--text" style="font-size:18px">{{ project.date_next_step_1 }}</strong>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="pb-0">
                            <v-card class="pa-3 text-center">
                                <v-btn outlined color="primary" small @click.prevent="modalNotes = true">Consultez vos notes</v-btn>
                            </v-card>
                        </v-col>
                    </v-row>
                </div>
            </div>

            <v-dialog v-model="modalNotes" width="750px">
                <v-card class="">
                    <v-card-text class="pt-5">
                        <h2 class="mb-4">
                            Notes personnelles
                        </h2>
                        <p style="white-space: pre-line;" v-if="project.note && project.note.length > 0">
                            {{ project.note }}
                        </p>
                        <p v-else>
                            <em>Aucunes notes personnelles.</em>
                        </p>
                        <p class="text-center">
                            <v-btn class="mb-1" color="darken-1" text @click.prevent="modalNotes = false">Fermer</v-btn>
                        </p>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-container>
    </div>
</template>

<script>
import Autodiagnostic from '@/components/Autodiagnostic';
import moment from 'moment';
import JQuery from 'jquery';
let $ = JQuery;
import downloadPDF from '@/functions/downloadPDF.js';
import Loading from '@/components/Loading';

export default {
    data(){
        return {
            loading: false,
            loadingQuestions: false,
            loadingSelectedQuestions: false,
            project: false,
            categories: [],
            selectedQuestions: [],
            modalNotes: false,
            options: {
                page: 1,
                itemsPerPage: 10
            },
            headers: [
                {
                    text: 'Question',
                    sortable: false,
                    value: 'question_html',
                }
            ],
            snackbar: false,
            snackbarText: '',
            snackbarColor: 'sucess',
            chart1data: [
                {
                    name: 'Auto-diagnostic',
                    data: [],
                },
                {
                    name: 'Entretien',
                    data: [],
                },
            ],
            chart1Options: {
                chart: {
                    type: 'radar',
                    toolbar: {
                        show: false
                    }
                },
                xaxis: {
                    categories: []
                },
                yaxis: {
                    min: 0,
                    max: 10,
                },
                grid: {
                    show: false,
                    xaxis: {
                        lines: {
                            show: false
                        }
                    },
                    yaxis: {
                        lines: {
                            show: false
                        }
                    },
                    row: {
                        colors: undefined,
                        opacity: 0
                    },
                    column: {
                        colors: undefined,
                        opacity: 0
                    },
                    padding: {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0
                    },
                }
            },
            scores: false,
            loadingPdf: false
        }
    },
    created() {
        this.getProject();
    },
    methods: {
        getProject() {
            this.loading = true;
            this.$http
                .get(process.env.VUE_APP_API + '/projects/' + this.$route.params.id)
                .then(response => {
                    this.project = response.data.data;
                    if( this.project.employer.date_appointment && this.project.employer.date_appointment.length > 0 ) {
                        this.date_appointment = moment(String(this.project.employer.date_appointment)).format('YYYY-MM-DD');
                        this.appointment_hour = moment(String(this.project.employer.date_appointment)).format('HH');
                        this.appointment_minutes = moment(String(this.project.employer.date_appointment)).format('mm');

                        this.project.employer.date_appointment = moment(String(this.project.employer.date_appointment)).format('DD/MM/YYYY') + ' ' + moment(String(this.project.employer.date_appointment)).format('HH') + 'h' + moment(String(this.project.employer.date_appointment)).format('mm');
                    }
                    if( this.project.date_next_step_1 ) {
                        this.project.date_next_step_1 = moment(String(this.project.date_next_step_1)).format('DD/MM/YYYY');
                    }
                    if( this.project.date_next_step_2 ) {
                        this.project.date_next_step_2 = moment(String(this.project.date_next_step_2)).format('DD/MM/YYYY');
                    }
                    this.getSelectedQuestions();
                    this.getEmployerScore();
                    this.loading = false;
                }).catch( () => {
                    this.loading = false;
                });
        },
        getSelectedQuestions() {
            this.loadingSelectedQuestions = true;
            this.$http
                .get(process.env.VUE_APP_API + '/projects/' + this.$route.params.id + '/questions')
                .then(response => {
                    this.selectedQuestions = response.data.data;
                    this.loadingSelectedQuestions = false;
                }).catch( () => {
                    this.loadingSelectedQuestions = false;
                });
        },
        getEmployerScore() {
            this.$http
                .get(process.env.VUE_APP_API + '/projects/' + this.$route.params.id + '/cspcomparison')
                .then(response => {
                    this.chart1data[0].data = response.data.chart_1_points;
                    this.chart1data[1].data = response.data.chart_1_points_pe;
                    this.chart1Options.xaxis.categories = response.data.chart_1_labels;
                    this.scores = response.data;
                    this.loading = false;
                }).catch( () => {
                    this.loading = false;
                });
        },
        downloadReport() {
            var chart1 = $("#chart1").find('svg')[0].outerHTML;
            this.loadingPdf = true;
            this.$http
                .post(process.env.VUE_APP_API + '/projects/' + this.project.id + '/report', {
                    chart1: chart1,
                }, { responseType: 'arraybuffer'} )
                .then(response => {
                    downloadPDF(response.data, 'rapport');
                    this.loadingPdf = false;
                }).catch( () => {
                    //console.log(error);
                    this.loadingPdf = false;
                });
        }
    },
    components: {
        Autodiagnostic,
        Loading
    }
}
</script>
