<template>
    <div class="d-inline-block">
        <a @click.prevent="openDialog">
            <v-icon color="primary" class="mr-1">mdi-plus-circle-outline</v-icon> Question personnalisée
        </a>

        <v-dialog v-model="dialog" width="600px">
            <v-card :loading="loading">
                <v-card-text class="pt-3">
                    <v-tabs
                        v-model="activetab"
                        align-with-title
                        background-color="#eee"
                        grow
                        centered
                    >
                        <v-tabs-slider color="#f16c08"></v-tabs-slider>

                        <v-tab :key="'tab_1'" v-if="fulltabs">Vos questions</v-tab>
                        <v-tab :key="'tab_2'">Nouvelle question</v-tab>

                        <v-tab-item :key="'tab_1'" class="pt-3" v-if="fulltabs">
                            <h3 class="mb-3">Choisissez une de vos questions</h3>
                            <div v-if="loading">
                                <Loading />
                            </div>
                            <div v-else>
                                <template v-if="questions.length === 0">
                                    <em>Vous n'avez pas encore créé de question personnalisée</em>
                                </template>
                                <template v-else>
                                    <v-simple-table>
                                        <template v-slot:default>
                                            <thead>
                                            <tr>
                                                <th class="text-left">Question</th>
                                                <th class="text-left">Action</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="q in questions" :key="'customquestion_'+q.id">
                                                <td>{{ q.question }}</td>
                                                <td>
                                                    <v-btn @click="sendBackQuestion(q)">
                                                        Ajouter
                                                    </v-btn>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </template>

                            </div>
                        </v-tab-item>

                        <v-tab-item :key="'tab_2'" class="pt-3">
                            <h3 class="">Créer une nouvelle question</h3>
                            <v-form ref="form" @submit.prevent="saveQuestion">
                                <v-text-field
                                    v-model="question"
                                    :rules="fieldRules"
                                    label="Rédigez votre question"
                                    required
                                ></v-text-field>
                                <div class="">
                                    <v-btn
                                        color="primary"
                                        class="mr-4"
                                        @click="saveQuestion"
                                    >
                                        Enregistrer
                                    </v-btn>
                                </div>
                            </v-form>
                        </v-tab-item>
                    </v-tabs>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="darken-1" text @click="dialog = false">Fermer</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import Loading from '@/components/Loading';

export default {
    data() {
        return {
            loading: false,
            dialog: false,
            questions: [],
            question: '',
            fieldRules: [
                v => !!v || 'Ce champ est requis',
            ],
            activetab: 'tab_1',
        }
    },
    created() {
        //this.getCustomQuestions();
    },
    props: [
        'fulltabs'
    ],
    methods: {
        openDialog() {
            this.dialog = true;
            this.getCustomQuestions();
        },
        getCustomQuestions() {
            this.loading = true;
            this.$http
                .get(process.env.VUE_APP_API + '/customquestions')
                .then(response => {
                    this.questions = response.data.data;
                    this.loading = false;
                }).catch( () => {
                    //this.errorResults = true;
                    this.loading = false;
                });
        },
        saveQuestion() {
            this.loading = true;
            this.$http
                .post(process.env.VUE_APP_API + '/customquestions', {
                    question: this.question
                })
                .then( () => {
                    this.question = '';
                    if( this.fulltabs ) {
                        this.activetab = 'tab_1';
                        this.getCustomQuestions();
                    } else {
                        this.dialog = false;
                        this.$emit('reloadQuestions', 1);
                    }

                }).catch( () => {
                    //this.errorResults = true;
                    this.loading = false;
                });
        },
        sendBackQuestion(question) {
            this.$emit('addCustomQuestion', question);
            this.dialog = false;
        }
    },
    components: {
        Loading
    }
}
</script>
