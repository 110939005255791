<template>
    <v-app>
        <component :is="layout">
            <router-view/>
        </component>
    </v-app>
</template>


<script>
const default_layout = "default";

export default {
    computed : {
        layout() {
            return (this.$route.meta.layout || default_layout) + "-layout";
        }
    },
    methods: {
        logout: function () {
            this.$store.dispatch('logout')
                .then(() => {
                    if( this.$route.name != 'login' ) {
                        this.$router.push({
                            name: 'login',
                        });
                    }
                })
        }
    },
    created: function () {
        let self = this;
        this.$http.interceptors.response.use(undefined, function (err) {
            return new Promise(function (/*resolve, reject*/) {
                if( err.response.status === 401 ) {
                    self.logout(); // TODO : to check
                } else if( err.response.status === 403 ) {
                    //console.log(err.response.data.message);
                    self.logout(); // TODO : to check

                    /*if( err.response.data.message === 'Your email address is not verified.' ) {
                        self.$router.push({
                            name: 'noverifiedemail',
                        });
                    } else if( err.response.data.message === 'No user' ) {
                        self.$router.push({
                            name: 'nouser',
                        });
                    }*/
                }

                throw err;
            });
        });
    }
}
</script>

<style lang="scss">
    @import 'assets/styles/main.scss';
</style>
