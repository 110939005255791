<template>
    <div class="login">
        <v-card class="elevation-4 mt-5">
            <v-toolbar flat>
                <v-toolbar-title>Outil d'aide au diagnostic</v-toolbar-title>
            </v-toolbar>
            <div v-if="loading" class="pt-4">
                <Loading />
            </div>
            <div v-else>
                <div ></div>
                <v-form @submit.prevent="resetPass" v-if="tokenOk" class="pb-3">
                    <div class="ma-3" v-if="message && message.length > 0">
                        <div v-if="success">
                            <v-alert type="success">
                                {{ message }}
                            </v-alert>
                            <v-btn :to="{ name: 'login' }">
                                Me connecter
                            </v-btn>
                        </div>
                        <v-alert type="error" v-else>
                            {{ message }}
                        </v-alert>
                    </div>
                    <div v-if="! success">
                        <v-card-text>
                            <v-text-field
                                id="password"
                                label="Mot de passe"
                                name="password"
                                :type="showPassword ? 'text' : 'password'"
                                autocomplete="new-password"
                                hint="Le mot de passe doit faire au minimum 8 caractères, contenir une majuscule, un caractère spécial ainsi qu'un chiffre."
                                v-model="password"
                                :readonly="loading"
                                :rules="[passwordRules.required, passwordRules.main]"
                                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                @click:append="showPassword = !showPassword" />
                            <v-text-field
                                id="confirm_password"
                                label="Confirmez votre mot de passe"
                                name="confirm_password"
                                :type="showPasswordConfirm ? 'text' : 'password'"
                                autocomplete="new-password"
                                v-model="confirm_password"
                                :rules="[passwordRules.match]"
                                :append-icon="showPasswordConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                                @click:append="showPasswordConfirm = !showPasswordConfirm"
                                :readonly="loading" />
                        </v-card-text>
                        <p style="padding: 0 16px 16px; font-style: italic; font-size: .8rem">Votre mot de passe doit faire au minimum 8 caractères, contenir une majuscule, un caractère spécial ainsi qu'un chiffre.</p>
                        <v-card-actions class="justify-center">
                            <v-btn type="submit" color="primary" :loading="loading" :disabled="loading">Réinitialiser mon mot de passe</v-btn>
                        </v-card-actions>
                    </div>
                </v-form>
                <div v-else>
                    <v-alert type="error">
                        Ce lien est incorrect.<br>
                        <router-link :to="{ name: 'passwordLost' }">
                            Demander une nouvelle réinitialisation
                        </router-link>
                    </v-alert>
                </div>
            </div>
        </v-card>
    </div>
</template>

<script>
    import Loading from '@/components/Loading';

    export default {
        data(){
            return {
                loading: true,
                tokenOk: false,
                password : "",
                confirm_password : "",
                showPassword: false,
                showPasswordConfirm: false,
                message: null,
                success: false,

                passwordRules: {
                      required: value => !!value || 'Requis.',
                      main: value => {
                        if (value && /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(value)) return true

                        return 'Le mot de passe ne respecte pas les règles de sécurité.'
                      },
                      match: () => {
                        if (this.password === this.confirm_password) return true

                        return 'Les mots de passe ne correspondent pas.'
                      }
                },
            }
        },
        created() {
            this.controlToken();
        },
        methods: {

            controlToken() {
                this.loading = true;
                this.$http
                    .post(process.env.VUE_APP_API + '/auth/token', {
                        token: this.$route.params.token,
                    })
                    .then( () => {
                        this.tokenOk = true;
                        this.loading = false;
                    }).catch( () => {
                        this.tokenOk = false;
                        this.loading = false;
                    });
            },
            resetPass() {
                this.loading = true;
                this.message = null;
                this.success = false;
                this.$http
                .post(process.env.VUE_APP_API + '/auth/reset-password', {
                    token: this.$route.params.token,
                    email: this.$route.params.email,
                    password: this.password,
                    confirm_password: this.confirm_password,
                })
                .then( () => {
                    this.message = "Votre mot de passe a bien été modifié. Vous pouvez maintenant vous connecter.";
                    this.loading = false;
                    this.success = true;
                }).catch( err => {
                    this.message = err.response.data.message;
                    this.loading = false;
                });
            }
        },
        components: {
            Loading
        }
    }
</script>
