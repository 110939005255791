<template>
    <div class="interview">

        <v-snackbar
            v-model="snackbar"
            :color="snackbarColor"
        >
            {{ snackbarText }}

            <template v-slot:action="{ attrs }">
                <v-btn
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    Fermer
                </v-btn>
            </template>
        </v-snackbar>

        <template v-if="! $route.query.edit">
            <v-dialog v-model="consentement" width="750px">
                <v-card class="text-center">
                    <v-card-text class="pt-5">
                        <h2 class="mb-4">
                            Consentement de l'entreprise
                        </h2>
                        <p v-if="project && project.employer && project.employer.company">
                            Avez-vous bien recueilli l'accord de l'entreprise pour {{ project.employer.company }} ?
                        </p>
                        <p>
                            <label>
                                <input type="checkbox" v-model="consentement_check"> Je certifie avoir reçu l'accord de l'entreprise pour mener à bien cet entretien au regard des <a href="#" @click.prevent="modal_cgu = true">conditions d’utilisation</a>
                            </label>
                        </p>
                        <p>
                            À rappeler : il s'agit d'une expérimentation dans le cadre d'une démarche innovante.
                        </p>
                        <p>
                            <v-btn :disabled="! consentement_check" @click="consentement = false">
                                Démarrer
                            </v-btn>
                        </p>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </template>

        <v-dialog v-model="confirmDelete" width="600px">
            <v-card>
                <v-card-title>
                    <h3>Supprimer la question</h3>
                </v-card-title>
                <v-card-text>
                    <p class="text-center">
                        Êtes-vous sûr de vouloir supprimer cette question de l'entretien :<br>
                        "<template v-if="selectedDeleteQuestion.question && selectedDeleteQuestion.question.question"><span v-html="selectedDeleteQuestion.question.question"></span></template><template v-else-if="selectedDeleteQuestion.customquestion && selectedDeleteQuestion.customquestion.question">{{ electedDeleteQuestion.customquestion.question }}</template>"
                    </p>
                    <div class="text-center">
                        <v-btn color="orange" text @click="removeQuestion(selectedDeleteQuestion)">Supprimer la question</v-btn> <v-btn color="darken-1" text @click="confirmDelete=false">Annuler</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>


        <v-dialog v-model="modal_cgu" width="600px">
            <v-card>
                <v-card-title>
                    <h3>Conditions d'utilisation</h3>
                </v-card-title>
                <v-card-text>
                    <p>
                      Votre interlocuteur accepte que les informations recueillies soient hébergées sur un site sécurisé, hébergé en France pendant la durée de l’expérimentation  c’est-à-dire d’avril 2024 à février 2025.
                    </p>
                    <p>
                      Il/ elle consent à ce que ces données soient partagées avec l’ensemble du personnel France Travail ainsi que ses partenaires amenés à intervenir dans l’accompagnement au recrutement, et accepte de partager son avis sur la démarche dans le cadre d’une évaluation. L’entreprise peut demander à tout moment que soit supprimée toute ou partie de ces informations en s’adressant directement à son conseiller ou via l’adresse mail : diagrenforceent.00174@francetravail.fr. France Travail et ses partenaires s’engagent à respecter le secret de tout fait et / ou information recueillis lors de vos échanges.
                    </p>
                    <div class="text-center">
                        <v-btn color="darken-1" text @click="modal_cgu=false">Fermer</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

        <template v-if="loading">
            <Loading />
        </template>
        <v-container v-else fluid>
            <div class="topbar">
                <div class="topbar-title">
                    <span class="topbar-title-span">
                        Entretien : {{ project.employer.company }}
                    </span>
                </div>
            </div>
            <div class="interview-questions" v-if="project.status && project.status === 'Terminé'">
                <v-row align="center" justify="center">
                    <v-col cols="12" lg="8" md="10">
                        <v-card>
                            <v-card-text class="text-center">
                                <h3 class="mb-2">Entretien terminé</h3>
                                <p>
                                    Vous ne pouvez plus éditer cet entretien.
                                </p>
                                <div>
                                    <v-btn small :to="{ name: 'interviews.see', params: {id: project.id} }">Retour à la consultation</v-btn>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </div>
            <div class="interview-questions" v-else>
                <v-row>
                    <v-col cols="12" md="3" class="d-md-block d-none">
                        <div class="interview-questions-list">
                            <div v-for="(c, name) in categories" :key="'main_categ_'+name" class="mb-4">
                                <div class="interview-questions-list-main">
                                    <strong :style="'color:'+c.color">
                                        {{ name }}
                                    </strong>
                                </div>
                                <div v-for="(sc, scname) in c.categs" :key="'sub_categ_'+scname" class="mb-2">
                                    <div class="interview-questions-list-sub" @click="sc.open = ! sc.open">
                                        {{ scname }} ({{ sc.categs.length }}) <template v-if="sc.open"><v-icon color="black">mdi-chevron-down</v-icon></template><template v-else><v-icon color="black">mdi-chevron-right</v-icon></template>
                                    </div>
                                    <div class="interview-questions-list-questions" :class="{ open: sc.open}">
                                        <div v-for="(q, index) in sc.categs" :key="'q_'+index" class="interview-questions-list-question">
                                            <a href="#" @click.prevent="$vuetify.goTo('#q_'+q.id)">
                                                <span v-html="q.question.question"></span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--<div v-for="q in selectedQuestions" :key="'sommaire_q_'+q.id">
                                <a href="#" @click.prevent="$vuetify.goTo('#q_'+q.id)">
                                    <template v-if="q.question && q.question.question">
                                        Question {{ q.question.num }}
                                    </template>
                                    <template v-else-if="q.customquestion && q.customquestion.question">
                                        Question personnalisée
                                    </template>
                                </a>
                            </div>-->
                        </div>
                    </v-col>
                    <v-col cols="12" md="8">
                        <div v-if="loadingSelectedQuestions" class="pt-5 mt-5">
                            <Loading />
                        </div>
                        <v-card class="mb-5" min-width="100%" v-for="q in selectedQuestions" :key="'q_'+q.id" :id="'q_'+q.id">
                            <div v-if="editing_question && editing_question != q.id" class="interview-questions-hide"></div>
                            <v-card-text>
                                <div class="interview-questions-delete" @click="displayConfirmDelete(q)">
                                    <v-icon>mdi-delete</v-icon>
                                </div>
                                <div v-if="q.question && q.question.question && q.question.type != 'custom'" class="interview-questions-title">
                                    <span class="interview-questions-title-number" :style="'background-color:'+q.question.category.color">{{ q.question.num }}</span> <span class="interview-questions-title-question" v-html="q.question.question"></span>
                                    <template v-if="q.question.infobulle_front && q.question.infobulle_front.length > 0">
                                        <v-tooltip top color="black" :max-width="500">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon v-bind="attrs" v-on="on" class="ml-2" small>mdi-information</v-icon>
                                            </template>
                                            <span>{{ q.question.infobulle_front }}</span>
                                        </v-tooltip>
                                    </template>
                                </div>
                                <div v-else-if="q.customquestion && q.customquestion.question" class="interview-questions-title">
                                     <span class="interview-questions-title-question">
                                         {{ q.customquestion.question }}
                                     </span>
                                </div>

                                <template v-if="q.question && q.question.type && q.question.type === 'checkbox'">
                                    <template v-if="q.answered">
                                        <v-textarea
                                            solo
                                            name="input-7-4"
                                            label="Saisissez la réponse"
                                            v-model="q.answer"
                                            @keydown="answerEdited(q)"
                                        ></v-textarea>
                                        <template v-if="! q.saving">
                                            <a href="#" class="interview-questions-reinit" @click.prevent="reinitAnswer(q)">
                                                Réinitialiser
                                            </a>
                                        </template>
                                    </template>
                                    <template v-else>
                                        <div class="mb-2">
                                            <div v-for="o in q.question.options" :key="'option_'+o.id">
                                                <label>
                                                    <input type="checkbox" @change="answerEdited(q)" v-model="q.selectedOptions" :value="o.value"> {{ o.value }}
                                                </label><br>
                                            </div>
                                        </div>
                                    </template>
                                </template>
                                <template v-if="q.question && q.question.type && q.question.type === 'radio'">
                                    <template v-if="q.answered">
                                         <v-textarea
                                            solo
                                            name="input-7-4"
                                            label="Saisissez la réponse"
                                            v-model="q.answer"
                                            @keydown="answerEdited(q)"
                                        ></v-textarea>
                                        <template v-if="! q.saving">
                                            <a href="#" class="interview-questions-reinit" @click.prevent="reinitAnswer(q)">
                                                Réinitialiser
                                            </a>
                                        </template>
                                    </template>
                                    <template v-else>
                                        <div class="mb-2">
                                            <div v-for="o in q.question.options" :key="'option_'+o.id">
                                                <label>
                                                    <input type="radio" @change="answerEdited(q)" v-model="q.selectedOptions" :value="o.value"> {{ o.value }}
                                                </label><br>
                                            </div>
                                        </div>
                                    </template>
                                </template>
                                <template v-else-if="q.question && q.question.type && q.question.type === 'text'">
                                    <v-textarea
                                        solo
                                        name="input-7-4"
                                        label="Saisissez la réponse"
                                        v-model="q.answer"
                                        @keydown="answerEdited(q)"
                                    ></v-textarea>
                                </template>
                                <template v-else-if="q.question && q.question.type && q.question.type === 'slider'">

                                    <template v-if="q.answered">
                                        <v-slider
                                            v-model="q.answer"
                                            class="align-center interview-sliders-slider"
                                            :max="10"
                                            :min="0"
                                            hide-details
                                            thumb-label="always"
                                            @end="answerEdited(q)"
                                            disabled
                                        ></v-slider>
                                         <v-textarea
                                            solo
                                            name="input-7-4"
                                            label="Saisissez la réponse"
                                            v-model="q.comment"
                                            @keydown="answerEdited(q)"
                                        ></v-textarea>
                                        <template v-if="! q.saving">
                                            <a href="#" class="interview-questions-reinit" @click.prevent="reinitAnswer(q)">
                                                Réinitialiser
                                            </a>
                                        </template>
                                    </template>
                                    <template v-else>
                                        <div class="pt-3">
                                            <v-slider
                                                v-model="q.answer"
                                                class="align-center interview-sliders-slider pt-5"
                                                :max="10"
                                                :min="0"
                                                hide-details
                                                thumb-label="always"
                                                @end="answerEdited(q)"
                                            ></v-slider>
                                        </div>
                                    </template>
                                </template>
                                <template v-else-if="q.question && q.question.type && q.question.type === 'number'">
                                    <template v-if="q.answered">
                                        <v-text-field
                                            v-model="q.answer"
                                            type="number"
                                            label="Réponse saisie"
                                            disabled
                                        ></v-text-field>
                                        <v-textarea
                                            solo
                                            name="input-7-4"
                                            label="Saisissez la réponse"
                                            v-model="q.comment"
                                            @keydown="answerEdited(q)"
                                        ></v-textarea>
                                        <template v-if="! q.saving">
                                            <a href="#" class="interview-questions-reinit" @click.prevent="reinitAnswer(q)">
                                                Réinitialiser
                                            </a>
                                        </template>
                                    </template>
                                    <template v-else>
                                        <v-text-field
                                            v-model="q.answer"
                                            type="number"
                                            label="Saisissez la réponse"
                                            @keydown="answerEdited(q)"
                                        ></v-text-field>
                                    </template>
                                </template>
                                <template v-else-if="q.question && q.question.type && q.question.type === 'custom'">
                                    <Question12 :q="q" v-if="q.question.num === 12" @saved="saveAnswer(q)" @reinit="reinitAnswer(q)" @editing="answerEdited(q)" />
                                    <Question13 :q="q" v-if="q.question.num === 13" @saved="saveAnswer(q)" @reinit="reinitAnswer(q)" @editing="answerEdited(q)" />
                                    <Question14 :q="q" v-if="q.question.num === 14" @saved="saveAnswer(q)" @reinit="reinitAnswer(q)" @editing="answerEdited(q)" />
                                    <Question15 :q="q" v-if="q.question.num === 15" @saved="saveAnswer(q)" @reinit="reinitAnswer(q)" @editing="answerEdited(q)" />
                                    <Question16 :q="q" v-if="q.question.num === 16" @saved="saveAnswer(q)" @reinit="reinitAnswer(q)" @editing="answerEdited(q)" />
                                    <Question17 :q="q" v-if="q.question.num === 17" @saved="saveAnswer(q)" @reinit="reinitAnswer(q)" @editing="answerEdited(q)" />
                                    <Question19 :q="q" v-if="q.question.num === 19" @saved="saveAnswer(q)" @reinit="reinitAnswer(q)" @editing="answerEdited(q)" />
                                    <Question20 :q="q" v-if="q.question.num === 20" @saved="saveAnswer(q)" @reinit="reinitAnswer(q)" @editing="answerEdited(q)" />
                                    <Question22 :q="q" v-if="q.question.num === 22" @saved="saveAnswer(q)" @reinit="reinitAnswer(q)" @editing="answerEdited(q)" />
                                    <Question24 :q="q" v-if="q.question.num === 24" @saved="saveAnswer(q)" @reinit="reinitAnswer(q)" @editing="answerEdited(q)" />
                                    <Question33 :q="q" v-if="q.question.num === 33" @saved="saveAnswer(q)" @reinit="reinitAnswer(q)" @editing="answerEdited(q)" />
                                    <Question48 :q="q" v-if="q.question.num === 48" @saved="saveAnswer(q)" @reinit="reinitAnswer(q)" @editing="answerEdited(q)" />
                                    <Question50 :q="q" v-if="q.question.num === 50" @saved="saveAnswer(q)" @reinit="reinitAnswer(q)" @editing="answerEdited(q)" />
                                    <Question55 :q="q" v-if="q.question.num === 55" @saved="saveAnswer(q)" @reinit="reinitAnswer(q)" @editing="answerEdited(q)" />
                                    <Question66 :q="q" v-if="q.question.num === 66" @saved="saveAnswer(q)" @reinit="reinitAnswer(q)" @editing="answerEdited(q)" />
                                    <Question67 :q="q" v-if="q.question.num === 67" @saved="saveAnswer(q)" @reinit="reinitAnswer(q)" @editing="answerEdited(q)" />
                                    <Question75 :q="q" v-if="q.question.num === 75" @saved="saveAnswer(q)" @reinit="reinitAnswer(q)" @editing="answerEdited(q)" />
                                    <Question76 :q="q" v-if="q.question.num === 76" @saved="saveAnswer(q)" @reinit="reinitAnswer(q)" @editing="answerEdited(q)" />
                                    <Question77 :q="q" v-if="q.question.num === 77" @saved="saveAnswer(q)" @reinit="reinitAnswer(q)" @editing="answerEdited(q)" />
                                    <Question83 :q="q" v-if="q.question.num === 83" @saved="saveAnswer(q)" @reinit="reinitAnswer(q)" @editing="answerEdited(q)" />
                                    <Question89 :q="q" v-if="q.question.num === 89" @saved="saveAnswer(q)" @reinit="reinitAnswer(q)" @editing="answerEdited(q)" />
                                    <Question92 :q="q" v-if="q.question.num === 92" @saved="saveAnswer(q)" @reinit="reinitAnswer(q)" @editing="answerEdited(q)" />
                                    <Question93 :q="q" v-if="q.question.num === 93" @saved="saveAnswer(q)" @reinit="reinitAnswer(q)" @editing="answerEdited(q)" />
                                </template>
                                <template v-else-if="q.customquestion && q.customquestion.id">
                                    <v-textarea
                                        solo
                                        name="input-7-4"
                                        label="Saisissez la réponse"
                                        v-model="q.answer"
                                        @keydown="answerEdited(q)"
                                    ></v-textarea>
                                </template>

                                <div class="" v-if="(q.question && q.question.type != 'custom') || (q.customquestion && q.customquestion.id)">
                                    <v-btn :loading="q.saving" :disabled="! q.edited || q.saving" color="primary" @click="saveAnswer(q)" class="mr-4">
                                        Enregistrer
                                    </v-btn>
                                </div>
                            </v-card-text>
                        </v-card>
                        <v-row align="center" v-if="$route.query.edit">
                            <v-col class="text-center">
                                <v-btn :to="{ name: 'interviews.see', params: {id: project.id} }">Retour au résultat</v-btn>
                            </v-col>
                        </v-row>
                        <v-row align="center" v-else-if="! $route.query.validate">
                            <v-col class="text-center">
                                <v-btn :to="{ name: 'interview.complete', params: {id: project.id} }">Terminer l'entretien</v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="3" v-if="$route.query.validate">
                        <div class="interview-questions-validate">
                            <v-btn :to="{ name: 'interview.slider', params: {id: project.id} }">Valider l'entretien</v-btn>
                        </div>
                    </v-col>
                </v-row>
            </div>


            <!--<v-dialog v-model="modalNote" width="750px">
                <v-card class="text-center">
                    <v-card-text class="pt-5">
                        <h2 class="mb-4">
                            Notes personnelles
                        </h2>
                        <v-textarea
                            solo
                            name="input-7-4"
                            label="Saisissez la réponse"
                            v-model="project.note"
                        ></v-textarea>
                        <p>
                            <v-btn color="primary" :loading="noteLoading" :disabled="noteLoading" @click.prevent="saveNote">Enregistrer</v-btn>
                        </p>
                    </v-card-text>
                </v-card>
            </v-dialog>-->

            <div class="selectedQuestions" v-if="modalNote">
                <div class="selectedQuestions-hover" @click="modalNote = false"></div>

                <div class="selectedQuestions-content">
                    <h3 class="selectedQuestions-content-header yellow darken-2 pa-3" @click="modalNote = false">
                        <v-icon color="black">mdi-arrow-bottom-right</v-icon> Notes personnelles
                    </h3>

                    <div class="pa-5">
                        <v-textarea
                            solo
                            name="input-7-4"
                            label="Saisissez vos notes ici"
                            v-model="project.note"
                        ></v-textarea>
                        <p class="text-center">
                            <v-btn color="primary" :loading="noteLoading" :disabled="noteLoading" @click.prevent="saveNote">Enregistrer</v-btn>
                        </p>
                    </div>
                </div>
            </div>
            <div v-else class="selectedQuestionsTag" @click="modalNote = true">
                <div class="yellow darken-2 pa-3">
                    <v-icon color="black">mdi-arrow-top-left</v-icon> Notes personnelles
                </div>
            </div>
        </v-container>
    </div>
</template>

<script>
import Question12 from '@/components/Question12';
import Question13 from '@/components/Question13';
import Question14 from '@/components/Question14';
import Question15 from '@/components/Question15';
import Question16 from '@/components/Question16';
import Question17 from '@/components/Question17';
import Question19 from '@/components/Question19';
import Question20 from '@/components/Question20';
import Question22 from '@/components/Question22';
import Question24 from '@/components/Question24';
import Question33 from '@/components/Question33';
import Question48 from '@/components/Question48';
import Question50 from '@/components/Question50';
import Question55 from '@/components/Question55';
import Question66 from '@/components/Question66';
import Question67 from '@/components/Question67';
import Question75 from '@/components/Question75';
import Question76 from '@/components/Question76';
import Question77 from '@/components/Question77';
import Question83 from '@/components/Question83';
import Question89 from '@/components/Question89';
import Question92 from '@/components/Question92';
import Question93 from '@/components/Question93';
import Loading from '@/components/Loading';

export default {
    data(){
        return {
            consentement: true,
            consentement_check: false,
            loading: false,
            loadingQuestions: false,
            loadingSelectedQuestions: false,
            loadingAdd: false,
            project: false,
            questions: [],
            selectedQuestions: [],
            snackbar: false,
            snackbarText: '',
            snackbarColor: 'sucess',
            categories: {},
            editing_question: '',
            selectedDeleteQuestion: false,
            confirmDelete: false,
            modal_cgu: false,
            modalNote: false,
            noteLoading: false,
        }
    },
    created() {
        this.getProject();
    },
    methods: {
        initCategories() {
            this.categories = {
                "Prendre connaissance": {
                    color: "#01aa05",
                    categs: {
                        "De l'entreprise": {
                            categs: [],
                            open: false
                        },
                        "Du poste": {
                            categs: [],
                            open: false
                        },
                        "Des conditions de travail": {
                            categs: [],
                            open: false
                        },
                        "Des attentes de l'employeur": {
                            categs: [],
                            open: false
                        }
                    }
                },
                "Comprendre": {
                    color: "#00aee4",
                    categs: {
                        "Sa pratique": {
                            categs: [],
                            open: false
                        },
                        "Ses difficultés": {
                            categs: [],
                            open: false
                        }
                    }
                },
                "Identifier": {
                    color: "#c39e05",
                    categs: {
                        "Ses besoins": {
                            categs: [],
                            open: false
                        },
                        "Les acteurs du recrutement": {
                            categs: [],
                            open: false
                        },
                        "Les facteurs d'attractivité": {
                            categs: [],
                            open: false
                        },
                        "Les zones de risque": {
                            categs: [],
                            open: false
                        }
                    }
                }
            };
        },
        getProject() {
            this.loading = true;
            this.$http
                .get(process.env.VUE_APP_API + '/projects/' + this.$route.params.id)
                .then(response => {
                    this.project = response.data.data;
                    this.getSelectedQuestions();
                    this.loading = false;
                }).catch( () => {
                    this.loading = false;
                });
        },
        getSelectedQuestions() {
            this.initCategories();
            this.loadingSelectedQuestions = true;
            this.$http
                .get(process.env.VUE_APP_API + '/projects/' + this.$route.params.id + '/questions')
                .then(response => {
                    this.selectedQuestions = response.data.data;
                    this.loadingSelectedQuestions = false;
                    this.editing_question = '';
                    // build categories
                    this.selectedQuestions.forEach((q) => {
                        if( q.question && q.question.categories && q.question.categories.length > 0 ) {
                            q.question.categories.forEach((c) => {
                                this.categories[c.group]['categs'][c.name]['categs'].push(q);
                            });
                        }
                    });
                }).catch( () => {
                    this.loadingSelectedQuestions = false;
                });
        },
        saveAnswer(question) {
            question.saving = true;
            this.$http
                .put(process.env.VUE_APP_API + '/selectedquestions/' + question.id, {
                    answer: question.answer,
                    comment: question.comment,
                    selectedOptions: question.selectedOptions
                })
                .then( () => {
                    this.getSelectedQuestions(); // reload selected questions
                    this.snackbarText = "Réponse enregistrée";
                    this.snackbarColor = 'success';
                    this.snackbar = true;
                    question.saving = false;
                    question.edited = false;
                    question.answered = true;
                }).catch( () => {
                    question.saving = false;
                });
        },
        removeQuestion(question) {
            question.saving = true;
            this.$http
                .delete(process.env.VUE_APP_API + '/projects/' + this.$route.params.id + '/questions', {
                    data: {
                        selectedquestion_id: question.id
                    }
                })
                .then( () => {
                    this.getSelectedQuestions(); // reload selected questions
                    // display notification success
                    this.snackbarText = "Question retirée";
                    this.snackbarColor = 'success';
                    this.snackbar = true;
                    this.selectedDeleteQuestion = false;
                    this.confirmDelete = false;
                }).catch( () => {
                    question.saving = false;
                });
        },
        displayConfirmDelete(question) {
            this.selectedDeleteQuestion = question;
            this.confirmDelete = true;
        },
        answerEdited(question) {
            if( ! question.edited ) {
                question.edited = true;
            }
            this.editing_question = question.id;
        },
        customSaved(question) {
            this.getSelectedQuestions(); // reload selected questions
            this.snackbarText = "Réponse enregistrée";
            this.snackbarColor = 'success';
            this.snackbar = true;
            question.saving = false;
            question.edited = false;
            question.answered = true;
        },
        reinitAnswer(question) {
            question.saving = true;
            this.$http
                .post(process.env.VUE_APP_API + '/selectedquestions/' + question.id + '/reinit', {
                    question_id: question.id,
                })
                .then( () => {
                    this.getSelectedQuestions(); // reload selected questions
                    this.snackbarText = "Réponse réinitialisée";
                    this.snackbarColor = 'success';
                    this.snackbar = true;
                    question.saving = false;
                    question.edited = false;
                    question.answered = true;
                }).catch( () => {
                    question.saving = false;
                });
        },
        saveNote() {
            this.noteLoading = true;
            this.$http
                .put(process.env.VUE_APP_API + '/projects/' + this.$route.params.id + '/note' , {
                    note: this.project.note
                })
                .then( () => {
                    // stop loading and close modal
                    this.modalNote = false;
                    this.noteLoading = false;

                    this.snackbarText = "Note enregistrée";
                    this.snackbarColor = 'success';
                    this.snackbar = true;
                }).catch( () => {
                    this.noteLoading = false;
                });
        }
    },
    components: {
        Loading,
        Question12,
        Question13,
        Question14,
        Question15,
        Question16,
        Question17,
        Question19,
        Question20,
        Question22,
        Question24,
        Question33,
        Question48,
        Question50,
        Question55,
        Question66,
        Question67,
        Question75,
        Question76,
        Question77,
        Question83,
        Question89,
        Question92,
        Question93,
    }
}
</script>
