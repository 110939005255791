<template>
    <div class="login">
        <v-card class="elevation-4 mt-5 pt-2 pb-4">
            <v-card-text>
                <h2 class="mb-2">Outil d'aide au diagnostic</h2>
                <h3 class="mb-3">
                    Mot de passe oublié
                </h3>
                <p>
                    Saisissez votre adresse mail, vous recevrez un lien pour réinitialiser votre mot de passe.
                </p>
                <v-form @submit.prevent="resetPass" class="pb-3">
                    <div class="" v-if="message">
                        <v-alert type="success" class="mb-3">
                            Si votre adresse mail correspond à un compte, vous recevrez un email pour réinitialiser votre mot de passe.
                        </v-alert>
                    </div>
                    <div v-else class="mb-3">
                        <v-text-field label="Email" name="email" type="email" v-model="email" :readonly="loading" />
                        <v-card-actions class="justify-center">
                            <v-btn type="submit" color="primary" :loading="loading" :disabled="loading">Réinitialiser mon mot de passe</v-btn>
                        </v-card-actions>
                    </div>
                    <div class="text-center">
                        <router-link :to="{ name: 'login' }">
                            <small>Retour à l'écran de connexion</small>
                        </router-link>
                    </div>
                </v-form>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
    export default {
        data(){
            return {
                loading: false,
                email : "",
                message: false,
            }
        },
        methods: {
            resetPass() {
                this.loading = true;
                this.$http
                .post(process.env.VUE_APP_API + '/auth/lost-password', {
                    email: this.email,
                })
                .then( () => {
                    this.message = true;
                    this.loading = false;
                }).catch( () => {
                    this.message = true;
                    this.loading = false;
                });
            }
        }
    }
</script>
