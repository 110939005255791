<template>
    <div>
        <div v-if="q.answered">
            <div class="interview-questions-title">
                <span class="interview-questions-title-number" :style="'background-color:'+q.question.category.color">{{ q.question.num }}</span> <span class="interview-questions-title-question" v-html="q.question.question"></span>
            </div>
            <div>
                <v-textarea
                    solo
                    name="input-7-4"
                    label="Saisissez la réponse"
                    v-model="q.answer"
                    @keydown="answerEdited(q)"
                ></v-textarea>
                <template v-if="! q.saving">
                    <a href="#" class="interview-questions-reinit" @click.prevent="$emit('reinit', q);">
                        Réinitialiser
                    </a>
                </template>
            </div>
        </div>
        <div v-else>
            <div>
                <div class="interview-questions-title">
                    <span class="interview-questions-title-number" :style="'background-color:'+q.question.category.color">{{ q.question.num }}</span>
                </div>
                <div v-for="(reponse, index) in reponses" :key="'rep_'+index" class="pa-3 rounded mb-3 pb-0" style="border:1px solid #ccc">
                    <div class="interview-questions-title">
                        <span class="interview-questions-title-question">
                            Quels sont les types de poste recherché, combien et avec quelle formation ?
                        </span>
                    </div>
                    <v-row align="center">
                        <v-col class="mt-0 pt-0">
                            <v-text-field
                                v-model="reponse.reponse1.nb_postes"
                                type="number"
                                label="Nombre de postes"
                                @change="answerEdited(q)"
                            ></v-text-field>
                        </v-col>
                        <v-col class="mt-0 pt-0">
                            <v-text-field
                                v-model="reponse.reponse1.intitule"
                                type="text"
                                label="Intitulé du poste"
                                @change="answerEdited(q)"
                            ></v-text-field>
                        </v-col>
                        <v-col class="mt-0 pt-0">
                            <v-select
                                :items="niveaux"
                                label="Niveau de formation"
                                v-model="reponse.reponse1.niveau"
                                @change="answerEdited(q)"
                            ></v-select>
                        </v-col>
                        <v-col class="mt-0 pt-0">
                            <v-text-field
                                v-model="reponse.reponse1.domaine"
                                type="text"
                                label="Domaine"
                                @change="answerEdited(q)"
                            ></v-text-field>
                        </v-col>
                    </v-row>



                    <div class="interview-questions-title">
                        <span class="interview-questions-title-question">
                            Quels type de contrat proposez-vous ?<br>
                            Quelle durée ? Quel temps de travail ?
                        </span>
                    </div>
                    <v-row align="center">
                        <v-col class="mt-0 pt-0">
                            <v-select
                                :items="types_contrat"
                                label="Type de contrat"
                                v-model="reponse.reponse2.type"
                                @change="answerEdited(q)"
                            ></v-select>
                        </v-col>
                        <v-col class="mt-0 pt-0">
                            <v-text-field
                                v-model="reponse.reponse2.heures"
                                type="text"
                                label="Nombre d'heures"
                                @change="answerEdited(q)"
                            ></v-text-field>
                        </v-col>
                    </v-row>

                    <div class="interview-questions-title">
                        <span class="interview-questions-title-question">
                            Quels horaires ?
                        </span>
                    </div>
                    <v-row align="center">
                        <v-col class="mt-0 pt-0">
                            <v-select
                                :items="horaires"
                                label="Horaire"
                                v-model="reponse.reponse3.horaire"
                                @change="answerEdited(q)"
                            ></v-select>
                        </v-col>
                        <v-col v-if="reponse.reponse3.horaire === 'autre (par ex. de telle heure à telle heure)'" class="mt-0 pt-0">
                            <v-text-field
                                v-model="reponse.reponse3.autre"
                                type="text"
                                label="Précisez"
                                @change="answerEdited(q)"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <div class="interview-questions-title">
                        <span class="interview-questions-title-question">
                            Existent-ils d'autres critères de choix des candidats décisifs ou rédhibitoires ?
                            <v-tooltip top color="black" :max-width="500">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon small v-bind="attrs" v-on="on" style="vertical-align:baseline">mdi-information</v-icon>
                                </template>
                                <span>
                                    Permis, Caces, ...
                                </span>
                            </v-tooltip>
                        </span>
                    </div>
                    <div>
                        <v-textarea
                            solo
                            name="input-7-4"
                            label="Saisissez la réponse"
                            v-model="reponse.reponse4"
                            @keydown="answerEdited(q)"
                        ></v-textarea>
                    </div>

                    <div class="interview-questions-title">
                        <span class="interview-questions-title-question">
                            Quelles sont les compétences que l'employeur pense indispensables ?
                        </span>
                    </div>
                    <div>
                        <v-textarea
                            solo
                            name="input-7-4"
                            label="Saisissez la réponse"
                            v-model="reponse.reponse5"
                            @keydown="answerEdited(q)"
                        ></v-textarea>
                    </div>


                    <v-col class="mt-0 pt-0">
                        <span @click="removeRow(index)" v-if="index > 0">
                            <v-icon>mdi-delete</v-icon>
                        </span>
                    </v-col>


                </div>

                
                <div class="text-right mb-3">
                    <v-btn @click="addRow" small>
                        Ajouter un poste
                    </v-btn>
                </div>
            </div>
        </div>
        <div>
            <v-btn :loading="q.saving" :disabled="! q.edited || q.saving" color="primary" @click="saveAnswer(q)">Enregistrer</v-btn>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            step: 1,
            reponses: [{
                reponse1: {
                    nb_postes: 0,
                    intitule: '',
                    niveau: '',
                    domaine: '',
                },
                reponse2: {
                    type: '',
                    heures: '',
                },
                reponse3: {
                    horaire: '',
                    autre: '',
                },
                reponse4: '',
                reponse5: '',
            }],
            
            niveaux: [
                "aucune",
                "CAP/BEP",
                "Niveau Bac/Bac pro",
                "BTS ou Bac +2",
                "Bac + 3 et +",
            ],
            
            types_contrat: [
                "CDI",
                "CDD",
                "Interimaire",
                "Saisonnier",
                "Contrat de professionnalisation",
                "Contrat d'apprentissage",
                "Autre",
            ],
            
            horaires: [
                "journée",
                "équipe 2x8",
                "équipe 3x8",
                "équipe 5x8",
                "autre (par ex. de telle heure à telle heure)",
            ],
            
        }
    },
    methods: {
        saveAnswer(question) {
            question.comment = {
                reponses: this.reponses
            };
            this.$emit('saved', question);
            // clean data
            question.comment = '';
            this.reponses = [{
                reponse1: {
                    nb_postes: 0,
                    intitule: '',
                    niveau: '',
                    domaine: '',
                },
                reponse2: {
                    type: '',
                    heures: '',
                },
                reponse3: {
                    horaire: '',
                    autre: '',
                },
                reponse4: '',
                reponse5: '',
            }];
        },
        answerEdited(question) {
            if( ! question.edited ) {
                question.edited = true;
            }
            this.$emit('editing', question);
        },
        displayNext() {
            this.q.edited = true;
        },
        addRow() {
            this.reponses.push({
                reponse1: {
                    nb_postes: 0,
                    intitule: '',
                    niveau: '',
                    domaine: '',
                },
                reponse2: {
                    type: '',
                    heures: '',
                },
                reponse3: {
                    horaire: '',
                    autre: '',
                },
                reponse4: '',
                reponse5: '',
            });
        },
        removeRow(index) {
            if( index > 0 ) {
                this.reponses.splice(index, 1);
            }
        },
        
    },
    props: [
        'q',
    ],
}
</script>