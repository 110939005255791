<template>
    <div class="interview">

        <div v-if="loading" class="pt-4">
            <Loading />
        </div>
        <v-container v-else fluid>
            <div class="topbar">
                <div class="topbar-title">
                    <span class="topbar-title-span">
                        Entretien : {{ project.employer.company }}
                    </span>
                </div>
            </div>
            <div class="interview-questions" v-if="project">
                <v-row align="center" justify="center">
                    <v-col cols="12" lg="8" md="10">
                        <v-card class="mb-5" min-width="100%">
                            <v-stepper value="5">
                                <v-stepper-header>
                                    <v-stepper-step step="1" complete>Entretien<br>enregistré</v-stepper-step>
                                    <v-divider></v-divider>
                                    <v-stepper-step step="2" complete>Positionnement<br>CSP</v-stepper-step>
                                    <v-divider></v-divider>
                                    <v-stepper-step step="3" complete>Positionnement<br>CPE</v-stepper-step>
                                    <v-divider></v-divider>
                                    <v-stepper-step step="4" complete>Pistes d'actions<br>et échéances</v-stepper-step>                                    
                                    <v-divider></v-divider>
                                    <v-stepper-step step="5">Évaluation</v-stepper-step>
                                </v-stepper-header>
                            </v-stepper>
                            <v-card-text class="text-center black--text">
                                <div class="mb-5">
                                    <h3 class="pt-3">
                                        Évaluation à chaud
                                    </h3>
                                </div>
                                <!--<div class="mb-5">
                                    <div class="text-left">
                                        Conseiller entreprise
                                    </div>
                                    <v-radio-group v-model="project.rate_user" row>
                                        <v-radio label="Très satisfait" :value="4"></v-radio>
                                        <v-radio label="Assez satisfait" :value="3"></v-radio>
                                        <v-radio label="Peu satisfait" :value="2"></v-radio>
                                        <v-radio label="Pas satisfait" :value="1"></v-radio>
                                    </v-radio-group>
                                </div>-->
                                <div class="mb-5">
                                    <div class="text-left">
                                        Entreprise
                                    </div>
                                    <v-radio-group v-model="project.rate_employer" row>
                                        <v-radio label="Très satisfait" :value="4"></v-radio>
                                        <v-radio label="Assez satisfait" :value="3"></v-radio>
                                        <v-radio label="Peu satisfait" :value="2"></v-radio>
                                        <v-radio label="Pas satisfait" :value="1"></v-radio>
                                    </v-radio-group>
                                </div>
                                <div class="mb-5">
                                    <div class="text-left">
                                        Commentaire libre (à chaud)
                                    </div>
                                    <v-textarea
                                        solo
                                        label=""
                                        v-model="project.comment"
                                    ></v-textarea>
                                </div>
                                <div class="mb-5">
                                    <h3 class="pt-3">
                                        Durée de l'entretien
                                    </h3>
                                </div>
                                <v-row>
                                    <v-col>
                                        <v-select
                                            :items="available_hours"
                                            label="Heures"
                                            v-model="project.duration_hours"
                                        ></v-select>
                                    </v-col>
                                    <v-col>
                                        <v-select
                                            :items="available_minutes"
                                            label="Minutes"
                                            v-model="project.duration_minutes"
                                        ></v-select>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-text class="text-right">
                                <v-btn text small :to="{ name: 'interview.dates', params: {id: project.id} }">Retour</v-btn> <v-btn small color="primary" @click="saveRates">Terminer l'entretien</v-btn>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </div>
        </v-container>
    </div>
</template>

<script>
import Loading from '@/components/Loading';

export default {
    data(){
        return {
            loading: false,
            project: false,
            available_hours: [0,1,2,3,4],
            available_minutes: [0,10,15,20,25,30,35,40,45,50,55],
        }
    },
    created() {
        this.getProject();
    },
    methods: {
        getProject() {
            this.loading = true;
            this.$http
                .get(process.env.VUE_APP_API + '/projects/' + this.$route.params.id)
                .then(response => {
                    this.project = response.data.data;
                    this.getSelectedQuestions();
                    this.loading = false;
                }).catch( () => {
                    this.loading = false;
                });
        },
        saveRates() {
            this.$http
                .post(process.env.VUE_APP_API + '/projects/' + this.$route.params.id + '/rates', {
                    //rate_user: this.project.rate_user,
                    rate_employer: this.project.rate_employer,
                    comment: this.project.comment,
                    duration_hours: this.project.duration_hours,
                    duration_minutes: this.project.duration_minutes,
                })
                .then( () => {
                    // redirect to next screen
                    this.$router.push({
                        name: 'interview.report',
                        params: {
                            id: this.$route.params.id,
                        }
                    });

                    this.loading = false;
                }).catch( () => {
                    this.loading = false;
                });
        }
    },
    components: {
        Loading
    }
}
</script>