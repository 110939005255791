<template>
    <div>
        <v-dialog v-model="display_chart" width="850px">
            <v-card>
                <v-card-text>
                    <div class="mb-5 pt-5">
                        <apexchart height="" type="radar" :options="chart1Options" :series="chart1data"></apexchart>
                    </div>
                    <div v-if="chartCPE_html" class="mb-5">
                        <div v-html="chartCPE_html"></div>
                    </div>
                    <div class="text-center">
                        <v-btn class="mb-1" color="darken-1" text @click="display_chart = false">Fermer</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="modal_compare" width="850px">
            <v-card>
                <v-card-text>
                    <div class="pt-5 mb-4">
                        <h3>
                            Vous avez choisi de comparer plusieurs auto-diagnostics :
                        </h3>
                    </div>
                    <div class="mb-5">
                        <ul>
                            <li v-for="c in compare" :key="'compare_'+c.id" class="">
                                {{ c.company }}
                            </li>
                        </ul>
                    </div>
                    <div class="pb-5">
                        <v-btn :loading="loadingCompare" :disabled="loadingCompare" color="primary" class="mr-2" small @click.prevent="compareCsv">Comparer les résultats (excel)</v-btn>
                        <v-btn :loading="loadingCSP" :disabled="loadingCSP" color="green" outlined small @click.prevent="compareCharts">Comparer les graphiques</v-btn>
                    </div>
                    <div class="text-right">
                        <v-btn class="mb-1" color="darken-1" text @click="modal_compare = false">Annuler</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="modal_group" width="850px">
            <v-card>
                <v-card-text>
                    <div class="pt-5 mb-4">
                        <h3>
                            Vous avez choisi de grouper plusieurs auto-diagnostics en un entretien, quel est celui que vous voulez préparer ?
                            <v-tooltip top color="black" :max-width="500">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on" class="ml-1" color="black">mdi-information</v-icon>
                                </template>
                                <span>
                                    L’ensemble des auto-diagnostics réalisés pour cette entreprise seront conservés.<br>
                                    Il n’est possible de grouper que 4 auto-diags maximum.<br>
                                    Si dans les auto-diags sélectionnés, l’un d’eux à un entretien en préparation, c’est cet entretien qui prendra le pas sur les autres.
                                </span>
                            </v-tooltip>
                        </h3>
                    </div>
                    <div v-if="selection_valid">
                        <div class="mb-5">
                            <ul>
                                <li v-for="c in compare" :key="'compare_'+c.id" class="">
                                    {{ c.company }}
                                </li>
                            </ul>
                        </div>
                        <div>
                            <v-select
                                :items="valid_grouping"
                                item-text="company"
                                item-value="id"
                                label="Choisissez un des auto-diagnostics"
                                v-model="employer_selected"
                                return-object
                            ></v-select>
                        </div>
                        <div class="pb-5">
                            <v-btn :loading="loadingGrouping" :disabled="loadingGrouping" color="primary" class="mr-2" small @click.prevent="groupEmployers">Valider</v-btn>
                        </div>
                    </div>
                    <div v-else class="mb-5">
                        <v-alert
                            border="left"
                            color="orange"
                            dark
                        >
                            Vous avez sélectionné plusieurs entretiens déjà en cours de création. Vous ne pouvez pas les grouper ensemble.
                        </v-alert>  
                    </div>
                    <div class="text-right">
                        <v-btn class="mb-1" color="darken-1" text @click="modal_group = false">Annuler</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

        <div class="pl-3">
            <v-row class="align-items-center">
                <v-col style="max-width:250px">
                    <v-select
                        :items="actions"
                        label="Action groupée"
                        outlined
                        v-model="action"
                        dense
                        clearable
                    ></v-select>
                </v-col>
                <v-col>
                    <v-btn @click.prevent="doAction" :disabled="!action || action.length <= 0 || !compare || compare.length <= 0">
                        Appliquer
                    </v-btn>
                </v-col>
            </v-row>
        </div>
        
        
        <!-- <template v-if="compare && compare.length > 0">
            <div class="selectedQuestions" v-if="displayComparePanel">
                <div class="selectedQuestions-hover" @click="displayComparePanel = false"></div>

                <div class="selectedQuestions-content">
                    <h3 class="selectedQuestions-content-header yellow darken-2 pa-3" @click="displayComparePanel = false">
                        <v-icon color="black">mdi-arrow-bottom-right</v-icon> Comparer {{ compare.length }} auto-diag<template v-if="compare.length > 1">s</template>
                    </h3>

                    <div class="pa-5">
                        <div class="mb-2">
                            <ul>
                                <li v-for="c in compare" :key="'compare_'+c.id" class="">
                                    {{ c.company }}
                                </li>
                            </ul>
                        </div>
                        <div class="pb-5">
                            <v-btn :loading="loadingCompare" :disabled="loadingCompare" color="primary" class="mr-2" small @click.prevent="compareCsv">Comparer les résultats (excel)</v-btn>
                            <v-btn :loading="loadingCSP" :disabled="loadingCSP" color="green" outlined small @click.prevent="compareCharts">Comparer graphiques</v-btn>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="selectedQuestionsTag" @click="displayComparePanel = true">
                <div class="yellow darken-2 pa-3">
                    <v-icon color="black">mdi-arrow-top-left</v-icon> Comparer {{ compare.length }} auto-diag<template v-if="compare.length > 1">s</template>
                </div>
            </div>
        </template> -->
    </div>
</template>

<script>
import downloadCSV from '@/functions/downloadCSV.js';

export default {
    data() {
        return {
            action: '',
            loadingCompare: false,
            loadingCSP: false,
            loadingCPE: false,
            displayComparePanel: false,
            display_chart: false,
            chartCPE_html: false,
            chart1data: [],
            chart1Options: {
                legend: {
                    position: 'top'
                },
                chart: {
                    type: 'radar',
                    toolbar: {
                        show: false
                    }
                },
                xaxis: {
                    categories: []
                },
                yaxis: {
                    min: 0,
                    max: 10,
                },
                grid: {
                    show: false,
                    xaxis: {
                        lines: {
                            show: false
                        }
                    },   
                    yaxis: {
                        lines: {
                            show: false
                        }
                    },  
                    row: {
                        colors: undefined,
                        opacity: 0
                    },  
                    column: {
                        colors: undefined,
                        opacity: 0
                    },
                    padding: {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0
                    }, 
                }
            },
            modal_compare: false,
            modal_group: false,
            loadingGrouping: false,
            employer_selected: false,
            valid_grouping: [],
            selection_valid: true,
        }
    },
    props: [
        'compare',
        'actions'
    ],
    methods: {
        doAction() {
            if( this.action === 'Comparer' ) {
                this.modal_compare = true;
            } else if( this.action === 'Grouper' ) {
                this.modal_group = true;
                this.controlGrouping();
            }
        },
        compareCsv() {
            this.loadingCompare = true;
            // build array to send
            var array_ids = [];
            this.compare.forEach((item) => {
                array_ids.push(item.id);
            });
            // post to api
            this.$http
                .post(process.env.VUE_APP_API + '/projects/compare/csv', {
                    compare: array_ids
                }, { responseType: 'arraybuffer'} )
                .then(response => {
                    // download csv
                    downloadCSV(response.data, 'tableau');
                    this.loadingCompare = false;
                }).catch( () => {
                    this.loadingCompare = false;
                });
        },
        compareCharts() {
            this.modal_compare = false;
            this.compareChartCSP();
            this.compareChartCPE();
        },
        compareChartCSP() {
            this.loadingCSP = true;
            this.display_chart = false;
            this.chart1data = [];
            // build array to send
            var array_ids = [];
            this.compare.forEach((item) => {
                array_ids.push(item.id);
            });
            this.$http
                .post(process.env.VUE_APP_API + '/projects/compare/charts/csp', {
                    compare: array_ids
                })
                .then(response => {
                    if( response.data.charts[0] && response.data.charts[0].length > 0 ) {
                        this.chart1data.push({
                            name: response.data.employers_name[0],
                            data: response.data.charts[0]
                        });
                    }
                    if( response.data.charts[1] && response.data.charts[1].length > 0 ) {
                        this.chart1data.push({
                            name: response.data.employers_name[1],
                            data: response.data.charts[1]
                        });
                    }
                    if( response.data.charts[2] && response.data.charts[2].length > 0 ) {
                        this.chart1data.push({
                            name: response.data.employers_name[2],
                            data: response.data.charts[2]
                        });
                    }
                    if( response.data.charts[3] && response.data.charts[3].length > 0 ) {
                        this.chart1data.push({
                            name: response.data.employers_name[3],
                            data: response.data.charts[3]
                        });
                    }

                    this.chart1Options.xaxis.categories = response.data.chart_labels;
                    this.display_chart = true;
                    this.loadingCSP = false;
                }).catch( () => {
                    this.loadingCSP = false;
                });
        },
        compareChartCPE() {
            this.loadingCPE = true;
            // build array to send
            var array_ids = [];
            this.compare.forEach((item) => {
                array_ids.push(item.id);
            });
            this.$http
                .post(process.env.VUE_APP_API + '/projects/compare/charts/cpe', {
                    compare: array_ids
                })
                .then(response => {
                    this.chartCPE_html = response.data.chart;
                    this.loadingCPE = false;
                }).catch( () => {
                    this.loadingCPE = false;
                });
        },
        controlGrouping() {
            this.valid_grouping = [];
            // control their is no 2 parents in selection
            let nb_parent = 0;
            this.compare.forEach((item) => {
                if( item.project && item.project.id && item.project.id > 0 ) {
                    nb_parent = nb_parent +1;
                }
            });

            // if multiple parent selcted
            if( nb_parent > 1 ) {
                // cannot group auto-diags
                this.selection_valid = false;
            } else if( nb_parent > 0 ) { // else if 1 prent
                // the parent only can be the parent
                this.selection_valid = true;
                this.compare.forEach((item) => {
                    if( item.project && item.project.id && item.project.id > 0 ) {
                        this.valid_grouping.push(item);
                    }
                });
            } else {
                // else, all auto-diag can be selected
                this.selection_valid = true;
                this.compare.forEach((item) => {
                    this.valid_grouping.push(item);
                });
            }
        },
        groupEmployers() {
            this.loadingGrouping = true;
            // build array to send
            var array_ids = [];
            this.compare.forEach((item) => {
                array_ids.push(item.id);
            });
            this.$http
                .post(process.env.VUE_APP_API + '/projects/grouping', {
                    employers: array_ids,
                    parent: this.employer_selected.id
                })
                .then(response => {
                    // emit employers goruped
                    this.$emit('employersGrouped', response.data.project.id);
                    this.loadingGrouping = false;
                }).catch( () => {
                    this.loadingGrouping = false;
                });
        }
    }
}
</script>