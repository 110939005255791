<template>
    <v-container
        fluid
    >

        <v-snackbar
            v-model="snackbar"
            :color="snackbarColor"
        >
            {{ snackbarText }}

            <template v-slot:action="{ attrs }">
                <v-btn
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    Fermer
                </v-btn>
            </template>
        </v-snackbar>

        <v-dialog v-model="display_delete" width="850px">
            <v-card class="text-center">
                <v-card-text class="pt-5">
                    <h2>Supprimer l'auto-diag</h2>
                    <div class="mb-5 pt-5">
                        Êtes-vous sûr de vouloir supprimer l'auto-diagnostic de la société "{{ selectedEmployer.company }}" ?
                    </div>
                    <div>
                        <v-btn class="mb-1 mr-2" color="red" dark @click="deleteEmployer" :loading="loadingDelete" :disabled="loadingDelete">Supprimer ce lien</v-btn>
                        <v-btn class="mb-1" color="darken-1" text @click="display_delete = false">Annuler</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>


        <v-card>
            <v-card-text>
                <h3 class="text--primary mb-4">
                    Auto-Diagnostic entreprises
                </h3>
                <v-btn density="compact" small v-if="user.role === 'admin'" :outlined="filter !== 'all'" color="primary" class="mr-2 mb-2" @click.prevent="getProjects('all', 1); changeFilter('all')">Toutes les agences</v-btn>
                <template v-if="userAgencies !== null">
                  <template v-for="(agency, index) in this.agencies">
                    <v-btn :outlined="filter !== agency.id" color="primary" small class="mr-2 mb-2" :key="index" v-if="JSON.parse(userAgencies).includes(agency.id)" @click.prevent="getProjects(agency.id, 1); changeFilter(agency.id)">{{ agency.name }}</v-btn>
                  </template>
                </template>
            </v-card-text>
            <v-data-table
                :headers="headers"
                :items="projects"
                :options.sync="options"
                :server-items-length="totalProjects"
                :loading="loading"
                :no-data-text="'Aucun lien envoyé en attente'"
                class="elevation-1"
                fluid
            >
                <template v-slot:item.agent="{ item }">{{ item.user.firstname }} {{ item.user.lastname }}</template>
                <template v-slot:item.created="{ item }">
                    {{ buildDate(item.created_at) }}
                </template>
                <template v-slot:item.link="{ item }">
                    {{ buildLink(item.token) }}
                </template>
                <template v-slot:item.action="{ item }">
                    <v-btn small color="black" dark class="mb-1 mr-2" @click.prevent="autodiag_token = item.token; reSendLinkDialog = true">Renvoyer le lien</v-btn>
                    <v-btn small color="red" dark class="mb-1" @click="confirmDelete(item)">Supprimer</v-btn>
                </template>
            </v-data-table>
        </v-card>

        <v-dialog
            v-model="reSendLinkDialog"
            persistent
            width="30%"
        >
          <v-card>
            <v-card-title>
              <span class="text-h5">Email du destinataire du lien d'autodiag</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        v-model="autodiag_email"
                        label="Email*"
                        required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <span v-if="mailSent" class="green py-2 px-2 white--text mr-2">Le lien a été envoyé</span>
              <v-btn
                  variant="text"
                  @click="reSendLinkDialog = false"
              >
                Fermer
              </v-btn>
              <v-btn
                  class="green white--text"
                  variant="text"
                  @click="sendAutoDiag(autodiag_token, autodiag_email)"
              >
                Envoyer
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

    </v-container>
</template>

<script>
import moment from 'moment';

export default {
    data() {
        return {
            loading: false,
            mailSent: false,
            reSendLinkDialog: false,
            autodiag_email: '',
            autodiag_token: '',
            user: this.$store.getters.user,
            userAgencies: this.$store.getters.user.agencies_ids,
            agencies: [],
            filter: 'all',
            projects: [],
            totalProjects: 0,
            options: {
                page: 1,
                itemsPerPage: 10
            },
            headers: [
                { text: 'Entreprise', value: 'company', sortable: false, },
                { text: 'Numéro', value: 'num', sortable: false, },
                { text: 'Date de la génération du lien', value: 'created', sortable: false, },
                { text: 'Statut', value: 'status', sortable: false, },
                { text: 'Lien', value: 'link', sortable: false, },
                { text: 'Créé par', value: 'agent', sortable: false, },
                { text: 'Agence', value: 'user.agency.name', sortable: false, },
                { text: 'Action', value: 'action', sortable: false, },
            ],
            loadingProject: false,
            selectedEmployer: false,
            loadingDelete: false,
            display_delete: false,
            snackbar: false,
            snackbarColor: 'success',
            snackbarText: '',
        }
    },
    created() {
      this.getAgencies();
      this.getProjects(this.user.agency_id);
    },
    methods: {
        changeFilter(value) {
            if ( value !== this.filter ) {
                this.filter = value;
            }
        },
        getAgencies() {
          this.loading = true;
          let url = process.env.VUE_APP_API + '/agencies';

          this.$http
              .get(url)
              .then(response => {
                this.agencies = response.data.agencies;
                this.loading = false;
              }).catch( () => {
            this.loading = false;
          });
        },

        getProjects(agencyFilter = null, page = null) {

            let agencyParameter = '';
            if (agencyFilter !== null || agencyFilter !== 'all') {
              agencyParameter = '&agency=' + agencyFilter;
              this.filter = agencyFilter;
            }

            let selectedPage = '';
            if (page !== null) {
              selectedPage = page;
            }
            else {
              selectedPage = this.options.page;
            }

            this.loading = true;
            this.$http
                .get(process.env.VUE_APP_API + '/employers-autodiag?page=' + selectedPage + '&itemsPerPage=' + this.options.itemsPerPage + agencyParameter)
                .then(response => {
                    this.projects = response.data.data;
                    this.totalProjects = response.data.meta.total;
                    this.options.page = response.data.meta.current_page;
                    this.options.itemsPerPage = response.data.meta.per_page;
                    this.loading = false;
                }).catch( () => {
                    this.loading = false;
                });
        },
        buildLink(token) {
            return process.env.VUE_APP_AUTODIAG_URL + '/' + token;
        },
        buildDate(fulldate) {
            return moment(String(fulldate)).format('DD/MM/YYYY');
        },
        buildBtn(token) {
            return '<a class="v-btn v-btn--contained theme--dark v-size--small black v-btn--flat" href="mailto:?subject=Faites votre diagnostic recrutement&body=Bonjour, faites dès maintenant votre diagnostic recrutement à l\'adresse suivante : '+ process.env.VUE_APP_AUTODIAG_URL + '/' + token +'" target="_blank">Renvoyer le lien</a>';
        },
        confirmDelete(item) {
            this.selectedEmployer = item;
            this.display_delete = true;
        },
        deleteEmployer() {
            if( this.selectedEmployer ) {
                this.loadingDelete = true;
                this.$http
                    .delete(process.env.VUE_APP_API + '/employers/' + this.selectedEmployer.id, {})
                    .then( () => {
                        this.getProjects(); // reload employers
                        this.display_delete = false;
                        this.selectedEmployer = false;
                        this.loadingDelete = false;
                        this.snackbarText = "Lien auto-diag supprimé";
                        this.snackbarColor = 'success';
                        this.snackbar = true;
                    }).catch( () => {
                        this.loadingDelete = false;
                    });
            }
        },

        sendAutoDiag(token, email) {
          this.$http
              .post(process.env.VUE_APP_API + '/autodiag/send', {
                email: email,
                link: process.env.VUE_APP_AUTODIAG_URL + '/' + token
              })
              .then((response) => {
                if (response.data.success === true) {
                  this.mailSent = true;

                  setTimeout(() => {
                    this.mailSent = false;
                    this.reSendLinkDialog = false;
                    this.autodiag_email = '';
                    this.autodiag_token = '';
                  }, 3000);
                }
              })
              .catch( () => {
                this.loading = false;
              });
        },

        closeReSendAutodiag() {
          this.reSendLinkDialog = false;
          this.autodiag_email = '';
          this.autodiag_token = '';
        }
    },
    watch: {
        options: {
            handler () {
                this.getProjects(this.filter, this.options.page);
            },
            deep: true,
        },
    },
    components: {
    }
}
</script>
