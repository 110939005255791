import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

import fr from 'vuetify/es5/locale/fr';

export default new Vuetify({
    lang: {
        locales: { fr },
        current: 'fr',
    },
    theme: {
        themes: {
            light: {
                primary: '#2E5BFF',
                //secondary: '#b0bec5',
                //accent: '#8c9eff',
                //error: '#b71c1c',
                warning: '#F57C00'
            },
        },
    },
});
