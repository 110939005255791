<template>
    <v-container
        class="pb-5"
        fluid
    >
        <v-card class="mb-5">
            <v-card-text>
                <h3 class="text--primary">
                    Mes entretiens terminés
                </h3>
            </v-card-text>
            <v-data-table
                :headers="headers"
                :items="projects"
                :options.sync="options"
                :server-items-length="totalProjects"
                :loading="loading"
                :no-data-text="'Aucun entretien terminé pour le moment'"
                fluid
            >
                <!--<template v-slot:item.compare="{ item }">
                    <div class="text-center">
                        <input type="checkbox" v-model="compare" :value="item" :disabled="compare.length >= 4 && compare.indexOf(item) < 0" />
                    </div>
                </template>-->
                <template v-slot:item.company="{ item }">
                    <template v-if="item.project && item.project.employers && item.project.employers.length > 0">
                        <v-tooltip top color="black" :max-width="500">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon color="black" v-bind="attrs" v-on="on">mdi-link</v-icon>
                            </template>
                            <span>
                                <span v-for="(employer, index) in item.project.employers" :key="'e_'+employer.id">
                                    <template v-if="index > 0">/ </template>{{ employer.company }}
                                </span>
                            </span>
                        </v-tooltip>
                    </template>
                    {{ item.company }}
                </template>
                <template v-slot:item.date_appointment_clean="{ item }">
                    <template v-if="item.date_appointment && item.date_appointment.length > 0">
                        {{ cleanDate(item.date_appointment) }}
                    </template>
                </template>
                <template v-slot:item.actions="{ item }">
                    <template v-if="item.project && item.project.id">
                        <v-btn :to="{ name: 'interviews.see', params: {id: item.project.id}}" color="green" small outlined dark class="mt-1 mb-1 mr-2">Consulter l'entretien</v-btn>
                        <v-btn :to="{ name: 'interviews.editreport', params: {id: item.project.id}}" color="warning" small outlined dark class="mt-1 mb-1 mr-2">Modifier le rapport</v-btn>

                        <template v-if="loadingPdf && loadingPdf.indexOf(item.project.id) != -1">
                            <v-btn class="mr-2 mt-1 mb-1" small disabled>Génération en cours...</v-btn>
                        </template>
                        <template v-else>
                            <v-btn small class="mr-2 mt-1 mb-1" text @click="downloadReport(item.project.id)"><v-icon>mdi-download</v-icon> Générer le rapport</v-btn>
                        </template>
                    </template>
                </template>


            </v-data-table>
        </v-card>

        <!-- <div class="pt-5">
            <CompareAutodiags :compare="compare" />
        </div> -->
        
    </v-container>
</template>

<script>
import moment from 'moment';
import downloadPDF from '@/functions/downloadPDF.js';
//import CompareAutodiags from '@/components/CompareAutodiags';

export default {
    data() {
        return {
            loading: false,
            loadingPdf: [],
            projects: [],
            totalProjects: 0,
            options: {
                page: 1,
                itemsPerPage: 10
            },
            headers: [
                //{ text: 'Comparer', value: 'compare', sortable: false },
                { text: 'Entreprise', value: 'company', sortable: false, },
                { text: 'Numéro', value: 'num', sortable: false, },
                //{ text: 'Contact', value: 'email', sortable: false, },
                { text: 'Entretien passé le', value: 'date_appointment_clean', sortable: false, },
                { text: 'Actions', value: 'actions', sortable: false, }
            ],
            loadingProject: false,
            compare: []
        }
    },
    created() {
        //this.getProjects();
    },
    methods: {
        getProjects() {
            this.loading = true;
            this.$http
                .get(process.env.VUE_APP_API + '/projects/complete?page=' + this.options.page + '&itemsPerPage=' + this.options.itemsPerPage)
                .then(response => {
                    this.projects = response.data.data;
                    this.totalProjects = response.data.meta.total;
                    this.options.page = response.data.meta.current_page;
                    this.options.itemsPerPage = response.data.meta.per_page;
                    this.loading = false;
                }).catch( () => {
                    this.loading = false;
                });
        },
        cleanDate(date) {
            return moment(String(date)).format('DD/MM/YYYY HH:mm');
        },
        downloadReport(project_id) {
            this.loadingPdf.push(project_id);
            this.$http
                .post(process.env.VUE_APP_API + '/projects/' + project_id + '/report', {}, { responseType: 'arraybuffer'} )
                .then(response => {
                    downloadPDF(response.data, 'rapport');
                    let index = this.loadingPdf.indexOf(project_id);
                    if (index !== -1) this.loadingPdf.splice(index, 1);
                }).catch( () => {
                    let index = this.loadingPdf.indexOf(project_id);
                    if (index !== -1) this.loadingPdf.splice(index, 1);
                });
        }
    },
    watch: {
        options: {
            handler () {
                this.getProjects();
            },
            deep: true,
        },
    },
    components: {
        //CompareAutodiags
    }
}
</script>
