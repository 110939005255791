<template>
    <div class="interview">

        <div v-if="loading" class="pt-4">
            <Loading />
        </div>
        <v-container v-else fluid>
            <div class="topbar">
                <div class="topbar-title">
                    <span class="topbar-title-span">
                        Entretien : {{ project.employer.company }}
                    </span>
                </div>
            </div>
            <div class="interview-review">
                <v-row align="center" justify="center">
                    <v-col cols="12" lg="8" md="10">
                        <v-card class="mb-5" min-width="100%">
                            <v-stepper value="2">
                                <v-stepper-header>
                                    <v-stepper-step step="1" complete>Entretien<br>enregistré</v-stepper-step>
                                    <v-divider></v-divider>
                                    <v-stepper-step step="2">Positionnement<br>CSP</v-stepper-step>
                                    <v-divider></v-divider>
                                    <v-stepper-step step="3">Positionnement<br>CPE</v-stepper-step>
                                    <v-divider></v-divider>
                                    <v-stepper-step step="4">Pistes d'actions<br>et échéances</v-stepper-step>
                                    <v-divider></v-divider>
                                    <v-stepper-step step="5">Évaluation</v-stepper-step>
                                </v-stepper-header>
                            </v-stepper>
                            <v-card-text class="text-center" v-if="scores">
                                <div>
                                    <h3 class="pt-3">
                                        <!-- Bilan et pistes d'actions possibles -->
                                        Où se situe l’employeur pour les axes Capter, Sélectionner, Pérenniser<br>(plus la note est élevée plus l'employeur est autonome sur cet axe)
                                    </h3>
                                </div>
                                <div ref="chart1" id="chart1" style="margin-top:-50px">
                                    <apexchart height="" type="radar" :options="chart1Options" :series="chart1data"></apexchart>
                                </div>
                                <!--<div class="text-left">
                                    <h3 class="mb-3 mt-5 pt-4">Pistes d’actions Pôle Emploi & Pistes d’actions Entreprise :</h3>
                                    <v-textarea
                                        solo
                                        label=""
                                        v-model="project.bilan"
                                    ></v-textarea>
                                </div>-->
                            </v-card-text>
                            <v-card-text class="text-right">
                                <!--<v-btn text small :to="{ name: 'interview.slider', params: {id: project.id} }">Retour</v-btn> <v-btn text small color="primary" @click="saveReview">Suivant</v-btn>-->
                                <v-btn text small :to="{ name: 'interview.slider', params: {id: project.id} }">Retour</v-btn> <v-btn text small color="primary" :to="{ name: 'interview.slider2', params: {id: project.id} }">Suivant</v-btn>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </div>
        </v-container>
    </div>
</template>

<script>
import Loading from '@/components/Loading';
export default {
    data(){
        return {
            loading: false,
            loadingQuestions: false,
            project: false,
            scores: false,
            chart1data: [
                {
                    //name: 'Auto-diagnostic',
                    data: [],
                },
                /*{
                    name: 'Entretien',
                    data: [],
                },*/
            ],
            chart1Options: {
                chart: {
                    type: 'radar',
                    toolbar: {
                        show: false
                    }
                },
                xaxis: {
                    categories: []
                },
                yaxis: {
                    min: 0,
                    max: 10,
                },
                grid: {
                    show: false,
                    xaxis: {
                        lines: {
                            show: false
                        }
                    },
                    yaxis: {
                        lines: {
                            show: false
                        }
                    },  
                    row: {
                        colors: undefined,
                        opacity: 0
                    },  
                    column: {
                        colors: undefined,
                        opacity: 0
                    },
                    padding: {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0
                    }, 
                }
            },
        }
    },
    created() {
        this.getProject();
    },
    methods: {
        getProject() {
            this.loading = true;
            this.$http
                .get(process.env.VUE_APP_API + '/projects/' + this.$route.params.id)
                .then(response => {
                    this.project = response.data.data;
                    this.getEmployerScore();
                    this.loading = false;
                }).catch( () => {
                    this.loading = false;
                });
        },
        getEmployerScore() {
            this.$http
                .get(process.env.VUE_APP_API + '/projects/' + this.$route.params.id + '/cspcomparison')
                .then(response => {
                    //this.chart1data[0].data = response.data.chart_1_points;
                    //this.chart1data[1].data = response.data.chart_1_points_pe;
                    this.chart1data[0].data = response.data.chart_1_points_pe;
                    this.chart1Options.xaxis.categories = response.data.chart_1_labels;
                    this.scores = response.data;
                    this.loading = false;
                }).catch( () => {
                    this.loading = false;
                });
        },
        /*saveReview() {
            this.$http
                .post(process.env.VUE_APP_API + '/projects/' + this.$route.params.id + '/review', {
                    bilan: this.project.bilan
                })
                .then( () => {
                    // redirect to next screen
                    this.$router.push({
                        name: 'interview.slider2',
                        params: {
                            id: this.$route.params.id,
                        }
                    });

                    this.loading = false;
                }).catch( () => {
                    this.loading = false;
                });
        }*/
    },
    components: {
        Loading
    }
}
</script>