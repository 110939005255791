<template>
    <div>
        <div v-if="q.answered">
            <div class="interview-questions-title">
                <span class="interview-questions-title-number" :style="'background-color:'+q.question.category.color">{{ q.question.num }}</span> <span class="interview-questions-title-question" v-html="q.question.question"></span>
            </div>
            <div>
                <v-textarea
                    solo
                    name="input-7-4"
                    label="Saisissez la réponse"
                    v-model="q.answer"
                    @keydown="answerEdited(q)"
                ></v-textarea>
                <template v-if="! q.saving">
                    <a href="#" class="interview-questions-reinit" @click.prevent="$emit('reinit', q);">
                        Réinitialiser
                    </a>
                </template>
            </div>
        </div>
        <div v-else>
            <div class="mb-4">
                <div class="interview-questions-title">
                    <span class="interview-questions-title-number" :style="'background-color:'+q.question.category.color">{{ q.question.num }}</span> <span class="interview-questions-title-question">
                        1) D'après le recruteur, ses difficultés sont elles dues au fait que les candidats qui se positionnent :
                    </span>
                </div>
                <div>
                    <label>
                        <input type="checkbox" @change="answerEdited(q)" v-model="step1" :value="'ne sont pas intéressés dès l’entretien'"> ne sont pas intéressés dès l’entretien
                    </label><br>
                    <label>
                        <input type="checkbox" @change="answerEdited(q)" v-model="step1" :value="'à terme ne restent pas dans l’entreprise'"> à terme ne restent pas dans l’entreprise
                    </label>
                </div>
            </div>
            <div>
                <div class="interview-questions-title">
                    <span class="interview-questions-title-question">
                        2) D’après lui est-ce lié :
                    </span>
                </div>
                <div>
                    <label>
                        <input type="checkbox" @change="answerEdited(q)" v-model="step2" value="aux conditions de salaires ou autre manque d’avantages"> aux conditions de salaires ou autre manque d’avantages
                    </label><br>
                    <label>
                        <input type="checkbox" @change="answerEdited(q)" v-model="step2" value="aux conditions physiques et d’environnement de travail"> aux conditions physiques et d’environnement de travail
                    </label><br>
                    <label>
                        <input type="checkbox" @change="answerEdited(q)" v-model="step2" value="aux horaires et aux déplacements"> aux horaires et aux déplacements
                    </label><br>
                    <label>
                        <input type="checkbox" @change="answerEdited(q)" v-model="is_comment"> à autre chose ?
                        <v-tooltip top color="black" :max-width="500">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon small v-bind="attrs" v-on="on" style="vertical-align:baseline">mdi-information</v-icon>
                            </template>
                            <span>
                                La phrase doit commencer par "Cette situation est en partie due à..."
                            </span>
                        </v-tooltip>
                    </label>
                    <div class="mb-2"></div>
                    <v-textarea
                    v-if="is_comment"
                        solo
                        name="input-7-4"
                        label="Précisez si autre"
                        v-model="comment"
                    ></v-textarea>
                </div>
            </div>
        </div>
        <div>
            <v-btn :loading="q.saving" :disabled="! q.edited || q.saving" color="primary" @click="saveAnswer(q)">Enregistrer</v-btn>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            step1: [],
            step2: [],
            is_comment: false,
            comment: '',
        }
    },
    methods: {
        saveAnswer(question) {
            question.comment = {
                step1: this.step1,
                step2: this.step2,
                comment: this.comment
            };
            this.$emit('saved', question);
            // clean data
            question.comment = '';
            this.step1 = [];
            this.step2 = [];
            this.is_comment = false;
            this.comment = '';
        },
        answerEdited(question) {
            if( ! question.edited ) {
                question.edited = true;
            }
            this.$emit('editing', question);
        },
        displayNext() {
            if( this.oui_non == 'oui' ) {
                this.step = 2;
            } else {
                this.step = 3;
            }
            this.q.edited = true;
        }
    },
    props: [
        'q',
    ],
}
</script>