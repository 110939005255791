<template>
    <v-container
        fluid
    >
        <v-snackbar
            v-model="snackbar"
            :color="snackbarColor"
        >
            {{ snackbarText }}

            <template v-slot:action="{ attrs }">
                <v-btn
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    Fermer
                </v-btn>
            </template>
        </v-snackbar>

        <v-card class="mb-3">
            <v-card-text>
                <h3 class="text--primary">
                    Vos questions personnalisées
                </h3>
            </v-card-text>
            <Loading v-if="loading" />
            <v-simple-table v-else-if="customquestions && customquestions.length > 0">
                <template v-slot:default>
                    <thead>
                    <tr>
                        <th class="text-left">Question</th>
                        <th class="text-left">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="q in customquestions" :key="'customquestion_'+q.id">
                        <td>{{ q.question }}</td>
                        <td>
                            <v-btn small color="primary" outlined dark @click="editCustomquestion(q)" class="mr-2">
                                Éditer
                            </v-btn>
                            <v-btn small color="orange" outlined dark @click="confirmDelete(q)">
                                Supprimer
                            </v-btn>
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>
            <v-card-text v-else>
                <em>Vous n'avez pas encore créé de questions personnalisées.</em>
            </v-card-text>
        </v-card>
        <AddCustomQuestion @reloadQuestions="getCustomQuestions" />

        <v-dialog v-model="dialog" width="600px">
            <v-card :loading="loading">
                <v-card-title>
                    <h3>Éditer la question</h3>
                </v-card-title>
                <v-card-text>
                    <v-form ref="form" @submit.prevent="saveQuestion">
                        <v-text-field
                            v-model="selected_question.question"
                            :rules="fieldRules"
                            label="Rédigez votre question"
                            required
                        ></v-text-field>
                        <div class="text-center">
                            <v-btn
                                color="primary"
                                class="mr-4"
                                @click="saveQuestion"
                            >
                                Enregistrer
                            </v-btn>
                            <v-btn color="darken-1" text @click="dialog = false">Annuler</v-btn>
                        </div>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogConfirmDelete" width="600px">
            <v-card :loading="loading">
                <v-card-title>
                    <h3>Supprimer la question</h3>
                </v-card-title>
                <v-card-text>
                    <p>
                        Êtes-vous sûr de vouloir supprimer la question : "<em>{{ selected_delete_question.question }}</em>" ?
                    </p>
                    <p>
                        Cette action est irreversible. Si la question est utilisée dans un entretien, elle sera supprimée de l'entretien (de même pour les modèles d'entretien).
                    </p>
                    <v-btn
                        dark
                        color="red"
                        class="mr-4"
                        @click="deleteCustomquestion"
                    >
                        Supprimer
                    </v-btn>
                    <v-btn color="darken-1" text @click="dialogConfirmDelete = false">Annuler</v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import AddCustomQuestion from '@/components/AddCustomQuestion';
import Loading from '@/components/Loading';

export default {
    data() {
        return {
            loading: false,
            customquestions: [],
            dialog: false,
            dialogConfirmDelete: false,
            selected_question: false,
            selected_delete_question: false,
            fieldRules: [
                v => !!v || 'Ce champ est requis',
            ],
            snackbar: false,
            snackbarColor: 'success',
            snackbarText: '',
        }
    },
    created() {
        this.getCustomQuestions();
    },
    methods: {
        getCustomQuestions() {
            this.loading = true;
            this.$http
                .get(process.env.VUE_APP_API + '/customquestions')
                .then(response => {
                    this.customquestions = response.data.data;
                    this.loading = false;
                }).catch( () => {
                    //this.errorResults = true;
                    this.loading = false;
                });
        },
        editCustomquestion(question) {
            this.selected_question = question;
            this.dialog = true;
        },
        confirmDelete(question) {
            this.selected_delete_question = question;
            this.dialogConfirmDelete = true;
        },
        deleteCustomquestion() {
            this.loading = true;
            this.$http
                .delete(process.env.VUE_APP_API + '/customquestions/' + this.selected_delete_question.id)
                .then( () => {
                    this.loading = false;
                    this.getCustomQuestions();
                    this.snackbarText = "Question supprimée";
                    this.snackbarColor = 'success';
                    this.snackbar = true;
                    this.dialogConfirmDelete = false;
                }).catch( () => {
                    //this.errorResults = true;
                    this.loading = false;
                });
        },
        saveQuestion() {
            this.loading = true;
            this.$http
                .post(process.env.VUE_APP_API + '/customquestions/' + this.selected_question.id, {
                    question: this.selected_question.question
                })
                .then( () => {
                    this.selected_question = false;
                    this.dialog = false;
                    this.getCustomQuestions();
                    this.snackbarText = "Question enregistrée";
                    this.snackbarColor = 'success';
                    this.snackbar = true;
                }).catch( () => {
                    //this.errorResults = true;
                    this.loading = false;
                });
        }
    },
    components: {
        AddCustomQuestion,
        Loading
    }
}
</script>