import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

import Home from '../views/Home.vue'
import InterviewsComplete from '../views/InterviewsComplete.vue'
import Login from '../views/Login.vue'
import PasswordLost from '../views/PasswordLost.vue'
import PasswordReset from '../views/PasswordReset.vue'
import InterviewEdit from '../views/InterviewEdit.vue'
import InterviewEditReport from '../views/InterviewEditReport.vue'
import InterviewSee from '../views/InterviewSee.vue'
import InterviewsAgency from '../views/InterviewsAgency.vue'
import Customquestions from '../views/Customquestions.vue'
import Interview from '../views/Interview.vue'
import InterviewComplete from '../views/InterviewComplete.vue'
import InterviewSlider from '../views/InterviewSlider.vue'
import InterviewSlider2 from '../views/InterviewSlider2.vue'
import InterviewReview from '../views/InterviewReview.vue'
import InterviewReview2 from '../views/InterviewReview2.vue'
import InterviewDates from '../views/InterviewDates.vue'
import InterviewRates from '../views/InterviewRates.vue'
import InterviewReport from '../views/InterviewReport.vue'
import Linkautodiag from '../views/Linkautodiag.vue'
import AutodiagSent from '../views/AutodiagSent.vue'
import Conseils from '../views/Conseils.vue'
import Stats from '../views/Stats.vue'
import Models from '../views/Models.vue'
import ModelsEdit from '../views/ModelsEdit.vue'
import Users from "@/views/Admin/Users.vue";
import Agencies from "@/views/Admin/Agencies.vue";
import RegionalDirectorates from "@/views/Admin/RegionalDirectorates.vue";
import TerritorialDirectorates from "@/views/Admin/TerritorialDirectorates.vue";
import DefaultQuestions from "@/views/Admin/DefaultQuestions.vue";
import ExportEmployers from "@/views/Admin/ExportEmployers.vue";

Vue.use(VueRouter)

const routes = [
    {
        path: '/export-entreprises',
        name: 'export-entreprises',
        component: ExportEmployers,
        meta: {
            requiresAuth: true,
            breadcrumb: [
                { name: 'Dashboard', 'link': '' }
            ]
        }
    },
    {
        path: '/defaultquestions',
        name: 'default-questions',
        component: DefaultQuestions,
        meta: {
            requiresAuth: true,
            breadcrumb: [
                { name: 'Dashboard', 'link': '' }
            ]
        }
    },
    {
        path: '/regional-directorates',
        name: 'regional-directorates',
        component: RegionalDirectorates,
        meta: {
            requiresAuth: true,
            breadcrumb: [
                { name: 'Dashboard', 'link': '' }
            ]
        }
    },
    {
        path: '/territorial-directorates',
        name: 'territorial-directorates',
        component: TerritorialDirectorates,
        meta: {
            requiresAuth: true,
            breadcrumb: [
                { name: 'Dashboard', 'link': '' }
            ]
        }
    },
    {
        path: '/users',
        name: 'users',
        component: Users,
        meta: {
            requiresAuth: true,
            breadcrumb: [
                { name: 'Dashboard', 'link': '' }
            ]
        }
    },
    {
        path: '/agencies',
        name: 'agencies',
        component: Agencies,
        meta: {
            requiresAuth: true,
            breadcrumb: [
                { name: 'Dashboard', 'link': '' }
            ]
        }
    },
    {
        path: '/stats',
        name: 'stats',
        component: Stats,
        meta: {
            requiresAuth: true,
            breadcrumb: [
                { name: 'Dashboard', 'link': '' }
            ]
        }
    },
    {
        path: '/interviews/:id/editreport',
        name: 'interviews.editreport',
        component: InterviewEditReport,
        meta: {
            requiresAuth: true,
            breadcrumb: [
                { name: 'Dashboard', 'link': '' }
            ]
        }
    },
    {
        path: '/interviews/:id/edit',
        name: 'interviews.edit',
        component: InterviewEdit,
        meta: {
            requiresAuth: true,
            breadcrumb: [
                { name: 'Dashboard', 'link': '' }
            ]
        }
    },
    {
        path: '/interviews/:id/see',
        name: 'interviews.see',
        component: InterviewSee,
        meta: {
            requiresAuth: true,
            breadcrumb: [
                { name: 'Dashboard', 'link': '' }
            ]
        }
    },
    {
        path: '/interviews/:id/report',
        name: 'interview.report',
        component: InterviewReport,
        meta: {
            requiresAuth: true,
            layout: 'interview',
            breadcrumb: [
                { name: 'Dashboard', 'link': '' }
            ]
        }
    },
    {
        path: '/interviews/:id/rates',
        name: 'interview.rates',
        component: InterviewRates,
        meta: {
            requiresAuth: true,
            layout: 'interview',
            breadcrumb: [
                { name: 'Dashboard', 'link': '' }
            ]
        }
    },
    {
        path: '/interviews/:id/dates',
        name: 'interview.dates',
        component: InterviewDates,
        meta: {
            requiresAuth: true,
            layout: 'interview',
            breadcrumb: [
                { name: 'Dashboard', 'link': '' }
            ]
        }
    },
    {
        path: '/interviews/:id/review',
        name: 'interview.review',
        component: InterviewReview,
        meta: {
            requiresAuth: true,
            layout: 'interview',
            breadcrumb: [
                { name: 'Dashboard', 'link': '' }
            ]
        }
    },
    {
        path: '/interviews/:id/review2',
        name: 'interview.review2',
        component: InterviewReview2,
        meta: {
            requiresAuth: true,
            layout: 'interview',
            breadcrumb: [
                { name: 'Dashboard', 'link': '' }
            ]
        }
    },
    {
        path: '/interviews/:id/slider',
        name: 'interview.slider',
        component: InterviewSlider,
        meta: {
            requiresAuth: true,
            layout: 'interview',
            breadcrumb: [
                { name: 'Dashboard', 'link': '' }
            ]
        }
    },
    {
        path: '/interviews/:id/slider2',
        name: 'interview.slider2',
        component: InterviewSlider2,
        meta: {
            requiresAuth: true,
            layout: 'interview',
            breadcrumb: [
                { name: 'Dashboard', 'link': '' }
            ]
        }
    },
    {
        path: '/interviews/:id/complete',
        name: 'interview.complete',
        component: InterviewComplete,
        meta: {
            requiresAuth: true,
            layout: 'interview',
            breadcrumb: [
                { name: 'Dashboard', 'link': '' }
            ]
        }
    },
    {
        path: '/interviews/:id',
        name: 'interview',
        component: Interview,
        meta: {
            requiresAuth: true,
            layout: 'interview',
            breadcrumb: [
                { name: 'Dashboard', 'link': '' }
            ]
        }
    },
    {
        path: '/interviews-complete',
        name: 'interviews.complete',
        component: InterviewsComplete,
        meta: {
            requiresAuth: true,
            breadcrumb: [
                { name: 'Dashboard', 'link': '' }
            ]
        }
    },
    {
        path: '/autodiag-sent',
        name: 'autodiags',
        component: AutodiagSent,
        meta: {
            requiresAuth: true,
            breadcrumb: [
                { name: 'Dashboard', 'link': '' }
            ]
        }
    },
    {
        path: '/interviews-agency',
        name: 'interviews.agency',
        component: InterviewsAgency,
        meta: {
            requiresAuth: true,
            breadcrumb: [
                { name: 'Dashboard', 'link': '' }
            ]
        }
    },
    {
        path: '/models/:id',
        name: 'models.edit',
        component: ModelsEdit,
        meta: {
            requiresAuth: true,
            breadcrumb: [
                { name: 'Dashboard', 'link': '' },
                { name: 'Models', 'link': '' }
            ]
        }
    },
    {
        path: '/models',
        name: 'models',
        component: Models,
        meta: {
            requiresAuth: true,
            breadcrumb: [
                { name: 'Dashboard', 'link': '' }
            ]
        }
    },
    {
        path: '/custom-questions',
        name: 'customquestions',
        component: Customquestions,
        meta: {
            requiresAuth: true,
            breadcrumb: [
                { name: 'Dashboard', 'link': '' }
            ]
        }
    },
    {
        path: '/link',
        name: 'linkautodiag',
        component: Linkautodiag,
        meta: {
            requiresAuth: true,
            breadcrumb: [
                { name: 'Dashboard', 'link': '' }
            ]
        }
    },
    {
        path: '/conseils',
        name: 'conseils',
        component: Conseils,
        meta: {
            requiresAuth: true,
            breadcrumb: [
                { name: 'Dashboard', 'link': '' }
            ]
        }
    },
    {
        path: '/',
        name: 'home',
        component: Home,
        meta: {
            requiresAuth: true,
            breadcrumb: [
                { name: 'Dashboard', 'link': '' }
            ]
        }
    },
    {
        path: '/index.html',
        name: 'home-index',
        component: Home,
        meta: {
            requiresAuth: true,
            breadcrumb: [
                { name: 'Dashboard', 'link': '' }
            ]
        }
    },
    {
        path: '/login',
        name: 'login',
        meta: { layout: 'login' },
        component: Login
    },
    {
        path: '/lost-password',
        name: 'passwordLost',
        meta: { layout: 'login' },
        component: PasswordLost
    },
    {
        path: '/reset-password/:token/:email',
        name: 'passwordReset',
        meta: { layout: 'login' },
        component: PasswordReset
    },

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    if(to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.isLoggedIn) {
            next();
            return;
        }
        if( to && to.path ) {
            next('/login?from=' + to.path);
        } else {
            next('/login');
        }
    } else {
        next();
    }
});

export default router
