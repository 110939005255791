<template>
  <v-container
      fluid
  >

    <v-row v-if="loading">
      <v-card-text >
        <Loading />
      </v-card-text>
    </v-row>
    <template v-else>
      <v-row>
        <v-col cols="8">
          <v-card>
            <v-card-text>
              <h1 class="py-3 pa-2">
                Directions Régionales
              </h1>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card class="py-3 pa-2">
            <v-card-text class="py-2 pa-2 text-center">
              <v-btn class="text-body-2"
                     color="primary"
                     @click="addRegionalDirectorateModal = true"
              >
                <v-icon class="pr-2">mdi-account-plus</v-icon>
                Ajouter une direction régionale
              </v-btn>
              <v-dialog
                  v-model="addRegionalDirectorateModal"
                  width="50%"
              >
                <v-card class="pa-3">
                  <v-card-text>
                    <v-row>
                      <v-col
                          cols="12"
                      >
                        <v-text-field
                            :rules="nameRules"
                            v-model="newRegionalDirectorateName"
                            :counter="10"
                            label="Nom de la Direction Régionale"
                            required
                            hide-details
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-row>
                      <v-col cols="6">
                        <v-btn color="secondary" block @click="closeRegionalDirectorateModal()">Annuler</v-btn>
                      </v-col>
                      <v-col cols="6">
                        <v-btn color="success" block @click="saveRegionalDirectorate()">Ajouter la Direction Régionale</v-btn>
                      </v-col>
                    </v-row>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog
                  v-model="editRegionalDirectorateModal"
                  width="50%"
              >
                <v-card class="pa-3">
                  <v-card-text>
                    <v-row>
                      <v-col
                          cols="12"
                      >
                        <v-text-field
                            :rules="nameRules"
                            v-model="editedRegionalDirectorate.name"
                            :counter="10"
                            label="Nom de l'agence"
                            required
                            hide-details
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-row>
                      <v-col cols="6">
                        <v-btn color="secondary" block @click="closeRegionalDirectorateModal()">Annuler</v-btn>
                      </v-col>
                      <v-col cols="6">
                        <v-btn color="success" block @click="editRegionalDirectorate()">Modifier la Direction Régionale</v-btn>
                      </v-col>
                    </v-row>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
              :headers="[
              { text: 'Nom', value: 'name' },
              { text: 'Actions', value: 'actions' }
            ]"
              :items="regionalDirectorates"
              :items-per-page="20"
              class="elevation-1">
            <template v-slot:item.actions="{item}">
              <v-btn
                  class="mr-2"
                  color="primary"
                  @click="editRegionalDirectorateModal = true; editedRegionalDirectorate = item;"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                  color="error"
                  @click="deleteRegionalDirectorate(item)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </template>


  </v-container>
</template>

<script>
import Loading from '@/components/Loading';

export default {
  data(){
    return {
      regionalDirectorates: false,
      addRegionalDirectorateModal: false,
      editRegionalDirectorateModal: false,
      editedRegionalDirectorate: false,
      loading: false,

      nameRules: [
        value => {
          if (value) return true

          return 'Le nom est requis'
        }
      ],

      newRegionalDirectorateName: '',
    }
  },

  created() {
    this.getRegionalDirectorate();
  },

  methods: {
    getRegionalDirectorate() {
      this.loading = true;
      let url = process.env.VUE_APP_API + '/regional-directorates';

      this.$http
          .get(url)
          .then(response => {
            this.regionalDirectorates = response.data.regional_directorates;
            this.loading = false;
          }).catch( () => {
        this.loading = false;
      });
    },

    closeRegionalDirectorateModal() {
      this.addRegionalDirectorateModal     = false;
      this.editedRegionalDirectorate       = false;
      this.editRegionalDirectorateModal    = false;
      this.newRegionalDirectorateName      = '';
    },

    saveRegionalDirectorate() {
      this.loading = true;
      let url = process.env.VUE_APP_API + '/regional-directorate/create';

      this.$http
          .post(url, {
            'name': this.newRegionalDirectorateName,
          })
          .then(response => {
            this.regionalDirectorates = response.data.regional_directorates;
            this.loading = false;
            this.closeRegionalDirectorateModal();
          }).catch( () => {
        this.loading = false;
      });
    },

    editRegionalDirectorate() {
      this.loading = true;
      let url = process.env.VUE_APP_API + '/regional-directorate/update';

      this.$http
          .post(url, {
            'id': this.editedRegionalDirectorate.id,
            'name': this.editedRegionalDirectorate.name,
          })
          .then(response => {
            this.regionalDirectorates = response.data.regional_directorates;
            this.loading = false;
            this.closeRegionalDirectorateModal();
          }).catch( () => {
        this.loading = false;
      });
    },

    deleteRegionalDirectorate(regionalDirectorate) {

      if (window.confirm("Supprimer la direction régionale " + regionalDirectorate.name + " ?")) {
        this.loading = true;
        let url = process.env.VUE_APP_API + '/regional-directorate/delete';

        this.$http
            .post(url, {
              'id': regionalDirectorate.id,
            })
            .then(response => {
              this.regionalDirectorates = response.data.regional_directorates;
              this.loading = false;
            }).catch(() => {
          this.loading = false;
        });
      }

    }
  },

  components: {
    Loading
  }
}
</script>
