<template>
  <v-container
      fluid
  >

    <v-row v-if="loading">
      <v-card-text >
        <Loading />
      </v-card-text>
    </v-row>
    <template v-else>
      <v-row>
        <v-col cols="8">
          <v-card>
            <v-card-text>
              <h1 class="py-3 pa-2">
                Agences
              </h1>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card class="py-3 pa-2">
            <v-card-text class="py-2 pa-2 text-center">
              <v-btn class="text-body-2"
                     color="primary"
                     @click="addAgencyModal = true"
              >
                <v-icon class="pr-2">mdi-account-plus</v-icon>
                Ajouter une agence
              </v-btn>
              <v-dialog
                  v-model="addAgencyModal"
                  width="50%"
              >
                <v-card class="pa-3">
                  <v-card-text>
                    <v-row>
                      <v-col
                          cols="12"
                      >
                        <v-text-field
                            :rules="nameRules"
                            v-model="newAgencyName"
                            :counter="10"
                            label="Nom de l'agence"
                            required
                            hide-details
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-row>
                      <v-col cols="6">
                        <v-btn color="secondary" block @click="closeNewAgencyModal()">Annuler</v-btn>
                      </v-col>
                      <v-col cols="6">
                        <v-btn color="success" block @click="saveNewAgency()">Ajouter l'agence</v-btn>
                      </v-col>
                    </v-row>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog
                  v-model="editAgencyModal"
                  width="50%"
              >
                <v-card class="pa-3">
                  <v-card-text>
                    <v-row>
                      <v-col
                          cols="12"
                      >
                        <v-text-field
                            :rules="nameRules"
                            v-model="editedAgency.name"
                            :counter="10"
                            label="Nom de l'agence"
                            required
                            hide-details
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-row>
                      <v-col cols="6">
                        <v-btn color="secondary" block @click="closeNewAgencyModal()">Annuler</v-btn>
                      </v-col>
                      <v-col cols="6">
                        <v-btn color="success" block @click="editAgency()">Modifier l'agence</v-btn>
                      </v-col>
                    </v-row>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
              :headers="[
              { text: 'Nom', value: 'name' },
              { text: 'Rattaché à la Direction Territoriale suivante :', value: 'territorial_directorate_id' },
              { text: 'Actions', value: 'actions' }
            ]"
              :items="agencies"
              :items-per-page="20"
              class="elevation-1">
            <template v-slot:item.territorial_directorate_id="{item}">
              <v-select
                  :value="item.territorial_directorate_id"
                  :v-model="item.territorial_directorate_id"
                  :items="territorialDirectorates"
                  item-text="name"
                  item-value="id"
                  @change="changeTerritorialDirectorate($event, item.id)"
              ></v-select>
            </template>
            <template v-slot:item.actions="{item}">
              <v-btn
                  class="mr-2"
                  color="primary"
                  @click="editAgencyModal = true; editedAgency = item; editedAgencyAgencies();"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                  color="error"
                  @click="deleteAgency(item)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </template>


  </v-container>
</template>

<script>
import Loading from '@/components/Loading';

export default {
  data(){
    return {
      agencies: [],
      territorialDirectorates: [],
      addAgencyModal: false,
      editAgencyModal: false,
      editedAgency: false,
      loading: false,

      nameRules: [
        value => {
          if (value) return true

          return 'Le nom est requis'
        }
      ],

      newAgencyName: '',
    }
  },

  created() {
    this.getAgencies();
    this.getTerritorialDirectorates();
  },

  methods: {
    getAgencies() {
      this.loading = true;
      let url = process.env.VUE_APP_API + '/agencies';

      this.$http
          .get(url)
          .then(response => {
            this.agencies = response.data.agencies;
            this.loading = false;
          }).catch( () => {
        this.loading = false;
      });
    },

    getTerritorialDirectorates() {
      this.loading = true;
      let url = process.env.VUE_APP_API + '/territorial-directorates';

      this.$http
          .get(url)
          .then(response => {
            this.territorialDirectorates = response.data.territorial_directorates;
            this.loading = false;
          }).catch( () => {
        this.loading = false;
      });
    },

    editedAgencyAgencies() {
      this.editedAgency.agencies_ids = JSON.parse(this.editedAgency.agencies_ids);


      this.editedAgency.agencies_ids.forEach((userAgency, index) => {
        this.agencies.filter((element) => {
          if (element.id === userAgency){
            this.editedAgency.agencies_ids[index] = element;
          }
        });
      });
    },

    closeNewAgencyModal() {
      this.addAgencyModal     = false;
      this.editedAgency       = false;
      this.editAgencyModal    = false;
      this.newAgencyName      = '';
    },

    saveNewAgency() {
      this.loading = true;
      let url = process.env.VUE_APP_API + '/agency/create';

      this.$http
          .post(url, {
            'name': this.newAgencyName,
          })
          .then(response => {
            this.agencies = response.data.agencies;
            this.loading = false;
            this.closeNewAgencyModal();
          }).catch( () => {
        this.loading = false;
      });
    },

    editAgency() {
      this.loading = true;
      let url = process.env.VUE_APP_API + '/agency/create';

      this.$http
          .post(url, {
            'id': this.editedAgency.id,
            'name': this.editedAgency.name,
          })
          .then(response => {
            this.agencies = response.data.agencies;
            this.loading = false;
            this.closeNewAgencyModal();
          }).catch( () => {
        this.loading = false;
      });
    },

    deleteAgency(agency) {

      if (window.confirm("Supprimer l'agence " + agency.name + " ?")) {
        this.loading = true;
        let url = process.env.VUE_APP_API + '/agency/delete';

        this.$http
            .post(url, {
              'id': agency.id,
            })
            .then(response => {
              this.agencies = response.data.agencies;
              this.loading = false;
            }).catch(() => {
          this.loading = false;
        });
      }

    },

    changeTerritorialDirectorate(event, agency_id) {
      this.loading = true;
      let url = process.env.VUE_APP_API + '/agency/territorial-directorate';

      this.$http
          .post(url, {
            'agency_id': agency_id,
            'territorial_directorate_id': event,
          })
          .then(response => {
            this.agencies = response.data.agencies;
            this.loading = false;
          }).catch(() => {
        this.loading = false;
      });
    }
  },

  components: {
    Loading
  }
}
</script>
