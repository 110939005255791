<template>
    <div>
        <div v-if="q.answered">
            <div class="interview-questions-title">
                <span class="interview-questions-title-number" :style="'background-color:'+q.question.category.color">{{ q.question.num }}</span> <span class="interview-questions-title-question" v-html="q.question.question"></span>
            </div>
            <div>
                <v-textarea
                    solo
                    name="input-7-4"
                    label="Saisissez la réponse"
                    v-model="q.answer"
                    @keydown="answerEdited(q)"
                ></v-textarea>
                <template v-if="! q.saving">
                    <a href="#" class="interview-questions-reinit" @click.prevent="$emit('reinit', q);">
                        Réinitialiser
                    </a>
                </template>
            </div>
        </div>
        <div v-else>
            <div>
                <div class="interview-questions-title">
                    <span class="interview-questions-title-number" :style="'background-color:'+q.question.category.color">{{ q.question.num }}</span> <span class="interview-questions-title-question">
                        Combien de temps en moyenne un salarié reste dans l'entreprise ?
                    </span>
                </div>
                <v-row>
                    <v-col>
                        <v-text-field
                            v-model="reponse1"
                            type="number"
                            label="Nombre"
                            @change="answerEdited(q)"
                        ></v-text-field>
                    </v-col>
                    <v-col>
                        <v-select
                            :items="mois_annees"
                            label=""
                            v-model="reponse2"
                            @change="answerEdited(q)"
                        ></v-select>
                    </v-col>
                </v-row>
                
                
            </div>
            
        </div>
        <div>
            <v-btn :loading="q.saving" :disabled="! q.edited || q.saving" color="primary" @click="saveAnswer(q)">Enregistrer</v-btn>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            step: 1,
            reponse1: '',
            reponse2: 'mois',
            mois_annees: [
                'mois',
                'années'
            ],
        }
    },
    methods: {
        saveAnswer(question) {
            question.comment = {
                reponse1: this.reponse1,
                reponse2: this.reponse2,
            };
            this.$emit('saved', question);
            // clean data
            question.comment = '';
            this.reponse1 = '';
            this.reponse2 = 'mois';
        },
        answerEdited(question) {
            if( ! question.edited ) {
                question.edited = true;
            }
            this.$emit('editing', question);
        },
        displayNext() {
            /*if( this.reponse1 == 'avoir besoin d’un appui' ) {
                this.step = 2;
            } else {
                this.step = 3;
            }*/
            this.q.edited = true;
        }
    },
    props: [
        'q',
    ],
}
</script>