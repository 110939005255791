import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Axios from 'axios';
import Notifications from 'vue-notification';
Vue.use(Notifications);
//import VTooltip from 'v-tooltip';
//Vue.use(VTooltip);
import VueApexCharts from 'vue-apexcharts';
Vue.component('apexchart', VueApexCharts);

// hide consol tooltip
Vue.config.productionTip = false

// import layouts
import DefaultLayout from "./layouts/Default.vue";
import InterviewLayout from "./layouts/Interview.vue";
import LoginLayout from "./layouts/Login.vue";
import vuetify from './plugins/vuetify';
Vue.component("default-layout", DefaultLayout);
Vue.component("interview-layout", InterviewLayout);
Vue.component("login-layout", LoginLayout);

Vue.prototype.$http = Axios;
//Vue.prototype.$http.defaults.withCredentials = true;
const token = Vue.$cookies.get('pe_user_token');
if (token) {
    Vue.prototype.$http.defaults.headers.common['Authorization'] = 'Bearer ' + token;
}

var vueapp = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
});

if (store.getters.isLoggedIn) {
  // control user is logged in on API before mounting the app
  Axios
    .get(process.env.VUE_APP_API + '/auth/me')
    .then( response => {
      // user is connected, refresh token directly
      // update user data
      store.dispatch('update_user', response.data.user)
        .then(() => {})
        .catch(() => {});
      
      //we can mount the app
      vueapp.$mount('#app'); 
    }).catch(() => {
      // error, delete token cookie, and then mount the app
      Vue.$cookies.remove('pe_user_token');
      delete Axios.defaults.headers.common['Authorization'];
      vueapp.$mount('#app');
    });
} else {
  // user token isn't set, display app right away
  vueapp.$mount('#app');    
}
