<template>
    <div class="interview">

        <div v-if="loading" class="pt-4">
            <Loading />
        </div>
        <v-container v-else fluid>
            <div class="topbar">
                <div class="topbar-title">
                    <span class="topbar-title-span">
                        Entretien : {{ project.employer.company }}
                    </span>
                </div>
            </div>
            <div class="interview-questions">
                <v-row align="center" justify="center">
                    <v-col cols="12" lg="8" md="10">
                        <v-card class="mb-5" min-width="100%">
                            <v-stepper value="1">
                                <v-stepper-header>
                                    <v-stepper-step step="1" complete>Entretien<br>enregistré</v-stepper-step>
                                    <v-divider></v-divider>
                                    <v-stepper-step step="2">Positionnement<br>CSP</v-stepper-step>
                                    <v-divider></v-divider>
                                    <v-stepper-step step="3">Positionnement<br>CPE</v-stepper-step>
                                    <v-divider></v-divider>
                                    <v-stepper-step step="4">Pistes d'actions<br>et échéances</v-stepper-step>
                                    <v-divider></v-divider>
                                    <v-stepper-step step="5">Évaluation</v-stepper-step>
                                </v-stepper-header>
                            </v-stepper>
                            <v-card-text class="text-center">
                                <h3 class="orange--text mb-4 mt-2 font-weight-bold">
                                    Merci. Vous venez d'enregistrer l'entretien.
                                </h3>
                                <p class="black--text" style="font-size:15px">
                                    Avez vous bien validé les réponses avec l’employeur ? 
                                </p>
                                <p class="black--text" style="font-size:15px">
                                    Si ce n’est pas le cas, faites “retour” pour revoir les réponses. Sinon, passez à la suite.
                                </p>
                                <p class="black--text" style="font-size:15px">
                                    L'employeur peut exprimer une réserve sur les propos exprimés; dans ce cas, convenir d'une nouvelle formulation ou décider ensemble de ne pas le faire figurer dans le rapport.
                                </p>
                            </v-card-text>
                            <v-card-text class="text-right">
                                <v-btn text small :to="{ name: 'interview', params: {id: project.id} }">Retour</v-btn> <!--<v-btn text small color="primary" :to="{ name: 'interview', params: {id: project.id}, query: {validate: 1} }">Suivant</v-btn>-->
                                <v-btn text small color="primary" :to="{ name: 'interview.slider', params: {id: project.id} }">Suivant</v-btn>
                                
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </div>
        </v-container>
    </div>
</template>

<script>
import Loading from '@/components/Loading';

export default {
    data(){
        return {
            loading: false,
            loadingQuestions: false,
            project: false,
        }
    },
    created() {
        this.getProject();
    },
    methods: {
        getProject() {
            this.loading = true;
            this.$http
                .get(process.env.VUE_APP_API + '/projects/' + this.$route.params.id)
                .then(response => {
                    this.project = response.data.data;
                    this.getSelectedQuestions();
                    this.loading = false;
                }).catch( () => {
                    this.loading = false;
                });
        }
    },
    components: {
        Loading
    }
}
</script>