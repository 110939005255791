<template>
    <div class="interviewedit">

        <v-snackbar
            v-model="snackbar"
            :color="snackbarColor"
        >
            {{ snackbarText }}

            <template v-slot:action="{ attrs }">
                <v-btn
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    Fermer
                </v-btn>
            </template>
        </v-snackbar>

        <div v-if="loading" class="pt-4">
            <Loading />
        </div>
        <v-container v-else fluid class="pb-5">

            <div v-if="project.status && project.status === 'Terminé'">
                <v-row align="center" justify="center">
                    <v-col cols="12" lg="8" md="10">
                        <v-card>
                            <v-card-text class="text-center">
                                <h3 class="mb-2">Entretien terminé</h3>
                                <p>
                                    Vous ne pouvez plus éditer cet entretien.
                                </p>
                                <div>
                                    <v-btn small :to="{ name: 'interviews.see', params: {id: project.id} }">Retour à la consultation</v-btn>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </div>
            <template v-else>
                <div class="topbar">
                    <div class="topbar-title">
                        <span class="topbar-title-span">
                            Outil d'aide au diagnostic de l'entreprise : {{ project.employer.company }}
                        </span>
                        <Autodiagnostic v-if="project && project.employers" :employer="project.employer" :employers="project.employers" @reload="getProject" />
                        <Autodiagnostic v-else :employer="project.employer" @reload="getProject" />
                    </div>
                    <div class="topbar-actions">
                        <template v-if="loadingPdf">
                            <v-btn class="mr-2" small disabled>Téléchargement...</v-btn>
                        </template>
                        <template v-else>
                            <v-btn class="mr-2 mb-1" small @click="downloadQuestions"><v-icon>mdi-download</v-icon> Télécharger les questions</v-btn>
                        </template>

                        <v-btn color="primary" class="mb-1" small :to="{ name: 'interview', params: {id: project.id} }">Démarrer l'entretien</v-btn>
                    </div>
                </div>

                <v-dialog v-model="modal_import_model" width="750px">
                    <v-card>
                        <v-card-title>
                            <span class="headline mr-2">
                                Quel modèle d'entretien souhaitez-vous importer ?
                                <v-tooltip top color="black" :max-width="700">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" class="ml-1" color="black">mdi-information</v-icon>
                                    </template>
                                    <span>
                                        Quand un modèle est importé, si l’entretien a déjà des questions, elles ne sont pas supprimées ou écrasées. Les questions du modèle importé sont ajoutées à la suite des questions déjà sélectionnées. Bien vérifier qu’il n’y a pas de doublon.<br>
                                        <br>
                                        Vous pouvez partager vos modèles avec d’autres conseillers, aller sur la page « mes modèles d’entretien », cliquer sur « partager », et choisissez avec qui partager votre modèle parmi les conseillers de votre agence. Aucune notification n’est envoyée, mais le conseiller le verra apparaître dans son listing de modèles.
                                    </span>
                                </v-tooltip>
                            </span>
                        </v-card-title>
                        <v-card-text>
                            <Loading v-if="loadingModels" />
                            <template v-else-if="projectmodels && projectmodels.length > 0">
                                <v-select
                                    :items="projectmodels"
                                    :item-text="getFieldText"
                                    item-value="id"
                                    label="Modèle à importer"
                                    v-model="model_selected"
                                    return-object
                                ></v-select>
                                <v-btn @click.prevent="import_model" :loading="loadingImportModel" :disabled="loadingImportModel" color="primary">
                                    Importer
                                </v-btn>
                            </template>
                            <template v-else>
                                <em>Vous n'avez pas encore créé de modèle d'entretien.</em>
                            </template>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="darken-1" text @click="modal_import_model = false">Fermer</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <div class="tab-container">
                    <v-tabs
                        v-model="tab"
                        align-with-title
                        background-color="#eee"
                        grow
                        centered
                    >
                        <v-tabs-slider color="#f16c08"></v-tabs-slider>

                        <v-tab key="tab_categ">
                            Préparation par thématique
                        </v-tab>
                        <v-tab key="tab_csp">
                            Préparation par axe CSP
                        </v-tab>
                    </v-tabs>
                </div>

                <v-row class="pt-4">
                    <v-col class="pt-0 pb-1">
                        <div class="d-flex" style="height:100%;align-items:center;justify-content:center;">
                            <div>
                                <v-btn color="black" dark small @click.prevent="display_import_modal">
                                    <v-icon color="white" class="mr-1">mdi-upload</v-icon> Importer un modèle d'entretien
                                </v-btn>
                            </div>
                        </div>
                    </v-col>
                    <v-col class="pt-0 pb-1">
                        <v-card class="d-flex" style="height:100%;align-items:center">
                            <v-card-text class="text-center">
                                <div v-if="! edit_date">
                                    <template v-if="project.employer.date_appointment && project.employer.date_appointment.length > 0">
                                        <h3 class="d-inline-block" style="vertical-align:middle">
                                            {{ project.employer.date_appointment }}
                                        </h3> <v-btn class="ml-3" small @click.prevent="edit_date=true">modifier</v-btn>
                                    </template>
                                    <div v-else class="text-center">
                                        <v-btn @click.prevent="edit_date=true" small>Définir la date d'entretien</v-btn>
                                    </div>
                                </div>
                                <template v-else>
                                    <v-row class="pt-O">
                                        <v-col class="pb-0 pt-0">
                                            <v-menu
                                                ref="ref_date_appointment"
                                                v-model="ref_date_appointment"
                                                :close-on-content-click="false"
                                                :return-value.sync="date_appointment"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290px"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        v-model="date_appointment"
                                                        label="Date d'entretien"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker v-model="date_appointment" no-title scrollable locale="fr" first-day-of-week="1">
                                                    <v-spacer></v-spacer>
                                                    <v-btn text color="primary" @click="ref_date_appointment = false">Cancel</v-btn>
                                                    <v-btn text color="primary" @click="$refs.ref_date_appointment.save(date_appointment)">OK</v-btn>
                                                </v-date-picker>
                                            </v-menu>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col class="pt-0">
                                            <v-select
                                                :items="available_hours"
                                                label="Heure"
                                                v-model="appointment_hour"
                                            ></v-select>
                                        </v-col>
                                        <v-col class="pt-0">
                                            <v-select
                                                :items="available_minutes"
                                                label="Minute"
                                                v-model="appointment_minutes"
                                            ></v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row justify="center">
                                        <v-btn @click="saveDate" primary color="primary" small>Enregistrer</v-btn>
                                    </v-row>
                                </template>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col class="pt-0 pb-1">
                        <v-card class="d-flex" style="height:100%;align-items:center">
                            <v-card-text class="pb-0">
                                <v-select
                                    :items="available_status"
                                    label="Statut"
                                    v-model="project.status"
                                    @change="saveStatus"
                                ></v-select>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

                <v-tabs-items v-model="tab" style="background-color: transparent">
                    <v-tab-item key="tab_categ">

                        <div class="row pb-5">
                            <div class="col-lg-3 col-md-4 col-12">
                                <div class="pt-4" v-if="loadingQuestions">
                                    <Loading />
                                </div>
                                <template v-else>
                                    <div v-for="category in categories" :key="'main_'+category[0].category.id" class="mb-4">
                                        <v-card>
                                            <v-card-text :style="'background-color:'+category[0].category.color_background">
                                                <h3>{{ category[0].category.group }}</h3>
                                            </v-card-text>
                                            <v-simple-table>
                                                <template v-slot:default>
                                                    <tbody>
                                                        <tr v-for="categ in category" :key="'main_'+category[0].category.id+'_sub_'+categ.category.id" style="cursor:pointer" @click="selectCategory(categ)" :class="{selected: selectedCategory === categ.category.id}">
                                                            <td>
                                                                {{ categ.category.name }}
                                                            </td>
                                                            <td class="text-right">
                                                                <v-icon color="" class="mr-1">mdi-chevron-right</v-icon>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                        </v-card>
                                    </div>
                                    <AddCustomQuestion :fulltabs="1" @addCustomQuestion="addCustomQuestion" />
                                </template>
                            </div>
                            <div class="col-lg-9 col-md-8 col-12">

                                <v-row>
                                    <v-col class="pt-0">
                                        <v-card>
                                            <v-card-text>
                                                <h3>Questions disponibles</h3>
                                            </v-card-text>
                                            <div v-if="questions && questions.length > 0">
                                                <v-data-table
                                                    :headers="headers"
                                                    :options.sync="tableoptions"
                                                    :items="questions"
                                                    class="elevation-1"
                                                    :loading="loadingQuestions"
                                                    hide-default-footer
                                                >
                                                    <template v-slot:item.question="{ item }">
                                                        <span v-html="item.question"></span>
                                                    </template>
                                                    <template v-slot:item.actions="{ item }">
                                                        <template v-if="item.infobulle_front">
                                                            <v-tooltip top color="black" :max-width="500">
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-icon v-bind="attrs" v-on="on">mdi-information</v-icon>
                                                                </template>
                                                                <span>{{ item.infobulle_front }}</span>
                                                            </v-tooltip>
                                                        </template>

                                                        <template v-if="questionSelected(item)">
                                                            <v-icon color="green" class="ml-2">mdi-check-circle</v-icon>
                                                        </template>
                                                        <template v-else>
                                                            <v-icon color="primary" class="ml-2" @click="addQuestion(item)">mdi-plus-circle</v-icon>
                                                        </template>
                                                    </template>
                                                </v-data-table>
                                            </div>
                                            <div v-else>
                                                <v-card-text>
                                                    <em>Sélectionnez un module pour afficher les questions disponibles</em>
                                                </v-card-text>
                                            </div>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </div>
                        </div>
                    </v-tab-item>

                    <v-tab-item key="tab_csp">
                        <div class="row">
                            <div class="col-md-6">
                                <v-card class="mb-4" style="overflow:hidden">
                                    <v-card-text>
                                        <div ref="chart1" id="chart1" v-if="chart1data && chart1data[0] && chart1data[0].data && chart1data[0].data.length > 0" style="margin-top:-60px;margin-bottom:-85px;">
                                            <apexchart height="400" type="radar" :options="chart1Options" :series="chart1data"></apexchart>
                                        </div>
                                    </v-card-text>
                                </v-card>
                                <v-card v-if="selectedAutocategory && selectedAutocategory > 0 && autoanswers && autoanswers.length > 0">
                                    <v-card-text>
                                        <div v-for="ans in autoanswers" :key="'autoanswer_' + ans.question">
                                            <div>
                                                <strong>
                                                    {{ ans.question }}
                                                </strong>
                                            </div>
                                            <div class="mb-3">
                                                {{ ans.answer }}<br>
                                                En savoir plus : {{ ans.knowmore }}
                                            </div>
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </div>
                            <div class="col-md-6">
                                <v-card>
                                    <v-card-text v-if="selectedAutocategory && selectedAutocategory > 0">
                                        <div @click.prevent="selectedAutocategory = 0" class="autocategory_back">
                                            <v-icon color="black">mdi-arrow-left</v-icon> <span>Retour aux catégories CSP</span>
                                        </div>

                                        <div class="autocategory_name">
                                            {{ this.selectedAutocategoryName }}
                                        </div>

                                        <v-tabs
                                            v-model="tab_type_q"
                                            align-with-title
                                            background-color="#eee"
                                            grow
                                            centered
                                        >
                                            <v-tabs-slider color="#f16c08"></v-tabs-slider>

                                            <v-tab key="tab_type_q_important">
                                                Prioritaires
                                            </v-tab>
                                            <v-tab key="tab_type_q_other">
                                                Subsidiaires
                                            </v-tab>
                                        </v-tabs>

                                        <v-tabs-items v-model="tab_type_q" style="background-color: transparent">
                                            <v-tab-item key="tab_type_q_important">
                                                <div v-if="autoquestions.important">
                                                    <v-data-table
                                                    :headers="headers"
                                                    :options.sync="tableoptions"
                                                    :items="autoquestions.important"
                                                    :loading="loadingQuestionsCSP"
                                                    hide-default-footer
                                                    >
                                                        <template v-slot:item.question="{ item }">
                                                            <span v-html="item.question"></span>
                                                        </template>
                                                        <template v-slot:item.actions="{ item }">
                                                            <template v-if="item.infobulle_front">
                                                                <v-tooltip top color="black" :max-width="500">
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-icon v-bind="attrs" v-on="on">mdi-information</v-icon>
                                                                    </template>
                                                                    <span>{{ item.infobulle_front }}</span>
                                                                </v-tooltip>
                                                            </template>

                                                            <template v-if="questionSelected(item)">
                                                                <v-icon color="green" class="ml-2">mdi-check-circle</v-icon>
                                                            </template>
                                                            <template v-else>
                                                                <v-icon color="primary" class="ml-2" @click="addQuestion(item)">mdi-plus-circle</v-icon>
                                                            </template>
                                                        </template>
                                                    </v-data-table>
                                                </div>
                                                <div v-else class="text-center pt-2">
                                                    <em>Aucune question prioritaire</em>
                                                </div>
                                            </v-tab-item>
                                            <v-tab-item key="tab_type_q_other">
                                                <div v-if="autoquestions.other">
                                                    <v-data-table
                                                    :options.sync="tableoptions"
                                                    :headers="headers"
                                                    :items="autoquestions.other"
                                                    :loading="loadingQuestionsCSP"
                                                    hide-default-footer
                                                    >
                                                        <template v-slot:item.question="{ item }">
                                                            <span v-html="item.question"></span>
                                                        </template>
                                                        <template v-slot:item.actions="{ item }">
                                                            <template v-if="item.infobulle_front">
                                                                <v-tooltip top color="black" :max-width="500">
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-icon v-bind="attrs" v-on="on">mdi-information</v-icon>
                                                                    </template>
                                                                    <span>{{ item.infobulle_front }}</span>
                                                                </v-tooltip>
                                                            </template>

                                                            <template v-if="questionSelected(item)">
                                                                <v-icon color="green" class="ml-2">mdi-check-circle</v-icon>
                                                            </template>
                                                            <template v-else>
                                                                <v-icon color="primary" class="ml-2" @click="addQuestion(item)">mdi-plus-circle</v-icon>
                                                            </template>
                                                        </template>
                                                    </v-data-table>
                                                </div>
                                                <div v-else class="text-center pt-2">
                                                    <em>Aucune question subsidiare</em>
                                                </div>
                                            </v-tab-item>
                                        </v-tabs-items>


                                    </v-card-text>
                                    <v-card-text v-else-if="autocategories && autocategories.length > 0" class="pb-1">

                                        <h3 class="mb-4">Questions disponibles</h3>

                                        <div v-if="autocategory[0].autocategory !== null"  v-for="autocategory in autocategories" :key="'main_'+autocategory[0].autocategory.id" class="mb-4">
                                            <div>
                                                <h3 class="csp_categ_title">{{ autocategory[0].autocategory.categ }}</h3>
                                            </div>
                                            <v-simple-table>
                                                <template v-slot:default>
                                                    <tbody>
                                                        <tr v-for="categ in autocategory" :key="'main_'+autocategory[0].autocategory.id+'_sub_'+categ.autocategory.id" style="cursor:pointer" @click="selectAutocategory(categ)" :class="{selected: selectedAutocategory === categ.autocategory.id}">
                                                            <td>
                                                                {{ categ.autocategory.name }}
                                                                <v-tooltip top color="black" :max-width="500">
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-icon color="" small v-bind="attrs" v-on="on">mdi-information</v-icon>
                                                                    </template>
                                                                    <span>
                                                                        {{ categ.definition }}
                                                                    </span>
                                                                </v-tooltip>
                                                            </td>
                                                            <td class="text-right">
                                                                <v-icon color="" class="mr-1">mdi-chevron-right</v-icon>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </template>
                                            </v-simple-table>
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </div>
                        </div>
                    </v-tab-item>
                </v-tabs-items>


                <div class="selectedQuestions" v-if="displaySelectedQuestions">
                    <div class="selectedQuestions-hover" @click="displaySelectedQuestions = false"></div>

                    <div class="selectedQuestions-content selectedQuestions-content--big">
                        <h3 class="selectedQuestions-content-header yellow darken-2 pa-3" @click="displaySelectedQuestions = false">
                            <v-icon color="black">mdi-arrow-bottom-right</v-icon> Votre sélection pour {{ project.employer.company }} ({{ selectedQuestions.length }})
                        </h3>

                        <Loading v-if="loadingSelectedQuestions" :hover="1" />
                        <template>
                            <div class="orderingQuestionsLegend">
                                Vous pouvez choisir de classer vos questions dans l’ordre que vous souhaitez. Pour cela, cliquer sur l’icône devant la question, et le faire glisser de haut en bas ou du bas vers le haut.
                            </div>
                            <div class="orderingQuestions">
                                <div v-if="selectedQuestions && selectedQuestions.length > 0">
                                    <draggable v-model="selectedQuestions" handle=".handle" draggable=".item" @end="saveOrder">
                                        <transition-group>
                                            <template v-for="q in selectedQuestions">
                                                <div class="orderingQuestions-line item" :key="'selected_'+q.id">
                                                    <div class="orderingQuestions-line-question">
                                                        <div class="handle ">
                                                            <!-- <v-icon>mdi-unfold-more-horizontal</v-icon> -->
                                                            <v-icon color="black">mdi-arrow-all</v-icon>
                                                        </div>
                                                        <div v-if="q.question && q.question.question">
                                                            <!--<small>({{ q.question.type }})</small> - --><span v-html="q.question.question"></span>
                                                        </div>
                                                        <div v-else-if="q.customquestion && q.customquestion.question">
                                                            <!--<small>(perso)</small> - -->{{ q.customquestion.question }}
                                                        </div>
                                                    </div>
                                                    <div class="orderingQuestions-line-actions">
                                                        <template v-if="q.question && q.question.id">
                                                            <template v-if="q.question.infobulle_back">
                                                                <v-tooltip left color="black" :max-width="500">
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-icon v-bind="attrs" v-on="on">mdi-information</v-icon>
                                                                    </template>
                                                                    <span>{{ q.question.infobulle_back }}</span>
                                                                </v-tooltip>
                                                            </template>
                                                            <v-icon color="red" class="ml-2" @click="removeQuestion(q)">mdi-minus-circle</v-icon>
                                                        </template>
                                                        <template v-else-if="q.customquestion && q.customquestion.id">
                                                            <v-icon color="red" class="ml-2" @click="removeQuestion(q)">mdi-minus-circle</v-icon>
                                                        </template>
                                                    </div>
                                                </div>
                                            </template>
                                        </transition-group>
                                    </draggable>
                                    <div class="orderingQuestionsShare">
                                        <p>
                                            Vous pouvez enregistrer ces questions comme modèle :
                                        </p>
                                        <CreateProjectmodel :project_id="project.id" @modelCreated="displayNotif" />
                                    </div>
                                </div>
                                <div v-else class="pl-2 pt-1">
                                    <em>Vous n'avez pas encoré sélectionné de questions.</em>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
                <div class="selectedQuestionsTag" v-else @click="displaySelectedQuestions = true">
                    <div class="yellow darken-2 pa-3">
                        <v-icon color="black">mdi-arrow-top-left</v-icon> Questions sélectionnées ({{ selectedQuestions.length }})
                    </div>
                </div>
            </template>
        </v-container>
    </div>
</template>

<script>
import draggable from 'vuedraggable';
import Autodiagnostic from '@/components/Autodiagnostic';
import AddCustomQuestion from '@/components/AddCustomQuestion';
import Loading from '@/components/Loading';
import moment from 'moment';
import downloadPDF from '@/functions/downloadPDF.js';
import CreateProjectmodel from '@/components/CreateProjectmodel';

export default {
    data(){
        return {
            tab: null,
            tab_type_q: null,
            loading: false,
            loadingPdf: false,
            loadingQuestions: false,
            loadingQuestionsCSP: false,
            loadingSelectedQuestions: false,
            loadingAutodiag: false,
            loadingAdd: false,
            project: false,
            questions: [],
            categories: [],
            selectedQuestions: [],
            tableoptions: {
                page: 1,
                itemsPerPage: -1,
            },
            headers: [
                {
                    text: 'Num',
                    sortable: false,
                    value: 'num',
                },
                {
                    text: 'Question',
                    sortable: false,
                    value: 'question',
                },
                /*{
                    text: 'Type',
                    sortable: false,
                    value: 'type',
                },*/
                {
                    text: 'Actions',
                    value: 'actions',
                    sortable: false,
                    align: 'end',
                    width: '100px'
                }
            ],
            headersSelected: [
                {
                    text: 'Question',
                    sortable: false,
                    value: 'question.question',
                },
                { text: 'Actions', value: 'actions', sortable: false, }
            ],
            snackbar: false,
            snackbarText: '',
            snackbarColor: 'sucess',
            displaySelectedQuestions: false,
            selectedCategory: 0,
            available_status: [
                "Entretien à préparer",
                "En attente de l'entretien",
                //"Terminé"
            ],
            ref_date_appointment: false,
            date_appointment: '',
            appointment_hour: '',
            appointment_minutes: '',
            edit_date: false,
            available_hours: [
                '07',
                '08',
                '09',
                '10',
                '11',
                '12',
                '13',
                '14',
                '15',
                '16',
                '17',
                '18',
                '19',
            ],
            available_minutes: [
                '00',
                '05',
                '10',
                '15',
                '20',
                '25',
                '30',
                '35',
                '40',
                '45',
                '50',
                '55',
            ],
            selectedAutocategory: 0,
            selectedAutocategoryName: '',
            autocategories: [],
            autoquestions: [],
            autoanswers: [],
            chart1data: [{
                name: 'Score',
                data: [],
            }],
            chart1Options: {
                chart: {
                    type: 'radar',
                    toolbar: {
                        show: false
                    }
                },
                xaxis: {
                    categories: []
                },
                yaxis: {
                    min: 0,
                    max: 10,
                },
                grid: {
                    show: false,
                    xaxis: {
                        lines: {
                            show: false
                        }
                    },
                    yaxis: {
                        lines: {
                            show: false
                        }
                    },
                    row: {
                        colors: undefined,
                        opacity: 0
                    },
                    column: {
                        colors: undefined,
                        opacity: 0
                    },
                    padding: {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0
                    },
                }
            },
            modal_import_model: false,
            projectmodels: [],
            model_selected: false,
            loadingModels: false,
            loadingImportModel: false,
        }
    },
    created() {
        this.getProject();
    },
    methods: {
        getProject() {
            this.loading = true;
            this.$http
                .get(process.env.VUE_APP_API + '/projects/' + this.$route.params.id)
                .then(response => {
                    this.project = response.data.data;
                    if( this.project.employer.date_appointment && this.project.employer.date_appointment.length > 0 ) {
                        this.date_appointment = moment(String(this.project.employer.date_appointment)).format('YYYY-MM-DD');
                        this.appointment_hour = moment(String(this.project.employer.date_appointment)).format('HH');
                        this.appointment_minutes = moment(String(this.project.employer.date_appointment)).format('mm');

                        this.project.employer.date_appointment = moment(String(this.project.employer.date_appointment)).format('DD/MM/YYYY') + ' ' + moment(String(this.project.employer.date_appointment)).format('HH') + 'h' + moment(String(this.project.employer.date_appointment)).format('mm');
                    }
                    this.loading = false;
                    this.getCategories();
                    this.getSelectedQuestions();
                    this.getAutodiag();
                    this.getAutocategories();
                }).catch( () => {
                    this.loading = false;
                });
        },
        getCategories() {
            this.loadingQuestions = true;
            this.$http
                .get(process.env.VUE_APP_API + '/questions')
                .then(response => {
                    this.categories = response.data;
                    this.loadingQuestions = false;
                }).catch( () => {
                    this.loadingQuestions = false;
                });
        },
        getSelectedQuestions() {
            this.loadingSelectedQuestions = true;
            this.$http
                .get(process.env.VUE_APP_API + '/projects/' + this.$route.params.id + '/questions')
                .then(response => {
                    this.selectedQuestions = response.data.data;
                    this.loadingSelectedQuestions = false;
                }).catch( () => {
                    this.loadingSelectedQuestions = false;
                });
        },
        getAutodiag() {
            this.loadingAutodiag = true;
            this.$http
                .get(process.env.VUE_APP_API + '/employers/' + this.project.employer.token + '/results')
                .then(response => {
                    this.chart1data[0].data = response.data.chart_1_points;
                    //this.chart1Options.xaxis.categories = response.data.chart_1_labels;
                    this.chart1Options.xaxis.categories = [
                        'CAPTER valoriser',
                        ['CAPTER', 'Communiqué et attirer'],
                        ['SELECTIONNER', 'Evaluer'],
                        'SELECTIONNER Choisir',
                        ['PERENNISER', 'Accueillir et Intégrer'],
                        ['PERENNISER', 'Former et Fidéliser'],
                    ];
                    this.loadingAutodiag = false;
                }).catch( () => {
                    //this.errorResults = true;
                    this.loadingAutodiag = false;
                });
        },
        getAutocategories() {
            this.loadingQuestionsCSP = true;
            this.$http
                .get(process.env.VUE_APP_API + '/questions-by-csp?project_id=' + this.$route.params.id)
                .then(response => {
                    this.autocategories = response.data;
                    this.loadingQuestionsCSP = false;
                }).catch( () => {
                    this.loadingQuestionsCSP = false;
                });
        },
        selectCategory(category) {
            this.questions = category.questions;
            this.selectedCategory = category.category.id;
        },
        selectAutocategory(autocategory) {
            this.tab_type_q = "tab_type_q_important";
            this.autoquestions = autocategory.questions;
            this.autoanswers = autocategory.answers;
            this.selectedAutocategory = autocategory.autocategory.id;
            this.selectedAutocategoryName = autocategory.autocategory.name;
        },
        addQuestion(item) {
            this.loadingAdd = true;
            this.loadingSelectedQuestions = true;
            this.$http
                .post(process.env.VUE_APP_API + '/projects/' + this.$route.params.id + '/questions', {
                    question_id: item.id
                })
                .then( () => {
                    this.getSelectedQuestions(); // reload selected questions
                    this.loadingAdd = false;
                    this.snackbarText = "Question enregistrée";
                    this.snackbarColor = 'success';
                    this.snackbar = true;
                }).catch( () => {
                    this.loadingAdd = false;
                    this.loadingSelectedQuestions = false;
                });
        },
        addCustomQuestion(item) {
            this.loadingAdd = true;
            this.loadingSelectedQuestions = true;
            this.$http
                .post(process.env.VUE_APP_API + '/projects/' + this.$route.params.id + '/customquestions', {
                    customquestion_id: item.id
                })
                .then( () => {
                    this.getSelectedQuestions(); // reload selected questions
                    this.loadingAdd = false;
                    this.snackbarText = "Question enregistrée";
                    this.snackbarColor = 'success';
                    this.snackbar = true;
                }).catch( () => {
                    this.loadingAdd = false;
                    this.loadingSelectedQuestions = false;
                });
        },
        removeQuestion(item) {
            this.loadingSelectedQuestions = true;
            this.$http
                .delete(process.env.VUE_APP_API + '/projects/' + this.$route.params.id + '/questions', {
                    data: {
                        selectedquestion_id: item.id
                    }
                })
                .then( () => {
                    this.getSelectedQuestions(); // reload selected questions
                    // display notification success
                    this.snackbarText = "Question retirée";
                    this.snackbarColor = 'success';
                    this.snackbar = true;
                }).catch( () => {
                    this.loadingSelectedQuestions = false;
                });
        },
        saveOrder() {
            // build array to send
            var array_questions = [];
            this.selectedQuestions.forEach((q) => {
                array_questions.push(q.id);
            });

            // send positions to server
            this.$http
                .post(process.env.VUE_APP_API + '/projects/' + this.$route.params.id + '/questions/order', {
                    questions: array_questions
                })
                .then( () => {
                    // display notification success
                    this.snackbarText = "Ordre des questions enregistré";
                    this.snackbarColor = 'success';
                    this.snackbar = true;
                }).catch( () => {
                    // display notification error
                    this.snackbarText = "L'ordre des questions n'a pas pû être enregistré";
                    this.snackbarColor = 'error';
                    this.snackbar = true;
                });
        },
        saveDate() {
            // prepare full date
            var clean_date = this.date_appointment+' '+this.appointment_hour+':'+this.appointment_minutes;
            // send date to server
            this.$http
                .post(process.env.VUE_APP_API + '/projects/' + this.$route.params.id + '/questions/appointment', {
                    date_appointment: clean_date
                })
                .then( () => {
                    // show correct date
                    this.project.employer.date_appointment = moment(String(this.date_appointment)).format('DD/MM/YYYY') + ' ' + this.appointment_hour + 'h' + this.appointment_minutes;
                    // display notification success
                    this.snackbarText = "Date d'entretien enregistrée";
                    this.snackbarColor = 'success';
                    this.snackbar = true;
                    this.edit_date = false;
                }).catch( () => {
                    // display notification error
                    this.snackbarText = "La date d'entretien n'a pas pû être enregistrée";
                    this.snackbarColor = 'error';
                    this.snackbar = true;
                });
        },
        saveStatus() {
            // send date to server
            this.$http
                .post(process.env.VUE_APP_API + '/projects/' + this.$route.params.id + '/questions/status', {
                    status: this.project.status
                })
                .then( () => {
                    // display notification success
                    this.snackbarText = "Statut enregistré";
                    this.snackbarColor = 'success';
                    this.snackbar = true;
                }).catch( () => {
                    // display notification error
                    this.snackbarText = "Le statut n'a pas pû être enregistré";
                    this.snackbarColor = 'error';
                    this.snackbar = true;
                });
        },
        questionSelected(question_displayed) {
            let is_in = false;
            this.selectedQuestions.forEach((q) => {
                if( q.question && q.question.id && q.question.id == question_displayed.id ) {
                    is_in = true;
                }
            });
            return is_in;
        },
        downloadQuestions() {
            this.loadingPdf = true;
            this.$http
                .post(process.env.VUE_APP_API + '/projects/' + this.project.id + '/print', {}, { responseType: 'arraybuffer'} )
                .then(response => {
                    downloadPDF(response.data, 'entretien');
                    this.loadingPdf = false;
                }).catch( () => {
                    //console.log(error);
                    this.loadingPdf = false;
                });
        },
        getFieldText(item) {
            return `${item.name} (${item.modelquestions_count} questions)`;
        },
        display_import_modal() {
            this.loadingModels = true;
            this.modal_import_model = true;
            this.$http
                .get(process.env.VUE_APP_API + '/projectmodels')
                .then(response => {
                    this.projectmodels = response.data.data;
                    this.loadingModels = false;
                }).catch( () => {
                    //console.log(error);
                    this.loadingModels = false;
                });
        },
        import_model() {
            this.loadingImportModel = true;
            this.$http
                .post(process.env.VUE_APP_API + '/projects/' + this.project.id + '/importModel', {
                    projectmodel_id: this.model_selected.id
                })
                .then( () => {
                    this.getSelectedQuestions(); // reload selected questions
                    this.snackbarText = "Modèle importé";
                    this.snackbarColor = 'success';
                    this.snackbar = true;
                    this.loadingImportModel = false;
                    this.modal_import_model = false;
                }).catch( () => {
                    //console.log(error);
                    this.loadingImportModel = false;
                });
        },
        displayNotif() {
            this.snackbarText = "Modèle créé avec succès";
            this.snackbarColor = 'success';
            this.snackbar = true;
        }
    },
    components: {
        draggable,
        Autodiagnostic,
        AddCustomQuestion,
        Loading,
        CreateProjectmodel
    }
}
</script>
