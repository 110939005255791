<template>
    <div class="interview">

        <div v-if="loading" class="pt-4">
            <Loading />
        </div>
        <v-container v-else fluid>
            <div class="topbar">
                <div class="topbar-title">
                    <span class="topbar-title-span">
                        Entretien : {{ project.employer.company }}
                    </span>
                </div>
            </div>
            <div class="interview-review">
                <v-row align="center" justify="center">
                    <v-col cols="12" lg="8" md="10">
                        <v-card class="mb-5" min-width="100%">
                            <v-stepper value="3">
                                <v-stepper-header>
                                    <v-stepper-step step="1" complete>Entretien<br>enregistré</v-stepper-step>
                                    <v-divider></v-divider>
                                    <v-stepper-step step="2" complete>Positionnement<br>CSP</v-stepper-step>
                                    <v-divider></v-divider>
                                    <v-stepper-step step="3">Positionnement<br>CPE</v-stepper-step>
                                    <v-divider></v-divider>
                                    <v-stepper-step step="4">Pistes d'actions<br>et échéances</v-stepper-step>
                                    <v-divider></v-divider>
                                    <v-stepper-step step="5">Évaluation</v-stepper-step>
                                </v-stepper-header>
                            </v-stepper>
                            <v-card-text class="text-center" v-if="scores">
                                <div class="mb-4">
                                    <h3 class="pt-3">
                                        Après cet entretien dans quelle mesure et à quoi l'employeur attribue t-il ses difficultés de recrutement sur Poste / Candidat / Entreprise (processus de recrutement)<br>
                                        (Plus la flèche est vers le rouge, plus cet axe est important dans la difficulté de recrutement)
                                    </h3>
                                </div>
                                <div>
                                    <div v-html="scores"></div>
                                    <!--<div class="pt-4">
                                        <em><u>Légende :</u> En orange le résultat de l'auto-diagnostic.</em>
                                    </div>-->
                                </div>
                                <!--<div class="text-left">
                                    <h3 class="mb-3 mt-5 pt-4">Pistes d’actions Pôle Emploi & Pistes d’actions Entreprise :</h3>
                                    <v-textarea
                                        solo
                                        label=""
                                        v-model="project.bilan2"
                                    ></v-textarea>
                                </div>-->
                            </v-card-text>
                            <v-card-text class="text-right">
                                <!--<v-btn text small :to="{ name: 'interview.slider2', params: {id: project.id} }">Retour</v-btn> <v-btn text small color="primary" @click="saveReview">Suivant</v-btn>-->
                                <v-btn text small :to="{ name: 'interview.slider2', params: {id: project.id} }">Retour</v-btn> <v-btn text small color="primary" :to="{ name: 'interview.dates', params: {id: project.id} }">Suivant</v-btn>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </div>
        </v-container>
    </div>
</template>

<script>
import Loading from '@/components/Loading';
export default {
    data(){
        return {
            loading: false,
            loadingQuestions: false,
            project: false,
            scores: false,
        }
    },
    created() {
        this.getProject();
    },
    methods: {
        getProject() {
            this.loading = true;
            this.$http
                .get(process.env.VUE_APP_API + '/projects/' + this.$route.params.id)
                .then(response => {
                    this.project = response.data.data;
                    this.getEmployerScore();
                    this.loading = false;
                }).catch( () => {
                    this.loading = false;
                });
        },
        getEmployerScore() {
            this.$http
                .get(process.env.VUE_APP_API + '/projects/' + this.$route.params.id + '/cpecomparison')
                .then(response => {
                    this.scores = response.data;
                    this.loading = false;
                }).catch( () => {
                    this.loading = false;
                });
        },
        /*saveReview() {

            this.$http
                .post(process.env.VUE_APP_API + '/projects/' + this.$route.params.id + '/review2', {
                    bilan2: this.project.bilan2
                })
                .then( () => {
                    // redirect to next screen
                    this.$router.push({
                        name: 'interview.dates',
                        params: {
                            id: this.$route.params.id,
                        }
                    });

                    this.loading = false;
                }).catch( () => {
                    this.loading = false;
                });
        }*/
    },
    components: {
        Loading
    }
}
</script>
