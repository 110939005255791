<template>
    <div>
        <div v-if="q.answered">
            <div class="interview-questions-title">
                <span class="interview-questions-title-number" :style="'background-color:'+q.question.category.color">{{ q.question.num }}</span> <span class="interview-questions-title-question" v-html="q.question.question"></span>
            </div>
            <div>
                <v-textarea
                    solo
                    name="input-7-4"
                    label="Saisissez la réponse"
                    v-model="q.answer"
                    @keydown="answerEdited(q)"
                ></v-textarea>
                <template v-if="! q.saving">
                    <a href="#" class="interview-questions-reinit" @click.prevent="$emit('reinit', q);">
                        Réinitialiser
                    </a>
                </template>
            </div>
        </div>
        <div v-else>
            <div class="mb-3">
                <div class="interview-questions-title">
                    <span class="interview-questions-title-number" :style="'background-color:'+q.question.category.color">{{ q.question.num }}</span> <span class="interview-questions-title-question">
                        1) Concernant la rédaction d’une offre d’emploi, l'employeur dit-il 
                    </span>
                </div>
                <div>
                    <label class="mr-3">
                        <input type="radio" @change="displayNext" v-model="reponse1" :value="'être l’aise pour rédiger une offre'"> être l’aise pour rédiger une offre
                    </label>
                    <label>
                        <input type="radio" @change="displayNext" v-model="reponse1" :value="'avoir besoin d’un appui'"> avoir besoin d’un appui
                    </label>
                </div>
            </div>
            <div v-if="step == 2" class="mb-3">
                <div class="interview-questions-title">
                    <span class="interview-questions-title-question">
                        2) Un appui de quel ordre :
                    </span>
                </div>
                <div>
                    <label>
                        <input type="checkbox" @change="answerEdited(q)" v-model="reponse2" value="rédiger la description du poste et les conditions de travail"> rédiger la description du poste et les conditions de travail
                    </label><br>
                    <label>
                        <input type="checkbox" @change="answerEdited(q)" v-model="reponse2" value="décrire l’entreprise et son attractivité"> décrire l’entreprise et son attractivité
                    </label><br>
                    <label>
                        <input type="checkbox" @change="answerEdited(q)" v-model="reponse2" value="définir les profils recherchés"> définir les profils recherchés
                    </label><br>
                    <label>
                        <input type="checkbox" @change="answerEdited(q)" v-model="reponse2" value="simplement gagner du temps"> simplement gagner du temps
                    </label>
                </div>
            </div>
        </div>
        <div v-if="step == 2 || step == 3 || q.answered">
            <v-btn :loading="q.saving" :disabled="! q.edited || q.saving" color="primary" @click="saveAnswer(q)">Enregistrer</v-btn>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            step: 1,
            reponse1: false,
            reponse2: [],
        }
    },
    methods: {
        saveAnswer(question) {
            question.comment = {
                step1: this.reponse1,
                options: this.reponse2
            };
            this.$emit('saved', question);
            // clean data
            question.comment = '';
            this.reponse1 = '';
            this.reponse2 = [];
            this.step = 1;
        },
        answerEdited(question) {
            if( ! question.edited ) {
                question.edited = true;
            }
            this.$emit('editing', question);
        },
        displayNext() {
            if( this.reponse1 == 'avoir besoin d’un appui' ) {
                this.step = 2;
            } else {
                this.step = 3;
            }
            this.q.edited = true;
        }
    },
    props: [
        'q',
    ],
}
</script>