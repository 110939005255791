<template>
    <div class="interview">

        <div v-if="loading" class="pt-4">
            <Loading />
        </div>
        <v-container v-else fluid>
            <div class="topbar">
                <div class="topbar-title">
                    <span class="topbar-title-span">
                        Entretien : {{ project.employer.company }}
                    </span>
                </div>
            </div>
            <div class="interview-sliders">
                <v-row align="center" justify="center">
                    <v-col cols="12" lg="8" md="10">
                        <v-card class="mb-5" min-width="100%">
                            <v-stepper value="3">
                                <v-stepper-header>
                                    <v-stepper-step step="1" complete>Entretien<br>enregistré</v-stepper-step>
                                    <v-divider></v-divider>
                                    <v-stepper-step step="2" complete>Positionnement<br>CSP</v-stepper-step>
                                    <v-divider></v-divider>
                                    <v-stepper-step step="3">Positionnement<br>CPE</v-stepper-step>
                                    <v-divider></v-divider>
                                    <v-stepper-step step="4">Pistes d'actions<br>et échéances</v-stepper-step>
                                    <v-divider></v-divider>
                                    <v-stepper-step step="5">Évaluation</v-stepper-step>
                                </v-stepper-header>
                            </v-stepper>
                            <v-card-text class="text-center">
                                <div>
                                    <h3 class="pt-3 pb-4">
                                        Après cet entretien dans quelle mesure et à quoi l'employeur attribue t-il ses difficultés de recrutement sur Poste / Candidat / Entreprise (processus de recrutement)
                                    </h3>
                                </div>
                                <div v-for="s in scores" :key="'score_'+s.autocategory.id" class="pt-4">
                                    <v-row>
                                        <v-col class="text-left" cols="3">
                                            <!--<template v-if="s.autocategory.name === 'ENTREPRISE'">
                                                PROCESSUS DE RECRUTEMENT
                                            </template>
                                            <template v-else>-->
                                                {{ s.autocategory.name }}
                                            <!-- </template> -->
                                        </v-col>
                                        <v-col>
                                            <v-slider
                                                v-model="s.scale_points"
                                                class="align-center interview-sliders-slider mb-5"
                                                :max="10"
                                                :min="0"
                                                hide-details
                                                thumb-label="always"
                                            >
                                                <template v-slot:append>
                                                    <div class="interview-sliders-autodiag" :style="'left:' + s.percent_points + '%'">
                                                        auto-diagnostic
                                                    </div>
                                                </template>
                                            </v-slider>
                                        </v-col>
                                    </v-row>
                                </div>
                                
                            </v-card-text>
                            <v-card-text class="text-right">
                                <v-btn text small :to="{ name: 'interview.review', params: {id: project.id} }">Retour</v-btn> <v-btn text small color="primary" @click="saveScore">Suivant</v-btn>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </div>
        </v-container>
    </div>
</template>

<script>
import Loading from '@/components/Loading';
export default {
    data(){
        return {
            loading: false,
            loadingQuestions: false,
            project: false,
            scores: false,
        }
    },
    created() {
        this.getProject();
    },
    methods: {
        getProject() {
            this.loading = true;
            this.$http
                .get(process.env.VUE_APP_API + '/projects/' + this.$route.params.id)
                .then(response => {
                    this.project = response.data.data;
                    this.getEmployerAutoDiag();
                    this.loading = false;
                }).catch( () => {
                    this.loading = false;
                });
        },
        getEmployerAutoDiag() {
            this.$http
                .get(process.env.VUE_APP_API + '/employers/' + this.project.employer.id + '/cpescrores')
                .then(response => {
                    this.scores = response.data;
                    this.loading = false;
                }).catch( () => {
                    this.loading = false;
                });
        },
        saveScore() {
            // clean array to send scores
            let keys = Object.keys(this.scores);
            var clean_array = [];
            keys.forEach(key => {
                let item = this.scores[key];
                clean_array.push({
                    id: item.autocategory.id,
                    score: item.scale_points
                });
            });
            
            // send scores
            this.$http
                .post(process.env.VUE_APP_API + '/projects/' + this.$route.params.id + '/cpescrores', {
                    scores: clean_array
                })
                .then( () => {
                    // redirect to next screen
                    // :to="{ name: 'interview', params: {id: project.id}, query: {validate: 1} }"
                    this.$router.push({
                        name: 'interview.review2',
                        params: {
                            id: this.$route.params.id,
                        }
                    });

                    this.loading = false;
                }).catch( () => {
                    this.loading = false;
                });


            
        }
    },
    components: {
        Loading
    }
}
</script>