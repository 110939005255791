<template>
    <div class="interviewedit">

        <v-snackbar
            v-model="snackbar"
            :color="snackbarColor"
        >
            {{ snackbarText }}

            <template v-slot:action="{ attrs }">
                <v-btn
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    Fermer
                </v-btn>
            </template>
        </v-snackbar>

        <div v-if="loading" class="pt-4">
            <Loading />
        </div>
        <v-container v-else fluid class="pb-5">
            <div class="topbar">
                <div class="topbar-title">
                    <span class="topbar-title-span">
                        <v-btn small class="mr-2" :to="{ name: 'interviews.see', params: {id: project.id }}">Retour</v-btn> Rapport de l'entretien de : {{ project.employer.company }}
                    </span>
                </div>
                <div class="topbar-actions">
                    <template v-if="loadingPdf">
                        <v-btn class="mr-2" small disabled>Génération en cours...</v-btn>
                    </template>
                    <template v-else>
                        <v-btn class="mr-2" small @click="downloadReport"><v-icon>mdi-download</v-icon> Générer le rapport</v-btn>
                    </template>
                </div>
            </div>
            <div class="row pb-5">
                <div class="col-12" style="max-width: 820px; margin: auto;">
                    <v-card>
                        <v-card-text>
                            <h3 class="orange--text text-uppercase text-center mb-3 pt-4">
                                <strong>
                                    Questions et réponses
                                </strong>
                            </h3>
                            <v-textarea
                                rows="10"
                                solo
                                label=""
                                v-model="project.theme1"
                                class="mb-5"
                                @keydown="reportChanged"
                            ></v-textarea>

                            <h3 class="orange--text text-uppercase text-center mb-3 pt-4">
                              <strong>
                                Votre rapport
                              </strong>
                            </h3>
                            <v-textarea
                                rows="10"
                                solo
                                label=""
                                v-model="project.theme2"
                                class="mb-5"
                                @keydown="reportChanged"
                            ></v-textarea>

                            <h3 class="orange--text text-uppercase text-center mb-3">
                                <strong>
                                    Où vous situez vous sur les axes Capter, Sélectionner, Pérenniser
                                </strong>
                            </h3>
                            <div ref="chart1" id="chart1" v-if="scores" class="mb-5">
                                <apexchart height="" type="radar" :options="chart1Options" :series="chart1data"></apexchart>
                            </div>

                            <h3 class="orange--text text-uppercase text-center mb-3">
                                <strong>
                                    À quel niveau situez vous vos difficultés de recrutement pour chaque cause suivante : Poste / Candidat / Entreprise (processus de recrutement)
                                </strong>
                            </h3>
                            <div v-if="scores_cpe" v-html="scores_cpe" class=""></div>

                            <h3 class="orange--text text-uppercase text-center mb-3 pt-5 mt-5">
                                <strong>
                                    Pistes d’actions du conseiller & Pistes d’actions Entreprise :
                                </strong>
                            </h3>
                            <v-textarea
                                rows="10"
                                solo
                                label=""
                                v-model="project.bilan"
                                class="mb-5"
                                @keydown="reportChanged"
                            ></v-textarea>

                            <h3 class="orange--text text-uppercase text-center mb-3">
                                <strong>
                                    Dates d'échéance
                                </strong>
                            </h3>
                            <!--<v-textarea
                                rows="10"
                                solo
                                label=""
                                v-model="project.next_step"
                                class="mb-5"
                            ></v-textarea> -->
                            <v-menu
                                ref="menu_1"
                                v-model="menu_1"
                                :close-on-content-click="false"
                                :return-value.sync="project.date_next_step_1"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="project.date_next_step_1"
                                        label="Choisissez la date d'échéance"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="project.date_next_step_1" no-title scrollable locale="fr" first-day-of-week="1">
                                    <v-spacer></v-spacer>
                                    <v-btn text color="primary" @click="menu_1 = false">Cancel</v-btn>
                                    <v-btn text color="primary" @click="$refs.menu_1.save(project.date_next_step_1); reportChanged();">OK</v-btn>
                                </v-date-picker>
                            </v-menu>


                            <div class="text-center pb-4">
                                <v-btn color="primary" dark @click.prevent="saveReport" :loading="loadingSave">
                                    Enregistrer
                                </v-btn>
                            </div>
                        </v-card-text>
                    </v-card>
                </div>
            </div>

            <div class="selectedQuestions" v-if="modalNote">
                <div class="selectedQuestions-hover" @click="modalNote = false"></div>

                <div class="selectedQuestions-content">
                    <h3 class="selectedQuestions-content-header yellow darken-2 pa-3" @click="modalNote = false">
                        <v-icon color="black">mdi-arrow-bottom-right</v-icon> Notes personnelles
                    </h3>

                    <div class="pa-5" style="cursor: auto;">
                        <p style="white-space: pre-line;" v-if="project.note && project.note.length > 0">
                            {{ project.note }}
                        </p>
                        <p v-else>
                            <em>Aucunes notes personnelles.</em>
                        </p>
                        <p class="text-center">
                            <v-btn class="mb-1" color="darken-1" text @click.prevent="modalNote = false">Fermer</v-btn>
                        </p>
                    </div>
                </div>
            </div>
            <div v-else class="selectedQuestionsTag" @click="modalNote = true">
                <div class="yellow darken-2 pa-3">
                    <v-icon color="black">mdi-arrow-top-left</v-icon> Notes personnelles
                </div>
            </div>
        </v-container>
    </div>
</template>

<script>
//import moment from 'moment';
import JQuery from 'jquery';
let $ = JQuery;
import downloadPDF from '@/functions/downloadPDF.js';
import Loading from '@/components/Loading';

export default {
    data(){
        return {
            loading: false,
            loadingSave: false,
            project: false,
            snackbar: false,
            snackbarText: '',
            menu_1: false,
            snackbarColor: 'sucess',
            modalNote: false,
            chart1data: [
                {
                    //name: 'Auto-diagnostic',
                    data: [],
                }/*,
                {
                    name: 'Entretien',
                    data: [],
                },*/
            ],
            chart1Options: {
                chart: {
                    type: 'radar',
                    toolbar: {
                        show: false
                    }
                },
                xaxis: {
                    categories: []
                },
                yaxis: {
                    min: 0,
                    max: 10,
                },
                grid: {
                    show: false,
                    xaxis: {
                        lines: {
                            show: false
                        }
                    },
                    yaxis: {
                        lines: {
                            show: false
                        }
                    },
                    row: {
                        colors: undefined,
                        opacity: 0
                    },
                    column: {
                        colors: undefined,
                        opacity: 0
                    },
                    padding: {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0
                    },
                }
            },
            scores: false,
            scores_cpe: false,
            loadingPdf: false,
            data_saved: true,
            reportQuestions: [],
        }
    },
    created() {
        this.getProject();
    },
    methods: {
        reportChanged() {
            this.data_saved = false;
        },
        getProject() {
            this.loading = true;
            this.$http
                .get(process.env.VUE_APP_API + '/projects/' + this.$route.params.id)
                .then(response => {
                    this.project = response.data.data;
                    this.getEmployerScore();
                    this.getReportQuestions(this.$route.params.id);

                    this.loading = false;
                }).catch( () => {
                    this.loading = false;
                });
        },
        getEmployerScore() {
            this.$http
                .get(process.env.VUE_APP_API + '/projects/' + this.$route.params.id + '/cspcomparison')
                .then(response => {
                    /*this.chart1data[0].data = response.data.chart_1_points;
                    this.chart1data[1].data = response.data.chart_1_points_pe;*/
                    this.chart1data[0].data = response.data.chart_1_points_pe;
                    this.chart1Options.xaxis.categories = response.data.chart_1_labels;
                    this.scores = response.data;
                    this.loading = false;
                }).catch( () => {
                    this.loading = false;
                });

            this.$http
                .get(process.env.VUE_APP_API + '/projects/' + this.$route.params.id + '/cpecomparison')
                .then(response => {
                    this.scores_cpe = response.data;
                    this.loading = false;
                }).catch( () => {
                    this.loading = false;
                });
        },
        getReportQuestions(id) {

            this.$http
                .get(process.env.VUE_APP_API + '/projects/' + id + '/questions')
                .then(response => {
                  this.reportQuestions = response.data.data;

                  if (this.project.theme1 === null || this.project.theme1 === '') {
                    let formattedReportQuestions = '';
                    this.reportQuestions.map((question) => {

                      if (typeof question.question !== 'undefined')  {
                        formattedReportQuestions += this.br2nl(question.question.question) + '\n\n';
                      }
                      else if (typeof question.customquestion !== 'undefined')  {
                        formattedReportQuestions += this.br2nl(question.customquestion.question) + '\n\n';
                      }

                      if (question.question.type === 'slider') {
                        formattedReportQuestions += 'Réponse : ' + '\n' + question.answer + '\n' + 'Commentaire : ' + question.comment + '\n\n\n\n';
                      } else if (question.question.type === 'number') {
                        formattedReportQuestions += 'Réponse : ' + '\n' + question.answer + '\n' + 'Commentaire : ' + question.comment + '\n\n\n\n';
                      } else {
                        formattedReportQuestions += 'Réponse : ' + '\n' + question.answer + '\n\n\n\n';
                      }

                    });

                    this.project.theme1 = formattedReportQuestions;
                  }
                }).catch( () => {
                  this.loading = false;
                });
        },
        downloadReport() {
            var chart1 = $("#chart1").find('svg')[0].outerHTML;
            this.loadingPdf = true;
            this.$http
                .post(process.env.VUE_APP_API + '/projects/' + this.project.id + '/report', {
                    chart1: chart1,
                }, { responseType: 'arraybuffer'} )
                .then(response => {
                    downloadPDF(response.data, 'rapport');
                    this.loadingPdf = false;
                }).catch( () => {
                    //console.log(error);
                    this.loadingPdf = false;
                });
        },
        saveReport() {
            this.loadingSave = true;
            this.$http
                .post(process.env.VUE_APP_API + '/projects/' + this.$route.params.id + '/full', {
                    theme1: this.project.theme1,
                    theme2: this.project.theme2,
                    theme3: this.project.theme3,
                    theme4: this.project.theme4,
                    theme5: this.project.theme5,
                    bilan: this.project.bilan,
                    next_step: this.project.next_step,
                    date_next_step_1: this.project.date_next_step_1,
                })
                .then( () => {
                    this.snackbarText = "Rapport enregistré";
                    this.snackbarColor = 'success';
                    this.snackbar = true;

                    this.data_saved = true;

                    this.loadingSave = false;
                }).catch( () => {
                    this.loadingSave = false;
                });
        },
        preventNav(event) {
            if (this.data_saved) return;
            event.preventDefault();
            event.returnValue = "";
        },

      br2nl(str) {
          return str.replaceAll('<br>', '');
      }
    },
    beforeRouteLeave (to, from, next) {
        if( ! this.data_saved ) {
            const answer = window.confirm("Vous n'avez pas enregistré. Êtes-vous sûr de vouloir quitter la page sans enregistrer ?");
            if (answer) {
                next();
            } else {
                next(false);
            }
        } else {
            next();
        }
    },
    beforeMount() {
        window.addEventListener("beforeunload", this.preventNav)
        this.$once("hook:beforeDestroy", () => {
            window.removeEventListener("beforeunload", this.preventNav);
        });
    },
    components: {
        Loading
    }
}
</script>
