<template>
    <div class="d-inline-block">
        <template v-if="employers && employers.length > 0">
            <v-btn
                color="warning"
                class="mr-2 mb-1 mt-1"
                small
                outlined
                dark
                @click.prevent="dialog_employers = true"
            >Auto-diagnostics <v-icon color="orange" small class="ml-1">mdi-link</v-icon></v-btn>
        </template>
        <template v-else>
            <v-btn
                color="warning"
                class="mr-2 mb-1 mt-1"
                small
                outlined
                dark
                @click.prevent="diplay_autodiagnostic(employer.token, employer.company)"
            >Auto-diagnostic</v-btn>
        </template>

        <v-dialog v-model="dialog_employers" width="750px">
            <v-card>
                <v-card-text class="text-center pt-5">
                    <h3 class="pb-4">
                        Cet entretien est lié à plusieurs auto-diagnostics, lequel voulez-vous consulter ?
                    </h3>
                    <v-btn
                        color="warning"
                        class="mr-2 mb-1 mt-1"
                        small
                        outlined
                        dark
                        @click.prevent="diplay_autodiagnostic(employer.token, employer.company)"
                    >{{ employer.company }}</v-btn>

                    <div v-for="emp in employers" :key="'autodiag_e_'+emp.id" class="d-inline-block">
                        <v-btn
                            color="warning"
                            class="mr-2 mb-1 mt-1"
                            small
                            outlined
                            dark
                            @click.prevent="diplay_autodiagnostic(emp.token, emp.company)"
                        >{{ emp.company }}</v-btn>
                    </div>
                </v-card-text>
                <v-card-text class="text-center">
                    <h3 class="pb-4">
                        Souhaitez-vous comparer les graphiques des auto-diags ?
                    </h3>
                    <v-btn
                        color="warning"
                        small
                        outlined
                        dark
                        @click.prevent="compareCharts"
                    >Comparer les graphiques</v-btn>
                </v-card-text>
                <!--<v-card-text class="text-center">
                    <h3 class="pb-4">
                        Vous pouvez délier les auto-diagnostics :
                    </h3>
                    <template v-if="loadingUnlink">
                        <Loading />
                    </template>
                    <v-simple-table v-else>
                        <template v-slot:default>
                        <tbody>
                        <tr v-for="emp in employers" :key="'autodiag_e_unlik_'+emp.id" class="d-inline-block">
                            <td>
                                {{ emp.company }}
                            </td>
                            <td>
                                <v-btn
                                    color="warning"
                                    class="mr-2 mb-1 mt-1"
                                    small
                                    outlined
                                    dark
                                    @click.prevent="unlinkDiag(emp)"
                                >Délier</v-btn>
                            </td>
                        </tr>
                        </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>-->
                <v-card-actions>
                <v-spacer></v-spacer>
                    <v-btn color="darken-1" text @click="dialog_employers = false">Fermer</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>


        <v-dialog v-model="dialog" width="750px">
            <v-card>
                <div v-if="employers && employers.length > 0" class="pa-5 pb-0">
                    <v-btn
                        color="warning"
                        class="mr-2 mb-1 mt-1"
                        small
                        :outlined="current_token != employer.token"
                        dark
                        @click.prevent="diplay_autodiagnostic(employer.token, employer.company)"
                    >{{ employer.company }}</v-btn>

                    <div v-for="emp in employers" :key="'autodiag_e_'+emp.id" class="d-inline-block">
                        <v-btn
                            color="warning"
                            class="mr-2 mb-1 mt-1"
                            small
                            :outlined="current_token != emp.token"
                            dark
                            @click.prevent="diplay_autodiagnostic(emp.token, emp.company)"
                        >{{ emp.company }}</v-btn>
                    </div>
                </div>


                <v-card-text v-if="loadingAutodiag">
                    <Loading />
                </v-card-text>
                <template v-else>

                    <v-card-title>
                        <span class="headline mr-2">Auto-diagnostic de {{ current_company }}</span> <v-btn small @click="downloadAutodiag(employer.token)" :disabled="loadingPdf" :loading="loadingPdf"><v-icon>mdi-download</v-icon> Télécharger</v-btn>
                    </v-card-title>
                    <v-card-text>




                        <div>
                            <h4>Votre contexte</h4>
                        </div>
                        <div class="mb-5">
                            <p v-html="results.context"></p>
                        </div>

                        <div>
                            <h4>
                                Les étapes du recrutement et les difficultés rencontrées
                            </h4>
                        </div>
                        <div>
                            <p>
                                En synthèse de vos réponses, un « radar » restitue graphiquement le visuel du recrutement en 3 étapes :
                            </p>
                            <p>
                                <ul>
                                    <li>
                                        communiquer et /ou valoriser le poste pour <strong>« capter »</strong> des candidats
                                    </li>
                                    <li>
                                        évaluer et /ou choisir pour <strong>« sélectionner »</strong> les bons candidats
                                    </li>
                                    <li>
                                        intégrer et /ou fidéliser pour <strong>« pérenniser »</strong> aux postes vos nouveaux collaborateurs
                                    </li>
                                </ul>
                            </p>
                        </div>

                        <div ref="chart1" id="chart1" style="margin-top:-70px">
                            <apexchart height="500" type="radar" :options="chart1Options" :series="chart1data"></apexchart>
                        </div>

                        <div style="margin-top:-40px">
                            <p>
                                <em><u>Légende :</u> Chacune des 6 sections qui les composent est représentée en axes gradués de 0 à 10. Plus le point est élevé (exemple 9/10) moins il y a de difficulté sur l’axe concerné, moins il est élevé plus la difficulté est forte.</em>
                            </p>
                        </div>

                        <div>
                            <h4>
                                Les causes des difficultés rencontrées
                            </h4>
                        </div>
                        <div>
                            <p>
                                En synthèse de vos réponses, un « histogramme » distingue et précise les 3 causes possibles de votre difficulté de recrutement :
                            </p>
                            <p>
                                <ul>
                                    <li>
                                        liée à un problème de <strong>« candidats »</strong> (par exemple de pénurie)
                                    </li>
                                    <li>
                                        liée à un problème de <strong>« poste »</strong> (par exemple en raison des conditions de travail)
                                    </li>
                                    <li>
                                        liée à un problème de <strong>« processus de recrutement »</strong> (par exemple l’entretien de recrutement)
                                    </li>
                                </ul>
                            </p>
                            <p>
                                Ici aussi, une ou plusieurs de ces 3 causes peuvent interagir. Qu’en est-il précisément pour vous et sur quelle(s) cause(s) principale(s) devez-vous porter votre attention ?
                            </p>
                        </div>

                        <div class="text-center mb-3">
                            <div v-html="results.chart_cpe"></div>
                        </div>

                        <div class="mb-5">
                            <p>
                                <em><u>Légende :</u> Chacune des 3 causes sont graduées sur un axe allant du vert au rouge. Plus la position sur cet axe est proche du rouge, plus cette cause est importante dans votre problématique.</em>
                            </p>
                        </div>

                        <div>
                            <h4>
                                Besoins et perspectives / Pour aller plus loin
                            </h4>
                        </div>
                        <div class="mb-4">
                            <p>
                                Vos préoccupations majeures en lien avec votre souhait d’approfondir le sujet, portent principalement sur :
                            </p>
                        </div>
                        <div class="mb-4">
                            <div v-html="results.chart_knowmore"></div>
                        </div>
                        <div class="mb-5">
                            <p>
                                <em>Ce résultat est issu de vos demandes « Souhaitez-vous en échanger avec un conseiller » cochées tout au long des questions.</em>
                            </p>
                            <p>
                              Selon les thèmes impactés il conviendra d’identifier si la nature et l’intensité de vos besoins sont du ressort de France Travail et de ses partenaires, ou de partenaires externes ou de vos propres ressources internes.
                            </p>
                        </div>

                        <template v-if="results.answers">
                            <div class="mb-2">
                                <h4>
                                    Réponses aux questions
                                </h4>
                            </div>
                            <div v-for="ans in results.answers" :key="ans.question">
                                <div>
                                    <strong>
                                        {{ ans.question }}
                                    </strong>
                                </div>
                                <div class="mb-3">
                                    {{ ans.answer }}<br>
                                    <span v-if="ans.display_know_more === true">En savoir plus : {{ ans.knowmore }}</span>
                                </div>
                            </div>
                        </template>


                    </v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                        <v-btn color="darken-1" text @click="dialog = false">Fermer</v-btn>
                    </v-card-actions>
                </template>
            </v-card>
        </v-dialog>


        <v-dialog v-model="display_chart_comparaison" width="850px">
            <v-card>
                <v-card-text>
                    <div class="mb-5 pt-5">
                        <apexchart height="" type="radar" :options="chart2Options" :series="chart2data"></apexchart>
                    </div>
                    <div v-if="chartCPE_html" class="mb-5">
                        <div v-html="chartCPE_html"></div>
                    </div>
                    <div class="text-center">
                        <v-btn class="mb-1" color="darken-1" text @click="display_chart_comparaison = false">Fermer</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

    </div>
</template>

<script>
import downloadPDF from '@/functions/downloadPDF.js';
import Loading from '@/components/Loading';

export default {
    data() {
        return {
            loadingAutodiag: false,
            loadingPdf: false,
            loadingUnlink: false,
            dialog: false,
            dialog_employers: false,
            results: [],
            chart1data: [{
                name: 'Score',
                data: [],
            }],
            chart1Options: {
                chart: {
                    type: 'radar',
                    toolbar: {
                        show: false
                    }
                },
                xaxis: {
                    categories: []
                },
                yaxis: {
                    min: 0,
                    max: 10,
                },
                grid: {
                    show: false,
                    xaxis: {
                        lines: {
                            show: false
                        }
                    },
                    yaxis: {
                        lines: {
                            show: false
                        }
                    },
                    row: {
                        colors: undefined,
                        opacity: 0
                    },
                    column: {
                        colors: undefined,
                        opacity: 0
                    },
                    padding: {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0
                    },
                }
            },
            current_token: '',
            current_company: '',

            loadingCSP: false,
            loadingCPE: false,
            display_chart_comparaison: false,
            chartCPE_html: false,
            chart2data: [],
            chart2Options: {
                legend: {
                    position: 'top'
                },
                chart: {
                    type: 'radar',
                    toolbar: {
                        show: false
                    }
                },
                xaxis: {
                    categories: []
                },
                yaxis: {
                    min: 0,
                    max: 10,
                },
                grid: {
                    show: false,
                    xaxis: {
                        lines: {
                            show: false
                        }
                    },
                    yaxis: {
                        lines: {
                            show: false
                        }
                    },
                    row: {
                        colors: undefined,
                        opacity: 0
                    },
                    column: {
                        colors: undefined,
                        opacity: 0
                    },
                    padding: {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0
                    },
                }
            },
        }
    },
    props: [
        'employer',
        'employers'
    ],
    methods: {
        diplay_autodiagnostic(token, company) {
            this.dialog_employers = false;
            this.loadingAutodiag = true;
            this.current_company = company;
            this.$http
                .get(process.env.VUE_APP_API + '/employers/' + token + '/results?full=1')
                .then(response => {
                    this.results = response.data;
                    this.chart1data[0].data = response.data.chart_1_points;
                    this.chart1Options.xaxis.categories = response.data.chart_1_labels;
                    this.loadingAutodiag = false;
                    this.dialog = true;
                    this.current_token = token;
                }).catch( () => {
                    //this.errorResults = true;
                    this.loadingAutodiag = false;
                });
        },

        downloadAutodiag(token) {
            this.loadingPdf = true;
            this.$http
                .post(process.env.VUE_APP_API + '/employers/' + token + '/report?full=1', {
                    //chart1: chart1,
                }, { responseType: 'arraybuffer'} )
                .then(response => {
                    downloadPDF(response.data, 'rapport');
                    this.loadingPdf = false;
                }).catch( () => {
                    //console.log(error);
                    this.loadingPdf = false;
                });
        },


        compareCharts() {
            this.dialog_employers = false;
            this.compareChartCSP();
            this.compareChartCPE();
        },
        compareChartCSP() {
            this.loadingCSP = true;
            this.display_chart_comparaison = false;
            this.chart2data = [];
            // build array to send
            var array_ids = [];
            array_ids.push(this.employer.id); // add parent
            this.employers.forEach((item) => {
                array_ids.push(item.id); // add children
            });
            this.$http
                .post(process.env.VUE_APP_API + '/projects/compare/charts/csp', {
                    compare: array_ids
                })
                .then(response => {
                    if( response.data.charts[0] && response.data.charts[0].length > 0 ) {
                        this.chart2data.push({
                            name: response.data.employers_name[0],
                            data: response.data.charts[0]
                        });
                    }
                    if( response.data.charts[1] && response.data.charts[1].length > 0 ) {
                        this.chart2data.push({
                            name: response.data.employers_name[1],
                            data: response.data.charts[1]
                        });
                    }
                    if( response.data.charts[2] && response.data.charts[2].length > 0 ) {
                        this.chart2data.push({
                            name: response.data.employers_name[2],
                            data: response.data.charts[2]
                        });
                    }
                    if( response.data.charts[3] && response.data.charts[3].length > 0 ) {
                        this.chart2data.push({
                            name: response.data.employers_name[3],
                            data: response.data.charts[3]
                        });
                    }

                    this.chart2Options.xaxis.categories = response.data.chart_labels;
                    this.display_chart_comparaison = true;
                    this.loadingCSP = false;
                }).catch( () => {
                    this.loadingCSP = false;
                });
        },
        compareChartCPE() {
            this.loadingCPE = true;
            // build array to send
            var array_ids = [];
            array_ids.push(this.employer.id); // add parent
            this.employers.forEach((item) => {
                array_ids.push(item.id); // add children
            });
            this.$http
                .post(process.env.VUE_APP_API + '/projects/compare/charts/cpe', {
                    compare: array_ids
                })
                .then(response => {
                    this.chartCPE_html = response.data.chart;
                    this.loadingCPE = false;
                }).catch( () => {
                    this.loadingCPE = false;
                });
        },

        unlinkDiag(employer) {
            this.loadingUnlink = true;
            this.$http
                .post(process.env.VUE_APP_API + '/employers/'+employer.id+'/unlink', {
                    employer_id: employer.id
                })
                .then( () => {
                    this.dialog_employers = false;
                    this.$emit('reload');
                    this.loadingUnlink = false;
                }).catch( () => {
                    this.loadingUnlink = false;
                });
        }
    },
    components: {
        Loading
    }
}
</script>
