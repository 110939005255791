<template>
    <div class="interviewedit">
        <v-snackbar
            v-model="snackbar"
            :color="snackbarColor"
        >
            {{ snackbarText }}

            <template v-slot:action="{ attrs }">
                <v-btn
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    Fermer
                </v-btn>
            </template>
        </v-snackbar>

        <div v-if="loading" class="pt-4">
            <Loading />
        </div>
        <v-container v-else fluid class="pb-5">

            <div v-if="projectmodel">
                <div class="topbar">
                    <div class="topbar-title">
                        <span class="topbar-title-span">
                            Modèle : {{ projectmodel.name }} <v-icon color="black" class="mr-1" @click="modalEdit = true">mdi-pencil</v-icon>
                        </span>
                    </div>
                </div>

                <v-dialog v-model="modalEdit" width="600px">
                    <v-card :loading="loadingEdit">
                        <v-card-title>
                            <h3>Modifier le nom du modèle</h3>
                        </v-card-title>
                        <v-card-text>
                            <v-form ref="form" @submit.prevent="updateModel">
                                <v-text-field
                                    v-model="projectmodel.name"
                                    :rules="fieldRules"
                                    label="Donnez un nom à votre modèle"
                                    required
                                ></v-text-field>
                                <div class="">
                                    <v-btn
                                        color="primary"
                                        class="mr-4"
                                        @click="updateModel"
                                    >
                                        Enregistrer
                                    </v-btn>
                                </div>
                            </v-form>
                            <div class="text-right">
                                <v-btn color="darken-1" text @click="modalEdit = false">Annuler</v-btn>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-dialog>


                <div class="tab-container">
                    <v-tabs
                        v-model="tab"
                        align-with-title
                        background-color="#eee"
                        grow
                        centered
                    >
                        <v-tabs-slider color="#f16c08"></v-tabs-slider>

                        <v-tab key="tab_categ">
                            Préparation par thématique
                        </v-tab>
                        <v-tab key="tab_csp">
                            Préparation par axe CSP
                        </v-tab>
                    </v-tabs>
                </div>

                <v-tabs-items v-model="tab" style="background-color: transparent">
                    <v-tab-item key="tab_categ">

                        <div class="row pb-5">
                            <div class="col-lg-3 col-md-4 col-12">
                                <template v-if="categories.length > 0">
                                  <div v-for="category in categories" :key="'main_'+category[0].category.id" class="mb-4">
                                      <v-card>
                                          <v-card-text :style="'background-color:'+category[0].category.color_background">
                                              <h3>{{ category[0].category.group }}</h3>
                                          </v-card-text>
                                          <v-simple-table>
                                              <template v-slot:default>
                                                  <tbody>
                                                      <tr v-for="categ in category" :key="'main_'+category[0].category.id+'_sub_'+categ.category.id" style="cursor:pointer" @click="selectCategory(categ)" :class="{selected: selectedCategory === categ.category.id}">
                                                          <td>
                                                              {{ categ.category.name }}
                                                          </td>
                                                          <td class="text-right">
                                                              <v-icon color="" class="mr-1">mdi-chevron-right</v-icon>
                                                          </td>
                                                      </tr>
                                                  </tbody>
                                              </template>
                                          </v-simple-table>
                                      </v-card>
                                  </div>
                                </template>
                                <AddCustomQuestion :fulltabs="1" @addCustomQuestion="addCustomQuestion" />
                            </div>
                            <div class="col-lg-9 col-md-8 col-12">

                                <v-row>
                                    <v-col class="pt-0">
                                        <v-card>
                                            <v-card-text>
                                                <h3>Questions disponibles</h3>
                                            </v-card-text>
                                            <div v-if="questions && questions.length > 0">
                                                <v-data-table
                                                    :headers="headers"
                                                    :options.sync="tableoptions"
                                                    :items="questions"
                                                    class="elevation-1"
                                                    :loading="loadingQuestions"
                                                    hide-default-footer
                                                >
                                                    <template v-slot:item.question="{ item }">
                                                        <span v-html="item.question"></span>
                                                    </template>
                                                    <template v-slot:item.actions="{ item }">
                                                        <template v-if="item.infobulle_front">
                                                            <v-tooltip top color="black" :max-width="500">
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-icon v-bind="attrs" v-on="on">mdi-information</v-icon>
                                                                </template>
                                                                <span>{{ item.infobulle_front }}</span>
                                                            </v-tooltip>
                                                        </template>

                                                        <template v-if="questionSelected(item)">
                                                            <v-icon color="green" class="ml-2">mdi-check-circle</v-icon>
                                                        </template>
                                                        <template v-else>
                                                            <v-icon color="primary" class="ml-2" @click="addQuestion(item)">mdi-plus-circle</v-icon>
                                                        </template>
                                                    </template>
                                                </v-data-table>
                                            </div>
                                            <div v-else>
                                                <v-card-text>
                                                    <em>Sélectionnez un module pour afficher les questions disponibles</em>
                                                </v-card-text>
                                            </div>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </div>
                        </div>
                    </v-tab-item>

                    <v-tab-item key="tab_csp">
                        <div class="row">
                            <div class="col-md-6">
                                <v-card>
                                    <v-card-text>
                                        <div ref="chart1" id="chart1" v-if="chart1data && chart1data[0] && chart1data[0].data && chart1data[0].data.length > 0">
                                            <apexchart height="500" type="radar" :options="chart1Options" :series="chart1data"></apexchart>
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </div>
                            <div class="col-md-6">
                                <v-card>
                                    <v-card-text v-if="selectedAutocategory && selectedAutocategory > 0">
                                        <div @click.prevent="selectedAutocategory = 0" class="autocategory_back">
                                            <v-icon color="black">mdi-arrow-left</v-icon> <span>Retour aux catégories CSP</span>
                                        </div>

                                        <v-tabs
                                            v-model="tab_type_q"
                                            align-with-title
                                            background-color="#eee"
                                            grow
                                            centered
                                        >
                                            <v-tabs-slider color="#f16c08"></v-tabs-slider>

                                            <v-tab key="tab_type_q_important">
                                                Prioritaires
                                            </v-tab>
                                            <v-tab key="tab_type_q_other">
                                                Subsidiaires
                                            </v-tab>
                                        </v-tabs>

                                        <v-tabs-items v-model="tab_type_q" style="background-color: transparent">
                                            <v-tab-item key="tab_type_q_important">
                                                <div v-if="autoquestions.important">
                                                    <v-data-table
                                                    :headers="headers"
                                                    :options.sync="tableoptions"
                                                    :items="autoquestions.important"
                                                    :loading="loadingQuestionsCSP"
                                                    hide-default-footer
                                                    >
                                                        <template v-slot:item.question="{ item }">
                                                            <span v-html="item.question"></span>
                                                        </template>
                                                        <template v-slot:item.actions="{ item }">
                                                            <template v-if="item.infobulle_front">
                                                                <v-tooltip top color="black" :max-width="500">
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-icon v-bind="attrs" v-on="on">mdi-information</v-icon>
                                                                    </template>
                                                                    <span>{{ item.infobulle_front }}</span>
                                                                </v-tooltip>
                                                            </template>

                                                            <template v-if="questionSelected(item)">
                                                                <v-icon color="green" class="ml-2">mdi-check-circle</v-icon>
                                                            </template>
                                                            <template v-else>
                                                                <v-icon color="primary" class="ml-2" @click="addQuestion(item)">mdi-plus-circle</v-icon>
                                                            </template>
                                                        </template>
                                                    </v-data-table>
                                                </div>
                                                <div v-else class="text-center pt-2">
                                                    <em>Aucune question importante</em>
                                                </div>
                                            </v-tab-item>
                                            <v-tab-item key="tab_type_q_other">
                                                <div v-if="autoquestions.other">
                                                    <v-data-table
                                                    :options.sync="tableoptions"
                                                    :headers="headers"
                                                    :items="autoquestions.other"
                                                    :loading="loadingQuestionsCSP"
                                                    hide-default-footer
                                                    >
                                                        <template v-slot:item.question="{ item }">
                                                            <span v-html="item.question"></span>
                                                        </template>
                                                        <template v-slot:item.actions="{ item }">
                                                            <template v-if="item.infobulle_front">
                                                                <v-tooltip top color="black" :max-width="500">
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-icon v-bind="attrs" v-on="on">mdi-information</v-icon>
                                                                    </template>
                                                                    <span>{{ item.infobulle_front }}</span>
                                                                </v-tooltip>
                                                            </template>

                                                            <template v-if="questionSelected(item)">
                                                                <v-icon color="green" class="ml-2">mdi-check-circle</v-icon>
                                                            </template>
                                                            <template v-else>
                                                                <v-icon color="primary" class="ml-2" @click="addQuestion(item)">mdi-plus-circle</v-icon>
                                                            </template>
                                                        </template>
                                                    </v-data-table>
                                                </div>
                                                <div v-else class="text-center pt-2">
                                                    <em>Aucune question subsidiare</em>
                                                </div>
                                            </v-tab-item>
                                        </v-tabs-items>


                                    </v-card-text>
                                    <v-card-text v-else-if="autocategories && autocategories.length > 0" class="pb-1">

                                        <h3 class="mb-4">Questions disponibles</h3>

                                        <template v-if="autocategories.length > 0 && autocategories[0][0].autocategory !== null">
                                          <div v-for="autocategory in autocategories" :key="'main_'+autocategory[0].autocategory.id" class="mb-4">
                                              <div>
                                                  <h3 class="csp_categ_title">{{ autocategory[0].autocategory.categ }}</h3>
                                              </div>
                                              <v-simple-table>
                                                  <template v-slot:default>
                                                      <tbody>
                                                          <tr v-for="categ in autocategory" :key="'main_'+autocategory[0].autocategory.id+'_sub_'+categ.autocategory.id" style="cursor:pointer" @click="selectAutocategory(categ)" :class="{selected: selectedAutocategory === categ.autocategory.id}">
                                                              <td>
                                                                  {{ categ.autocategory.name }}
                                                                  <v-tooltip top color="black" :max-width="500">
                                                                      <template v-slot:activator="{ on, attrs }">
                                                                          <v-icon color="" small v-bind="attrs" v-on="on">mdi-information</v-icon>
                                                                      </template>
                                                                      <span>
                                                                          {{ categ.definition }}
                                                                      </span>
                                                                  </v-tooltip>
                                                              </td>
                                                              <td class="text-right">
                                                                  <v-icon color="" class="mr-1">mdi-chevron-right</v-icon>
                                                              </td>
                                                          </tr>
                                                      </tbody>
                                                  </template>
                                              </v-simple-table>
                                          </div>
                                        </template>
                                    </v-card-text>
                                </v-card>
                            </div>
                        </div>
                    </v-tab-item>
                </v-tabs-items>


                <div class="selectedQuestions" v-if="displaySelectedQuestions">
                    <div class="selectedQuestions-hover" @click="displaySelectedQuestions = false"></div>

                    <div class="selectedQuestions-content selectedQuestions-content--big">
                        <h3 class="selectedQuestions-content-header yellow darken-2 pa-3" @click="displaySelectedQuestions = false">
                            <v-icon color="black">mdi-arrow-bottom-right</v-icon> Votre selection pour {{ projectmodel.name }} ({{ selectedQuestions.length }})
                        </h3>

                        <Loading v-if="loadingSelectedQuestions" :hover="1" />
                        <template>
                            <div class="orderingQuestionsLegend">
                                Vous pouvez choisir de classer vos questions dans l’ordre que vous souhaitez. Pour cela, cliquer sur l’icône devant la question, et le faire glisser de haut en bas ou du bas vers le haut.
                            </div>
                            <div class="orderingQuestions">
                                <draggable v-if="selectedQuestions && selectedQuestions.length > 0" v-model="selectedQuestions" handle=".handle" draggable=".item" @end="saveOrder">
                                    <transition-group>
                                        <template v-for="q in selectedQuestions">
                                            <div class="orderingQuestions-line item" :key="'selected_'+q.id">
                                                <div class="orderingQuestions-line-question">
                                                    <div class="handle ">
                                                        <!-- <v-icon>mdi-unfold-more-horizontal</v-icon> -->
                                                        <v-icon color="black">mdi-arrow-all</v-icon>
                                                    </div>
                                                    <div v-if="q.question && q.question.question">
                                                        <!--<small>({{ q.question.type }})</small> - --><span v-html="q.question.question"></span>
                                                    </div>
                                                    <div v-else-if="q.customquestion && q.customquestion.question">
                                                        <!--<small>(perso)</small> - -->{{ q.customquestion.question }}
                                                    </div>
                                                </div>
                                                <div class="orderingQuestions-line-actions">
                                                    <template v-if="q.question && q.question.id">
                                                        <template v-if="q.question.infobulle_back">
                                                            <v-tooltip left color="black" :max-width="500">
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-icon v-bind="attrs" v-on="on">mdi-information</v-icon>
                                                                </template>
                                                                <span>{{ q.question.infobulle_back }}</span>
                                                            </v-tooltip>
                                                        </template>
                                                        <v-icon color="red" class="ml-2" @click="removeQuestion(q)">mdi-minus-circle</v-icon>
                                                    </template>
                                                    <template v-else-if="q.customquestion && q.customquestion.id">
                                                        <v-icon color="red" class="ml-2" @click="removeQuestion(q)">mdi-minus-circle</v-icon>
                                                    </template>
                                                </div>
                                            </div>
                                        </template>
                                    </transition-group>
                                </draggable>
                                <div v-else class="pl-2 pt-1">
                                    <em>Vous n'avez pas encoré sélectionné de questions.</em>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
                <div class="selectedQuestionsTag" v-else @click="displaySelectedQuestions = true">
                    <div class="yellow darken-2 pa-3">
                        <v-icon color="black">mdi-arrow-top-left</v-icon> Questions sélectionnées ({{ selectedQuestions.length }})
                    </div>
                </div>










            </div>
            <div v-else>
                <div class="text-center">
                    <em>Le modèle demandé n'existe pas.</em>
                </div>
            </div>
        </v-container>
    </div>
</template>

<script>
import draggable from 'vuedraggable';
import AddCustomQuestion from '@/components/AddCustomQuestion';
import Loading from '@/components/Loading';

export default {
    data() {
        return {
            loading: false,
            loadingEdit: false,
            projectmodel: false,
            snackbar: false,
            snackbarColor: 'success',
            snackbarText: '',
            modalEdit: false,
            fieldRules: [
                v => !!v || 'Ce champ est requis',
            ],
            tab: null,
            tab_type_q: null,
            loadingQuestions: false,
            loadingQuestionsCSP: false,
            loadingSelectedQuestions: false,
            loadingAdd: false,
            questions: [],
            categories: [],
            selectedQuestions: [],
            tableoptions: {
                page: 1,
                itemsPerPage: -1,
            },
            headers: [
                {
                    text: 'Num',
                    sortable: false,
                    value: 'num',
                },
                {
                    text: 'Question',
                    sortable: false,
                    value: 'question',
                },
                {
                    text: 'Actions',
                    value: 'actions',
                    sortable: false,
                    align: 'end',
                    width: '100px'
                }
            ],
            headersSelected: [
                {
                    text: 'Question',
                    sortable: false,
                    value: 'question.question',
                },
                { text: 'Actions', value: 'actions', sortable: false, }
            ],
            displaySelectedQuestions: false,
            selectedCategory: 0,
            selectedAutocategory: 0,
            autocategories: [],
            autoquestions: [],
            chart1data: [{
                name: 'Score',
                data: [5,5,5,5,5,5],
            }],
            chart1Options: {
                chart: {
                    type: 'radar',
                    toolbar: {
                        show: false
                    }
                },
                xaxis: {
                    categories: [
                        'CAPTER valoriser',
                        'CAPTER communiquer et attirer',
                        'SELECTIONNER Evaluer',
                        'SELECTIONNER Choisir',
                        'PERENNISER Accueillir et intégrer',
                        'PERENNISER Former et Fidéliser'
                    ]
                },
                yaxis: {
                    min: 0,
                    max: 10,
                },
                grid: {
                    show: false,
                    xaxis: {
                        lines: {
                            show: false
                        }
                    },
                    yaxis: {
                        lines: {
                            show: false
                        }
                    },
                    row: {
                        colors: undefined,
                        opacity: 0
                    },
                    column: {
                        colors: undefined,
                        opacity: 0
                    },
                    padding: {
                        top: 0,
                        right: 0,
                        bottom: 0,
                        left: 0
                    },
                }
            },
        }
    },
    created() {
        this.getModel();
    },
    methods: {
        getModel() {
            this.loading = true;
            this.$http
                .get(process.env.VUE_APP_API + '/projectmodels/' + this.$route.params.id)
                .then(response => {
                    this.projectmodel = response.data.data;

                    this.getCategories();
                    this.getSelectedQuestions();
                    this.getAutocategories();

                    this.loading = false;
                }).catch( () => {
                    //this.errorResults = true;
                    this.loading = false;
                });
        },
        getCategories() {
            this.loadingQuestions = true;
            this.$http
                .get(process.env.VUE_APP_API + '/questions')
                .then(response => {
                    this.categories = response.data;
                    this.loadingQuestions = false;
                }).catch( () => {
                    this.loadingQuestions = false;
                });
        },
        getSelectedQuestions() {
            this.loadingSelectedQuestions = true;
            this.$http
                .get(process.env.VUE_APP_API + '/projectmodels/' + this.$route.params.id + '/questions')
                .then(response => {
                    this.selectedQuestions = response.data.data;
                    this.loadingSelectedQuestions = false;
                }).catch( () => {
                    this.loadingSelectedQuestions = false;
                });
        },
        getAutocategories() {
            this.loadingQuestionsCSP = true;
            this.$http
                .get(process.env.VUE_APP_API + '/questions-by-csp')
                .then(response => {
                    this.autocategories = response.data;
                    this.loadingQuestionsCSP = false;
                }).catch( () => {
                    this.loadingQuestionsCSP = false;
                });
        },
        selectCategory(category) {
            this.questions = category.questions;
            this.selectedCategory = category.category.id;
        },
        selectAutocategory(autocategory) {
            this.tab_type_q = "tab_type_q_important";
            this.autoquestions = autocategory.questions;
            this.selectedAutocategory = autocategory.autocategory.id;
        },
        addQuestion(item) {
            this.loadingAdd = true;
            this.loadingSelectedQuestions = true;
            this.$http
                .post(process.env.VUE_APP_API + '/projectmodels/' + this.$route.params.id + '/questions', {
                    question_id: item.id
                })
                .then( () => {
                    this.getSelectedQuestions(); // reload selected questions
                    this.loadingAdd = false;
                    this.snackbarText = "Question enregistrée";
                    this.snackbarColor = 'success';
                    this.snackbar = true;
                }).catch( () => {
                    this.loadingAdd = false;
                    this.loadingSelectedQuestions = false;
                });
        },
        addCustomQuestion(item) {
            this.loadingAdd = true;
            this.loadingSelectedQuestions = true;
            this.$http
                .post(process.env.VUE_APP_API + '/projectmodels/' + this.$route.params.id + '/customquestions', {
                    customquestion_id: item.id
                })
                .then( () => {
                    this.getSelectedQuestions(); // reload selected questions
                    this.loadingAdd = false;
                    this.snackbarText = "Question enregistrée";
                    this.snackbarColor = 'success';
                    this.snackbar = true;
                }).catch( () => {
                    this.loadingAdd = false;
                    this.loadingSelectedQuestions = false;
                });
        },
        removeQuestion(item) {
            this.loadingSelectedQuestions = true;
            this.$http
                .delete(process.env.VUE_APP_API + '/projectmodels/' + this.$route.params.id + '/questions', {
                    data: {
                        modelquestion_id: item.id
                    }
                })
                .then( () => {
                    this.getSelectedQuestions(); // reload selected questions
                    // display notification success
                    this.snackbarText = "Question retirée";
                    this.snackbarColor = 'success';
                    this.snackbar = true;
                }).catch( () => {
                    this.loadingSelectedQuestions = false;
                });
        },
        saveOrder() {
            // build array to send
            var array_questions = [];
            this.selectedQuestions.forEach((q) => {
                array_questions.push(q.id);
            });

            // send positions to server
            this.$http
                .post(process.env.VUE_APP_API + '/projectmodels/' + this.$route.params.id + '/questions/order', {
                    questions: array_questions
                })
                .then( () => {
                    // display notification success
                    this.snackbarText = "Ordre des questions enregistré";
                    this.snackbarColor = 'success';
                    this.snackbar = true;
                }).catch( () => {
                    // display notification error
                    this.snackbarText = "L'ordre des questions n'a pas pû être enregistré";
                    this.snackbarColor = 'error';
                    this.snackbar = true;
                });
        },
        questionSelected(question_displayed) {
            let is_in = false;
            this.selectedQuestions.forEach((q) => {
                if( q.question && q.question.id && q.question.id == question_displayed.id ) {
                    is_in = true;
                }
            });
            return is_in;
        },
        updateModel() {
            this.loadingEdit = true;
            this.$http
                .put(process.env.VUE_APP_API + '/projectmodels/' + this.$route.params.id, {
                    name: this.projectmodel.name,
                })
                .then( () => {
                    this.snackbarText = "Nom du modèle enregistré.";
                    this.snackbarColor = 'success';
                    this.snackbar = true;
                    this.loadingEdit = false;
                    this.modalEdit = false;
                }).catch( () => {
                    this.snackbarText = "Oups, le nom n'a pas pû être modifié.";
                    this.snackbarColor = 'error';
                    this.snackbar = true;
                    this.loadingEdit = false;
                });
        }
    },
    components: {
        AddCustomQuestion,
        Loading,
        draggable
    }
}
</script>
