<template>
    <v-container
        fluid
    >
        <v-row>
            <v-col>
                <v-btn v-if="user.role === 'admin'" :outlined="filter !== 'all'" color="primary" class="mr-2 mb-2" @click.prevent="changeFilter('all')">Toutes les agences</v-btn>
                <template v-if="userAgencies !== null">
                  <template v-for="(agency, index) in this.agencies">
                      <v-btn :outlined="filter !== agency.id" color="primary" class="mr-2 mb-2" :key="index" v-if="JSON.parse(userAgencies).includes(agency.id)" @click.prevent="changeFilter(agency.id)">{{ agency.name }}</v-btn>
                  </template>
                </template>
                <template v-if="user.role === 'dt' || user.role === 'admin'">
                  <template v-for="(territorialDirectorate) in this.territorialDirectorates">
                    <v-btn :outlined="filter !== 'territorial_' + territorialDirectorate.id"
                           :key="'territorial_' + territorialDirectorate.id"
                           v-if="user.role !== 'admin' ? territorialDirectorate.id === user.territorial_directorate_id : true"
                           color="primary"
                           class="mr-2 mb-2"
                           @click.prevent="changeFilter('territorial_' + territorialDirectorate.id)">
                      Direction Territoriale : {{ territorialDirectorate.name }}
                    </v-btn>
                  </template>
                </template>
                <template v-if="user.role === 'dr' || user.role === 'admin'">
                    <template v-for="(regionalDirectorate) in this.regionalDirectorates">
                        <v-btn :outlined="filter !== 'regional_' + regionalDirectorate.id"
                               :key="'regional_' + regionalDirectorate.id"
                                v-if="user.role !== 'admin' ? regionalDirectorate.id === user.regional_directorate_id : true"
                               color="primary"
                               class="mr-2 mb-2"
                               @click.prevent="changeFilter('regional_' + regionalDirectorate.id)">
                          Direction Régionale : {{ regionalDirectorate.name }}
                        </v-btn>
                    </template>
                </template>
            </v-col>
        </v-row>
        <v-row v-if="loading">
            <v-card-text >
                <Loading />
            </v-card-text>
        </v-row>
        <template v-else>
            <v-row>
                <v-col md="4" sm="6" cols="6">
                    <v-card>
                        <v-card-text>
                            <h1 class="mb-1">
                                {{ stats.total_autodiag }}
                            </h1>
                            <div>
                                Auto-diags envoyés depuis le début du POC
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col md="4" sm="6" cols="6">
                    <v-card>
                        <v-card-text>
                            <h1 class="mb-1">
                                {{ stats.total_autodiag_complete }}
                            </h1>
                            <div>
                                Auto-diags terminés depuis le début du POC
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
                <!-- <v-col md="3" sm="6" cols="6">
                    <v-card>
                        <v-card-text>
                            <h1 class="mb-1">
                                {{ stats.total_autodiag_downloads }}
                            </h1>
                            <div>
                                Téléchargements de la synthèse
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col> -->
                <v-col md="4" sm="6" cols="6">
                    <v-card>
                        <v-card-text>
                            <h1 class="mb-1">
                                {{ stats.total_autodiag_employer_dl }}
                            </h1>
                            <div>
                                Employeurs ont téléchargé leur synthèse
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col md="3" sm="6" cols="6">
                    <v-card>
                        <v-card-text>
                            <h1 class="mb-1">
                                {{ stats.total_projects }}
                            </h1>
                            <div>
                                Entretiens préparés
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col md="3" sm="6" cols="6">
                    <v-card>
                        <v-card-text>
                            <h1 class="mb-1">
                                {{ stats.total_projects_complete }}
                            </h1>
                            <div>
                                Entretiens réalisés
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col md="3" sm="6" cols="6">
                    <v-card>
                        <v-card-text>
                            <h1 class="mb-1">
                                {{ stats.average_nb_questions }}
                            </h1>
                            <div>
                                Nb moyen de questions par entretien
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col>
                    <v-card>
                        <v-card-text>
                            <h1 class="mb-1">
                                {{ stats.average_time_diff }} jours
                            </h1>
                            <div>
                              Durée entre la date de finalisation de l'auto-diagnostic par l'employeur et la date de l'entretien.
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </template>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-card-text>
                        <v-row align="center">
                            <v-col class="pt-0">
                                <v-menu
                                    ref="menu"
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    :return-value.sync="dates"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on }">
                                        <v-text-field
                                            v-model="dates"
                                            label="Choisir une période"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dates" no-title scrollable range>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="menu = false">Annuler</v-btn>
                                        <v-btn text color="primary" @click="$refs.menu.save(dates)">OK</v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="pt-0">
                                <v-btn small @click="getGraphStats(filter); getGraphCumulStats(filter)">Filtrer</v-btn>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col md="6" cols="12">
                                <template v-if="loadingGraph">
                                    <Loading />
                                </template>
                                <template v-else-if="graphstats">
                                    <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
                                </template>
                            </v-col>
                            <v-col md="6" cols="12">
                                <template v-if="loadingGraphCumul">
                                    <Loading />
                                </template>
                                <template v-else-if="graphcumulstats">
                                    <apexchart type="area" height="350" :options="chartOptionsCumul" :series="seriesCumul"></apexchart>
                                </template>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-card-text class="pb-0">
                        <v-select style="max-width: 300px" :items="itemsTopFlop" v-model="topflop"></v-select>
                    </v-card-text>
                    <v-card-text v-if="loadingGraph">
                        <Loading />
                    </v-card-text>
                    <div v-else-if="questions && questions.top && questions.top.length > 0">
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left">
                                            Num
                                        </th>
                                        <th class="text-left">
                                            Question
                                        </th>
                                        <th class="text-left">
                                            Nb
                                        </th>
                                    </tr>
                                </thead>
                                <tbody v-if="topflop === 'top'">
                                    <tr v-for="(item, index) in questions.top" :key="'question_'+index">
                                        <td>{{ item.question.num }}</td>
                                        <td><span v-html="item.question.question"></span></td>
                                        <td>{{ item.nb }}</td>
                                    </tr>
                                </tbody>
                                <tbody v-else-if="topflop === 'flop'">
                                    <tr v-for="(item, index) in questions.flop" :key="'question_'+index">
                                        <td>{{ item.question.num }}</td>
                                        <td><span v-html="item.question.question"></span></td>
                                        <td>{{ item.nb }}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </div>
                </v-card>
            </v-col>
        </v-row>


    </v-container>
</template>

<script>
import Loading from '@/components/Loading';

export default {
    data() {
        return {
            filter: 'all',
            agencies: [],
            territorialDirectorates: [],
            regionalDirectorates: [],
            user: this.$store.getters.user,
            userAgencies: this.$store.getters.user.agencies_ids,
            userRegionalDirectorate: this.$store.getters.user.regional_directorate_id,
            loading: false,
            loadingGraph: false,
            loadingGraphCumul: false,
            stats: false,
            menu: false,
            graphstats: false,
            graphcumulstats: false,
            dates: [],
            questions: {
                top: [],
                flop: []
            },
            itemsTopFlop: [
                {
                    text: "Top 10 des questions",
                    value: "top",
                },
                {
                    text: "Flop 10 des questions",
                    value: "flop",
                }
            ],
            topflop: 'top',
            series: [{
                name: 'Auto-diags terminés',
                data: []
            },{
                name: 'Entretiens terminés',
                data: []
            }],
            chartOptions: {
                chart: {
                    height: 350,
                    type: 'bar',
                    toolbar: {
                        show: false
                    }
                },
                plotOptions: {
                    bar: {
                        dataLabels: {
                            position: 'top', // top, center, bottom
                        },
                    }
                },
                dataLabels: {
                    enabled: true,
                    formatter: function (val) {
                        return val;
                    },
                    offsetY: -20,
                    style: {
                        fontSize: '12px',
                        colors: ["#304758"]
                    }
                },
                xaxis: {
                    categories: [],
                    position: 'top',
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    },
                    crosshairs: {
                    fill: {
                        type: 'gradient',
                        gradient: {
                            colorFrom: '#D8E3F0',
                            colorTo: '#BED1E6',
                            stops: [0, 100],
                            opacityFrom: 0.4,
                            opacityTo: 0.5,
                        }
                    }
                    },
                    tooltip: {
                        enabled: true,
                    }
                },
                yaxis: {
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false,
                    },
                    labels: {
                        show: false,
                        formatter: function (val) {
                            return val;
                        }
                    }

                },
                title: {
                    text: "Nombre d'auto-diag & entretiens terminés",
                    /*floating: false,
                    offsetY: 330,*/
                    align: 'center',
                    style: {
                        color: '#444'
                    }
                }
            },

            seriesCumul: [{
                name: 'Auto-diags terminés',
                data: []
            },{
                name: 'Entretiens terminés',
                data: []
            }],
            chartOptionsCumul: {
                chart: {
                    height: 350,
                    type: 'area',
                    toolbar: {
                        show: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                xaxis: {
                    //type: 'datetime',
                },
                title: {
                    text: "Cumul d'auto-diag & entretiens terminés",
                    /*floating: false,
                    offsetY: 330,*/
                    align: 'center',
                    style: {
                        color: '#444'
                    }
                },
                tooltip: {
                    x: {
                        format: 'dd/MM/yy HH:mm'
                    },
                },
            },
        }
    },
    created() {
        this.getAgencies();
        this.getTerritorialDirectorates();
        this.getRegionalDirectorates();

        if (this.user.role === 'admin') {
          this.getStats('all');
          this.getGraphStats('all');
          this.getGraphCumulStats('all');
          this.getTopQuestions('all');
        }
    },
    methods: {
      getTerritorialDirectorates() {
          this.loading = true;
          let url = process.env.VUE_APP_API + '/territorial-directorates';

          this.$http
              .get(url)
              .then(response => {
                this.territorialDirectorates = response.data.territorial_directorates;
                this.loading = false;
              }).catch( () => {
            this.loading = false;
          });
        },
        getRegionalDirectorates() {
          this.loading = true;
          let url = process.env.VUE_APP_API + '/regional-directorates';

          this.$http
              .get(url)
              .then(response => {
                this.regionalDirectorates = response.data.regional_directorates;
                this.loading = false;
              }).catch( () => {
            this.loading = false;
          });
        },
        getAgencies() {
          this.loading = true;
          let url = process.env.VUE_APP_API + '/agencies';

          this.$http
              .get(url)
              .then(response => {
                this.agencies = response.data.agencies;
                this.loading = false;
              }).catch( () => {
            this.loading = false;
          });
        },

      getUserAgencies() {
        this.loading = true;
        let url = process.env.VUE_APP_API + '/agencies';

        this.$http
            .get(url)
            .then(response => {
              this.userAgencies = response.data.agencies;
              this.loading = false;
            }).catch( () => {
          this.loading = false;
        });
      },

        changeFilter(value) {
            if( value != this.filter ) {
                this.filter = value;
                this.getStats(value);
                this.getGraphStats(value);
                this.getGraphCumulStats(value);
                this.getTopQuestions(value);
            }
        },
        getStats(agency) {
            this.loading = true;
            var url = process.env.VUE_APP_API + '/stats';
            if( agency && agency != 'all' ) {
                url = process.env.VUE_APP_API + '/stats?agency=' + agency;
            }
            this.$http
                .get(url)
                .then(response => {
                    this.stats = response.data;
                    this.loading = false;
                }).catch( () => {
                    this.loading = false;
                });
        },
        getGraphStats(agency) {
            this.loadingGraph = true;
            var url = process.env.VUE_APP_API + '/graphstats';
            if( agency && agency != 'all' ) {
                url = process.env.VUE_APP_API + '/graphstats?agency=' + agency;
            }
            this.$http
                .post(url, {
                    dates: this.dates
                })
                .then(response => {
                    this.graphstats = response.data;
                    this.series[0].data = response.data.nb_autodiag_completes;
                    this.series[1].data = response.data.nb_projects_completes;
                    this.chartOptions.xaxis.categories = response.data.labels_autodiag_completes;
                    this.loadingGraph = false;
                }).catch( () => {
                    this.loadingGraph = false;
                });
        },
        getGraphCumulStats(agency) {
            this.loadingGraphCumul = true;
            var url = process.env.VUE_APP_API + '/graphcumulstats';
            if( agency && agency != 'all' ) {
                url = process.env.VUE_APP_API + '/graphcumulstats?agency=' + agency;
            }
            this.$http
                .post(url, {
                    dates: this.dates
                })
                .then(response => {
                    this.graphcumulstats = response.data;
                    this.seriesCumul[0].data = response.data.nb_autodiag_completes;
                    this.seriesCumul[1].data = response.data.nb_projects_completes;
                    this.chartOptionsCumul.xaxis.categories = response.data.labels_autodiag_completes;
                    this.loadingGraphCumul = false;
                }).catch( () => {
                    this.loadingGraphCumul = false;
                });
        },
        getTopQuestions(agency) {
            this.loadingQuestions = true;
            var url = process.env.VUE_APP_API + '/questionsstats';
            if( agency && agency != 'all' ) {
                url = process.env.VUE_APP_API + '/questionsstats?agency=' + agency;
            }
            this.$http
                .get(url)
                .then(response => {
                    this.questions.top = response.data.top10;
                    this.questions.flop = response.data.flop10;
                    this.loadingQuestions = false;
                }).catch( () => {
                    this.loadingQuestions = false;
                });
        },
    },
    components: {
        Loading
    }
}
</script>
