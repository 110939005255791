<template>
    <div>
        <div v-if="q.answered">
            <div class="interview-questions-title">
                <span class="interview-questions-title-number" :style="'background-color:'+q.question.category.color">{{ q.question.num }}</span> <span class="interview-questions-title-question" v-html="q.question.question"></span>
            </div>
            <div>
                <v-textarea
                    solo
                    name="input-7-4"
                    label="Saisissez la réponse"
                    v-model="q.answer"
                    @keydown="answerEdited(q)"
                ></v-textarea>
                <template v-if="! q.saving">
                    <a href="#" class="interview-questions-reinit" @click.prevent="$emit('reinit', q);">
                        Réinitialiser
                    </a>
                </template>
            </div>
        </div>
        <div v-else>
            <div>
                <div class="interview-questions-title">
                    <span class="interview-questions-title-number" :style="'background-color:'+q.question.category.color">{{ q.question.num }}</span> <span class="interview-questions-title-question">
                        L'entreprise fait-elle passer un test aux candidats ? 
                    </span>
                </div>
                <div class="mb-3">
                    <label class="mr-3">
                        <input type="radio" @change="displayNext" v-model="reponse1" :value="'oui'"> Oui
                    </label>
                    <label>
                        <input type="radio" @change="displayNext" v-model="reponse1" :value="'non'"> Non
                    </label>
                </div>
            </div>
            <div v-if="reponse1 === 'oui'">
                <div class="interview-questions-title">
                    <span class="interview-questions-title-question">
                        Si oui, quel type de test ?
                        <v-tooltip top color="black" :max-width="500">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon small v-bind="attrs" v-on="on" style="vertical-align:baseline">mdi-information</v-icon>
                            </template>
                            <span>
                                Test pratique, de connaissances techniques, de personnalité, ... Saisir uniquement le nom ou le type de test.
                            </span>
                        </v-tooltip>
                    </span>
                </div>
                <div>
                     <v-textarea
                        solo
                        name="input-7-4"
                        label="Saisissez la réponse"
                        v-model="reponse2"
                        @keydown="answerEdited(q)"
                    ></v-textarea>
                </div>
                <div>
                    <div class="interview-questions-title">
                        <span class="interview-questions-title-question">
                            Et pourquoi ?
                        </span>
                    </div>
                    <div>
                        <v-textarea
                            solo
                            name="input-7-4"
                            label="Saisissez la réponse"
                            v-model="reponse3"
                            @keydown="answerEdited(q)"
                        ></v-textarea>
                    </div>
                </div>
                <div>
                    <div class="interview-questions-title">
                        <span class="interview-questions-title-question">
                            Ces tests donnent ils satisfaction ?
                            <v-tooltip top color="black" :max-width="500">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon small v-bind="attrs" v-on="on" style="vertical-align:baseline">mdi-information</v-icon>
                                </template>
                                <span>
                                    Leur résultats sont il confirmés par les savoirs, savoirs faire ou comportements constatés ensuite par les candiadts une fois dans l'entreprise ?
                                </span>
                            </v-tooltip>
                        </span>
                    </div>
                    <div class="mb-3">
                        <label class="mr-3">
                            <input type="radio" @change="displayNext" v-model="reponse4" :value="'oui'"> Oui
                        </label>
                        <label>
                            <input type="radio" @change="displayNext" v-model="reponse4" :value="'non'"> Non
                        </label>
                    </div>
                </div>
                <div>
                    <div class="interview-questions-title">
                        <span class="interview-questions-title-question">
                            Pourquoi ?
                        </span>
                    </div>
                    <div>
                        <v-textarea
                            solo
                            name="input-7-4"
                            label="Saisissez la réponse"
                            v-model="reponse5"
                            @keydown="answerEdited(q)"
                        ></v-textarea>
                    </div>
                </div>
            </div>
            
        </div>
        <div v-if="q.edited || q.answered">
            <v-btn :loading="q.saving" :disabled="! q.edited || q.saving" color="primary" @click="saveAnswer(q)">Enregistrer</v-btn>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            step: 1,
            reponse1: '',
            reponse2: '',
            reponse3: '',
            reponse4: '',
            reponse5: '',
        }
    },
    methods: {
        saveAnswer(question) {
            question.comment = {
                reponse1: this.reponse1,
                reponse2: this.reponse2,
                reponse3: this.reponse3,
                reponse4: this.reponse4,
                reponse5: this.reponse5,
            };
            this.$emit('saved', question);
            // clean data
            question.comment = '';
            this.reponse1 = '';
            this.reponse2 = '';
            this.reponse3 = '';
            this.reponse4 = '';
            this.reponse5 = '';
        },
        answerEdited(question) {
            if( ! question.edited ) {
                question.edited = true;
            }
            this.$emit('editing', question);
        },
        displayNext() {
            /*if( this.reponse1 == 'avoir besoin d’un appui' ) {
                this.step = 2;
            } else {
                this.step = 3;
            }*/
            this.q.edited = true;
        }
    },
    props: [
        'q',
    ],
}
</script>