<template>
    <v-container
      fluid
  >

    <template>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-text>
              <h1 class="py-3 pa-2">
                Questions par défaut
              </h1>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
              :headers="[
                  { text: 'Question', value: 'question' },
                  { text: 'Actions', value: 'actions' }
                ]"
              :items="defaultQuestions"
              :items-per-page="40"
              class="elevation-1">
            <template v-slot:item.actions="{item}">
              <v-select
                  :value="item.know_more"
                  :v-model="item.know_more"
                  :items="yesNo"
                  item-text="name"
                  item-value="id"
                  @change="setDefaultQuestionKnowMore($event, item.id)"
                  label='Afficher "En savoir plus"'>
              </v-select>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
export default {
    data() {
        return {
            defaultQuestions: [],
            editDefaultQuestion: false,
            editedDefaultQuestion: false,
            yesNo: [
                { id: 1, name: 'Oui' },
                { id: 0, name: 'Non' },
            ],
        }
    },

    created() {
        this.getDefaultQuestions();
    },

    methods: {
        getDefaultQuestions() {
            this.$http
                .get(process.env.VUE_APP_API + '/defaultquestions')
                .then(response => {
                    this.defaultQuestions = response.data.questions;
                }).catch( () => {});
        },

        setDefaultQuestionKnowMore(value, id) {
          let url = process.env.VUE_APP_API + '/defaultquestions/set-know-more';

          this.$http
              .post(url, {
                'question': id,
                'value': value,
              })
              .then(response => {
                this.defaultQuestions = response.data.questions;
              }).catch(() => {
          });
        },
    }
}

</script>
