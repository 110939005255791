<template>
  <v-container
      fluid
  >
    <v-row
        justify="center"
    >
      <v-col
          cols="12"
      >
        <v-card
            class="elevation-12"
        >
          <v-card-title
              class="headline"
          >
            Export Entreprises
          </v-card-title>
          <v-card-text>
            <v-btn
                color="primary"
                @click="exportExcel"
            >
              Exporter
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import axios from "axios";

export default {
  methods: {
    exportExcel() {
      axios.post(process.env.VUE_APP_API + '/employers/export', {}, { responseType: 'blob' })
          .then(function (response) {
            console.log(response)
            let currentDate = new Date().toJSON().slice(0, 10),
                fileName = 'Export Entreprises ' + currentDate + '.xls',
                link = document.createElement('a')
            ;

            link.href = window.URL.createObjectURL(new Blob([response.data]));
            link.setAttribute('download', fileName); //or any other extension
            document.body.appendChild(link);
            link.click();
            link.remove();
          })
          // eslint-disable-next-line no-unused-vars
          .catch(function (error) {
            console.log(error);
          });
    },
  }
}

</script>
<script setup>
</script>
