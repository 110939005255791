<template>
    <div>
        <v-navigation-drawer
            v-model="displayMenu"
            :clipped="true"
            app
        >
            <v-list dense flat>
                <v-list-item>
                    <small>Auto-diag</small>
                </v-list-item>
                <v-list-item link :to="{ name: 'linkautodiag' }" :class="{ menuactive : $route.name === 'linkautodiag' }">
                    <v-list-item-action>
                    <v-icon>mdi-forum</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                    <v-list-item-title>Lien Auto-Diag</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item link :to="{ name: 'autodiags' }" :class="{ menuactive : $route.name === 'autodiags' }">
                    <v-list-item-action>
                    <v-icon>mdi-send</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                    <v-list-item-title>Auto-diag envoyés</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <hr class="mt-2 mb-2">

                <v-list-item>
                    <small>Entretiens</small>
                </v-list-item>
                <v-list-item link :to="{ name: 'home' }" :class="{ menuactive : $route.name === 'home' }">
                    <v-list-item-action>
                    <v-icon>mdi-file-document-edit-outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                    <v-list-item-title>Mes entretiens à réaliser</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item link :to="{ name: 'interviews.complete' }" :class="{ menuactive : $route.name === 'interviews.complete' }">
                    <v-list-item-action>
                    <v-icon>mdi-flag</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                    <v-list-item-title>Mes entretiens terminés</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item link :to="{ name: 'customquestions' }" :class="{ menuactive : $route.name === 'customquestions' }">
                    <v-list-item-action>
                    <v-icon>mdi-star</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                    <v-list-item-title>Mes questions</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item link :to="{ name: 'models' }" :class="{ menuactive : $route.name === 'models' || $route.name === 'models.edit' }">
                    <v-list-item-action>
                    <v-icon>mdi-content-copy</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                    <v-list-item-title>Mes modèles d'entretien</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <hr class="mt-2 mb-2">

                <v-list-item>
                    <small>Pilotage</small>
                </v-list-item>
                <v-list-item link :to="{ name: 'interviews.agency' }" :class="{ menuactive : $route.name === 'interviews.agency' }">
                    <v-list-item-action>
                    <v-icon>mdi-clock-outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                    <v-list-item-title>Toutes les demandes<br>de l'agence</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <hr class="mt-2 mb-2">

                <v-list-item>
                    <small>Ressources</small>
                </v-list-item>
                <v-list-item link :to="{ name: 'conseils' }" :class="{ menuactive : $route.name === 'conseils' }">
                    <v-list-item-action>
                    <v-icon>mdi-information-outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                    <v-list-item-title>Apports des webinaires et conseils préparatoires</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <template v-if="$store.state.user.role === 'admin' || $store.state.user.role === 'dt' || $store.state.user.role === 'dr'">

                    <v-list-item link :to="{ name: 'stats' }" :class="{ menuactive : $route.name === 'stats' }">
                        <v-list-item-action>
                        <v-icon>mdi-chart-bar</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                        <v-list-item-title>Statistiques</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>

              <template v-if="$store.state.user.role === 'admin'">

                <hr class="mt-2 mb-2">

                <v-list-item>
                  <small>Administration</small>
                </v-list-item>

                <v-list-item link :to="{ name: 'users' }" :class="{ menuactive : $route.name === 'users' }">
                  <v-list-item-action>
                    <v-icon>mdi-account-group</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>Utilisateurs</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-list-item link :to="{ name: 'agencies' }" :class="{ menuactive : $route.name === 'agencies' }">
                  <v-list-item-action>
                    <v-icon>mdi-office-building-marker-outline</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>Agences</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item link :to="{ name: 'territorial-directorates' }" :class="{ menuactive : $route.name === 'territorial-directorates' }">
                  <v-list-item-action>
                    <v-icon>mdi-chair-rolling</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>Directions Territoriales</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item link :to="{ name: 'regional-directorates' }" :class="{ menuactive : $route.name === 'regional-directorates' }">
                  <v-list-item-action>
                    <v-icon>mdi-chair-rolling</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>Directions Régionales</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item link :to="{ name: 'default-questions' }" :class="{ menuactive : $route.name === 'default-questions' }">
                  <v-list-item-action>
                    <v-icon>mdi-comment-question</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>Questions par défaut</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>

              <template v-if="$store.state.user.role === 'admin' || $store.state.user.role === 'dt' || $store.state.user.role === 'dr'">
                <v-list-item link :to="{ name: 'export-entreprises' }" :class="{ menuactive : $route.name === 'export-entreprises' }">
                  <v-list-item-action>
                    <v-icon>mdi-file-excel</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>Export Entreprises</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
        </v-navigation-drawer>
        <v-app-bar
            app
            color="yellow darken-2"
            :clipped-left="true"
        >
            <v-app-bar-nav-icon @click.stop="displayMenu = !displayMenu"></v-app-bar-nav-icon>
            <v-toolbar-title>
                Outil d’aide au diagnostic Recrutement
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <div>
                <v-btn @click.prevent="logout" small text>
                    <v-icon>mdi-logout</v-icon>
                    Déconnexion
                </v-btn>
            </div>
        </v-app-bar>
    </div>
</template>

<script>

    export default {
        props: [
            'drawer',
        ],
        data() {
            return {
                displayMenu: this.drawer,
            }
        },
        methods: {
            logout: function () {
                this.$store.dispatch('logout')
                    .then(() => {
                        this.$router.push({ name: 'login' })
                    })
            },
        }
    }
</script>
