<template>
    <div class="interview">

        <v-dialog v-model="modal_rate" width="700px">
            <v-card>
                <v-card-title>
                    <h3>Évaluation à chaud</h3>
                </v-card-title>
                <v-card-text>
                    <div class="text-left">
                        Conseiller entreprise
                    </div>
                    <v-radio-group v-model="project.rate_user" row>
                        <v-radio label="Très satisfait" :value="4"></v-radio>
                        <v-radio label="Assez satisfait" :value="3"></v-radio>
                        <v-radio label="Peu satisfait" :value="2"></v-radio>
                        <v-radio label="Pas satisfait" :value="1"></v-radio>
                    </v-radio-group>
                    <div class="text-left">
                        Commentaire libre (à chaud)
                    </div>
                    <v-textarea
                        solo
                        label=""
                        v-model="project.comment_user"
                    ></v-textarea>
                    <div class="text-center">
                        <v-btn color="orange" dark @click="saveRate">Enregistrer</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

        <div v-if="loading" class="pt-4">
            <Loading />
        </div>
        <v-container v-else fluid>
            <div class="topbar">
                <div class="topbar-title">
                    <span class="topbar-title-span">
                        Entretien : {{ project.employer.company }}
                    </span>
                </div>
            </div>
            <div class="interview-questions">
                <v-row align="center" justify="center">
                    <v-col cols="12" lg="8" md="10">
                        <v-card class="mb-5" min-width="100%">
                            <v-card-text class="text-center">
                                <div class="mb-5">
                                    <h3 class="pt-3">
                                        Conclure l'entretien
                                    </h3>
                                </div>
                                <p>
                                    C’est terminé ! Il ne vous reste plus qu’à remercier votre interlocuteur et à conclure l’entretien.
                                </p>
                                <p>
                                    Vous pouvez retourner à votre rapport d’entretien et reformuler certaines phrases si nécessaire avant de le télécharger et de le transmettre par mail à votre interlocuteur dans un délai convenu ensemble.
                                </p>
                                <p>
                                    <v-btn text small @click.prevent="modal_rate = true">Tableau de bord</v-btn>
                                </p>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </div>
        </v-container>
    </div>
</template>

<script>
import Loading from '@/components/Loading';

export default {
    data(){
        return {
            loading: false,
            project: false,
            modal_rate: false,
        }
    },
    created() {
        this.getProject();
    },
    methods: {
        getProject() {
            this.loading = true;
            this.$http
                .get(process.env.VUE_APP_API + '/projects/' + this.$route.params.id)
                .then(response => {
                    this.project = response.data.data;
                    this.getSelectedQuestions();
                    this.loading = false;
                }).catch( () => {
                    this.loading = false;
                });
        },
        saveRate() {
            this.loading = true;
            this.$http
                .post(process.env.VUE_APP_API + '/projects/' + this.$route.params.id + '/ratesUser', {
                    rate_user: this.project.rate_user,
                    comment_user: this.project.comment_user,
                })
                .then( () => {
                    // redirect to interviews list
                    this.$router.push({
                        name: 'interviews.complete'
                    });

                    this.loading = false;
                }).catch( () => {
                    this.loading = false;
                });
        }
    },
    components: {
        Loading
    }
}
</script>