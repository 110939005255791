<template>
    <div>
        <div v-if="q.answered">
            <div class="interview-questions-title">
                <span class="interview-questions-title-number" :style="'background-color:'+q.question.category.color">{{ q.question.num }}</span> <span class="interview-questions-title-question" v-html="q.question.question"></span>
            </div>
            <div>
                <v-textarea
                    solo
                    name="input-7-4"
                    label="Saisissez la réponse"
                    v-model="q.answer"
                    @keydown="answerEdited(q)"
                ></v-textarea>
                <template v-if="! q.saving">
                    <a href="#" class="interview-questions-reinit" @click.prevent="$emit('reinit', q);">
                        Réinitialiser
                    </a>
                </template>
            </div>
        </div>
        <div v-else>
            <div class="mb-3">
                <div class="interview-questions-title">
                    <span class="interview-questions-title-number" :style="'background-color:'+q.question.category.color">{{ q.question.num }}</span> <span class="interview-questions-title-question">
                        L’employeur trouve-t-il son entreprise aussi, voire plus attractive que les autres du même secteur ?
                    </span>
                </div>
                <div>
                    <label>
                        <input type="radio" @change="displayNext" v-model="reponse1" :value="'plus attractive'"> plus attractive
                    </label><br>
                    <label>
                        <input type="radio" @change="displayNext" v-model="reponse1" :value="'légèrement plus attractive'"> légèrement plus attractive
                    </label><br>
                    <label>
                        <input type="radio" @change="displayNext" v-model="reponse1" :value="'ni plus ni moins attractive'"> ni plus ni moins attractive
                    </label><br>
                    <label>
                        <input type="radio" @change="displayNext" v-model="reponse1" :value="'un peu moins attractive'"> un peu moins attractive
                    </label><br>
                    <label>
                        <input type="radio" @change="displayNext" v-model="reponse1" :value="'moins attractive'"> moins attractive
                    </label>
                </div>
            </div>
            <div v-if="reponse1 === 'plus attractive' || reponse1 === 'légèrement plus attractive'">
                <!--<div class="interview-questions-title">
                    <span class="interview-questions-title-question">
                        Sur quoi précisément ?
                    </span>
                </div>
                <div>
                    <v-textarea
                        solo
                        name="input-7-4"
                        label="Saisissez la réponse"
                        v-model="reponse2"
                        @keydown="answerEdited(q)"
                    ></v-textarea>
                </div>-->
                <div class="interview-questions-title">
                    <span class="interview-questions-title-question">
                        En quoi son entreprise se démarque t-elle des autres ?
                    </span>
                </div>
                <div>
                    <v-textarea
                        solo
                        name="input-7-4"
                        label="Saisissez la réponse"
                        v-model="reponse3"
                        @keydown="answerEdited(q)"
                    ></v-textarea>
                </div>
                <div class="interview-questions-title">
                    <span class="interview-questions-title-question">
                        Pourquoi les candidats postuleraient ils et plus chez lui qu'ailleurs ?
                        <v-tooltip top color="black" :max-width="500">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon small v-bind="attrs" v-on="on" style="vertical-align:baseline">mdi-information</v-icon>
                            </template>
                            <span>
                                Peut-il décrire ce qui différencie son entreprise ou son offre des autres ? Reçoit-il par exemple des candidatures de salariés travaillant dans d’autres entreprises de son secteur.
                            </span>
                        </v-tooltip>
                    </span>
                </div>
                <div>
                    <v-textarea
                        solo
                        name="input-7-4"
                        label="Saisissez la réponse"
                        v-model="reponse4"
                        @keydown="answerEdited(q)"
                    ></v-textarea>
                </div>
            </div>
            <div v-else-if="reponse1.length > 0">
                <div class="interview-questions-title">
                    <span class="interview-questions-title-question">
                        Sur quoi agir dans un 1er temps ?
                    </span>
                </div>
                <div class="mb-3">
                    <label>
                        <input type="checkbox" @change="answerEdited(q)" v-model="reponse5" value="modifier certaines conditions de travail"> modifier certaines conditions de travail
                    </label><br>
                    <label>
                        <input type="checkbox" @change="answerEdited(q)" v-model="reponse5" value="revaloriser le poste, en numéraire ou autrement"> revaloriser le poste, en numéraire ou autrement
                    </label><br>
                    <label>
                        <input type="checkbox" @change="answerEdited(q)" v-model="reponse5" value="mieux faire connaitre son entreprise et mieux communiquer"> mieux faire connaitre son entreprise et mieux communiquer
                    </label><br>
                    <label>
                        <input type="checkbox" @change="answerEdited(q)" v-model="reponse6" value="ne sait pas"> ne sait pas
                    </label>
                </div>
            </div>
        </div>
        <div v-if="q.edited || q.answered">
            <v-btn :loading="q.saving" :disabled="! q.edited || q.saving" color="primary" @click="saveAnswer(q)">Enregistrer</v-btn>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            step: 1,
            reponse1: false,
            reponse2: '',
            reponse3: '',
            reponse4: '',
            reponse5: [],
            reponse6: '',
        }
    },
    methods: {
        saveAnswer(question) {
            question.comment = {
                reponse1: this.reponse1,
                reponse2: this.reponse2,
                reponse3: this.reponse3,
                reponse4: this.reponse4,
                reponse5: this.reponse5,
                reponse6: this.reponse6,
            };
            this.$emit('saved', question);
            // clean data
            question.comment = '';
            this.reponse1 = '';
            this.reponse2 = '';
            this.reponse3 = '';
            this.reponse4 = '';
            this.reponse5 = [];
            this.reponse6 = '';
            this.step = 1;
        },
        answerEdited(question) {
            if( ! question.edited ) {
                question.edited = true;
            }
            this.$emit('editing', question);
        },
        displayNext() {
            /*if( this.reponse1 == 'avoir besoin d’un appui' ) {
                this.step = 2;
            } else {
                this.step = 3;
            }*/
            this.q.edited = true;
        }
    },
    props: [
        'q',
    ],
}
</script>